import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

export const useForm = (
  initialValues,
  validationSchema,
  onSubmit,
  messagesQueue = []
) => {

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await onSubmit(values);
    }
  });

  useEffect(() => {
    const errors = { ...formik.errors };

    let errorMessage = null;

    if (messagesQueue) {
      for (const key of messagesQueue) {
        const error = errors[key];
        if (error) {
          errorMessage = String(error);
          break;
        }
      }
    } else {
      if (Object.keys(errors).length > 0) {
        const firstErrorKey = Object.keys(errors)[0];
        errorMessage = String(errors[firstErrorKey]);
      }
    }

    setErrorMessage(errorMessage);
  }, [formik, messagesQueue]);

  const [errorMessage, setErrorMessage] = useState(null);


  return {
    formik,
    errorMessage
  };
};