import {GET_WAREHOUSE_STATISTIC} from '../types';

const handlers = {
    [GET_WAREHOUSE_STATISTIC]: (state, {payload}) => ({...state, warehouseStatistic: payload}),
    DEFAULT: state => state,
}

export const reportsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}