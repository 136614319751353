import React from 'react';
import { ErrorModalController } from './shared/appLayer/errorModalController/ErrorModalController';
import { GlobalLoaderRunner } from './shared/appLayer/globalLoaderRunner/GlobalLoaderRunner';
import { WindowDimensionsController } from './shared/appLayer/windowDimensionsController/WindowDimensionsController';
import { ContentLayer } from './shared/appLayer/contentLayer/ContentLayer';
import { useRootStore } from '../store/RootStateContext';
import GlobalLoader from './shared/ui/GlobalLoader';
import {CheckUser} from "./shared/appLayer/checkUser/CheckUser";

export const App = () => {

	const rootStore = useRootStore();
	const isStoreReady = !!rootStore;

	return isStoreReady
		?
		<>
			<ContentLayer/>
			<ErrorModalController/>
			<GlobalLoaderRunner/>
			<WindowDimensionsController/>
			<CheckUser/>
		</>
		: <GlobalLoader/>

}
