import React, { useContext } from 'react';
import { AppContext, AuthContext } from '../../../context';
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from '@mui/material';

const MainInfo = ({ isPatientParam, mainModule, mainModuleHandler }) => {
	const { authState } = useContext(AuthContext);
	const { appState } = useContext(AppContext);

	const changeInfoFrom = (name) => {
		const elements = ['fromPatient', 'fromFamily', 'fromHealthCare', 'fromAnother'];

		elements.forEach(element => {
			if (element === name) {
				mainModuleHandler(element, true);
			} else {
				mainModuleHandler(element, false);
			}
		})
	}

	return (
		<Grid container border={`1px solid #071d49`}>
			<Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
				<Typography
					variant="h6"
					color="white">INFORMACJE OGÓLNE</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12} lg={6} p={2}>
						<Grid container alignItems="center" mb={2}>
							<Grid item xs={12}>
								<Typography variant="subtitle1">Rodzaj zdarzenia: *</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={12} md={6}>
										<RadioGroup
											aria-labelledby="adverseType-label"
											name="type"
											value={mainModule.type}
											onChange={(e) => {
												mainModuleHandler(e.target.name, e.target.value)
												if (e.target.value === 'nowe') {
													mainModuleHandler('number', '')
												}
											}}
										>
											<FormControlLabel value="nowe" control={<Radio/>} label="nowe"/>
											<FormControlLabel value="uzupełniające" control={<Radio/>}
											                  label="uzupełniające"/>
										</RadioGroup>
									</Grid>
									<Grid item xs={12} md={6}>
										{
											mainModule.type === 'uzupełniające' &&
											<FormControl fullWidth required>
												<InputLabel id="adverseNumber-label">Zdarzenie numer:</InputLabel>
												<Select
													labelId="adverseNumber-label"
													id="number"
													name="number"
													value={mainModule.number}
													label="Zdarzenie numer:"
													onChange={(e) => mainModuleHandler(e.target.name, e.target.value)}
												>
													<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
													{
														appState.adverseEventsName && appState.adverseEventsName.map((el, index) =>
															<MenuItem value={el.identifier} key={el.id}>{el.identifier}</MenuItem>)
													}
												</Select>
											</FormControl>
										}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Typography variant="subtitle1">Nazwisko osoby zgłaszającej:</Typography>
								<Typography>{authState.name} {authState.surname}</Typography>
								<Typography variant="subtitle1">Numer telefonu:</Typography>
								{authState.telephone && <Typography>{authState.telephone}</Typography>}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6} p={2} className="dynamicLeftBorder">
						<Typography variant="subtitle1">Informacja pochodzi od:</Typography>
						<RadioGroup
						>
							<FormControlLabel control={
								<Checkbox
									disabled={isPatientParam}
									name="fromPatient"
									checked={mainModule.fromPatient}
									onChange={(e) => changeInfoFrom(e.target.name)}
								/>} label="Pacjent"/>
							<FormControlLabel control={
								<Checkbox
									disabled={isPatientParam}
									name="fromFamily"
									checked={mainModule.fromFamily}
									onChange={(e) => changeInfoFrom(e.target.name)}
								/>
							} label="Członek rodziny"/>
							<FormControlLabel control={
								<Checkbox
									disabled={isPatientParam}
									name="fromHealthCare"
									checked={mainModule.fromHealthCare}
									onChange={(e) => changeInfoFrom(e.target.name)}
								/>
							} label="Przedstawiciel służby zdrowia"/>
							<FormControlLabel
								control={
									<Checkbox
										disabled={isPatientParam}
										sx={{ width: 'auto' }}
										name="fromAnother"
										checked={mainModule.fromAnother}
										onChange={(e) => {
											changeInfoFrom(e.target.name)
											if (!e.target.checked) {
												mainModuleHandler('additionalContact', '')
											}
										}}
									/>}
								label="Inna osoba (należy określić funkcję)"
							/>
							<Box minHeight={56}>
								{
									mainModule.fromAnother && <FormControl fullWidth required>
										<OutlinedInput
											id="additionalContact"
											name="additionalContact"
											value={mainModule.additionalContact}
											onChange={(e) => mainModuleHandler(e.target.name, e.target.value)}
										/>
									</FormControl>
								}
							</Box>
						</RadioGroup>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default MainInfo;