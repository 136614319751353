import React, {useContext} from "react"
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import {MessagesContext} from "../../../../context";

export const MessageModalDelete = ({ closeModal, messageID }) => {
  const { delMessage } = useContext(MessagesContext);

  const submitHandler = async (e) => {
    await delMessage(messageID);
    closeModal();
  }

  return (
    <Grid className="customModal ">
      <Stack component="div" className="customModal__addMenu">
        <Box style={{ display: 'flex', justifyContent: 'center' }}> <Typography variant="h5" mb={2} mt={3}>
          Czy jesteś pewny, że chcesz usunąć wiadomość?
        </Typography></Box>
        <div className={`customModal__addMenu-buttonArea`}>
         <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
         <Button
           type="submit"
           color="primary" variant="contained"
           onClick={submitHandler}>Usuń</Button>
        </div>
      </Stack>
    </Grid>
  )
}