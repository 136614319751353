import React from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { DropZone } from '../../../../../shared/dropZone/DropZone';
import { useOrdersModalViewModel } from './useOrdersModal.vm';

import './OrdersModal.scss';

export const OrdersModal = ({ isModalOpen, closeModalHandler }) => {

	const {
		file,
		onDrugStateChangeHandler,
		onFileDropHandler,
		onChangeHandler,
		isError,
		uploadFileHandler,
		clearStateHandler
	} = useOrdersModalViewModel(closeModalHandler);

	return (
		<Modal
			open={isModalOpen}
		>
			<Box
				sx={styles.boxStyle}
			>
				<Grid
					container
					flexDirection="column"
				>
					<DropZone
						onDragStateChange={onDrugStateChangeHandler}
						onFilesDrop={onFileDropHandler}
					>
						<label htmlFor="fileUpload">
							<Grid
								container
								sx={{ ...styles.dropZoneContent, borderColor: isError ? 'red' : 'lightgray' }}
								className="dropZoneContent"
							>
								{
									isError ?
										<Typography variant="caption" color="error">
											wybierz plik z rozszerzeniem
											<br/>
											.xlsx
										</Typography>
										:
										file?.name
											?
											<Typography variant="caption" p={2}>
												{file.name}
											</Typography>
											:
											<Typography variant="caption">
												Wybierz lub przeciągnij
												<br/>
												plik z komputera
											</Typography>
								}
								<input
									style={{ display: 'none' }}
									type="file"
									name="file"
									id="fileUpload"
									onChange={onChangeHandler}
								/>
							</Grid>
						</label>
					</DropZone>
					<Grid
						container
						justifyContent="space-between"
					>
						<Button
							className="formButton"
							type="submit"
							variant="contained"
							color="success"
							disabled={isError || !file}
							onClick={uploadFileHandler}
						>
							zaladuj plik
						</Button>
						<Button
							className="formButton"
							variant="contained"
							color="error"
							onClick={() => {
								clearStateHandler();
								closeModalHandler();
							}
							}
						>
							zamknij
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	)
}

const styles = {
	boxStyle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '16px',
		backgroundColor: 'white',
		borderRadius: '8px',
		boxShadow: '8px'
	},
	dropZoneContent: {
		height: '80px',
		border: '1px dashed lightgray',
		borderRadius: '8px',
		marginBottom: '16px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'gray',
		textAlign: 'center',
	}
}