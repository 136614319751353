export const buttons = [
	{
		text: "nowego pacjenta",
		color: "green"
	},
	null,
	{
		text: "szkolenie",
		color: "blue"
	},
	null, {
		text: "coaching",
		color: "blue"
	}, {
		text: "inne aktywności",
		color: "blue"
	}, null
];
