import React, {useContext, useEffect} from "react";
import {
    Button,
    Stack,
    Grid,
    Typography,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Divider,
    MenuList
} from "@mui/material";
import {equalizerData} from "../../../../utils/helpfunc";
import FormControl from "@mui/material/FormControl";
import {regions} from "../../../../utils/regionsNormalizer";
import {roleNormaliser} from "../../../../utils/roleNormalizer";
import {AppContext} from "../../../../context/app/appContext";
import GlobalLoader from "../../../shared/ui/GlobalLoader";
import {hospitalDataInit, validationSchema} from "./helper";
import {ADD_OPTION, EDIT_OPTION, SHOW_OPTION} from "../../../../data/consts/modalOptions";
import {useForm} from "../../../../hooks/useForm";

const AddHospitalModal = ({closeModal, modalOption, hospitalID}) => {
    const {
        appState,
        addInstitution,
        getUsersFromInstitutions,
        getInstitutionInfo,
        editInstitution,
    } = useContext(AppContext);

    useEffect(() => {
            getUsersFromInstitutions();

            switch (modalOption) {
                case SHOW_OPTION:
                    getInstitutionInfo(hospitalID);
                    getUsersFromInstitutions();
                    break;
                case EDIT_OPTION:
                    getInstitutionInfo(hospitalID);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
        , [modalOption])

    const submitHandler = async (e) => {
        let isAdd = false;

        if(modalOption === ADD_OPTION){
            isAdd = await addInstitution(formik.values);
        }
        else if(modalOption === EDIT_OPTION){
            isAdd = await editInstitution(formik.values, hospitalID);
        }

        isAdd && closeModal();
    }

    const {
        formik
    } = useForm(hospitalDataInit, validationSchema, submitHandler);

    useEffect(() => {
        if (appState.showInstitutionInfo) {
            if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {
                formik.setValues({...appState.showInstitutionInfo});
            }
        }
        // eslint-disable-next-line
    }, [appState.showInstitutionInfo])

    return (
        formik.values ?
            <div className='customModal'>
                <Stack component="div" className="customModal__addMenu">
                    <Typography variant='h4' mb={2}>
                        {
                            modalOption === ADD_OPTION ? 'Dodaj placówkę '
                                : modalOption === SHOW_OPTION ? 'Dane placówki' : 'Edytuj placówkę'
                        }
                    </Typography>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
                              className="customModal__scrollArea">
                            <Grid item>
                                <TextField
                                    required
                                    label="Nazwa"
                                    name='name'
                                    error={Boolean(formik.errors.name)}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Kod placówki"
                                    name='identifier'
                                    error={Boolean(formik.errors.identifier)}
                                    value={formik.values.identifier}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Adres"
                                    name='address'
                                    error={Boolean(formik.errors.address)}
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth required>
                                    <InputLabel id="addUser-label">Województwo</InputLabel>
                                    <Select
                                        labelId="addUser-label"
                                        name="voivodeship"
                                        error={Boolean(formik.errors.voivodeship)}
                                        value={formik.values.voivodeship}
                                        onChange={formik.handleChange}
                                        label="Województwo"
                                        disabled={(modalOption === SHOW_OPTION)}>
                                        <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                        {regions.map((el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {el}
                                            </MenuItem>
                                        )))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Kod pocztowy"
                                    name='zipCode'
                                    error={Boolean(formik.errors.zipCode)}
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Miasto"
                                    name='city'
                                    error={Boolean(formik.errors.city)}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    disabled={(modalOption === SHOW_OPTION)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                  required
                                  label="Adres dostawy"
                                  name='delivery'
                                  error={Boolean(formik.errors.delivery)}
                                  value={formik.values.delivery}
                                  onChange={formik.handleChange}
                                  fullWidth
                                  disabled={(modalOption === SHOW_OPTION)}

                                />
                            </Grid>
                            {modalOption === 'show' ?

                                <Grid item marginBottom={2}>
                                    <Paper mt={2}
                                           sx={{
                                               width: '100%',
                                               border: '0.5px solid #9e9e9e',
                                               color: 'text.disabled',
                                               borderBottom: 'none',
                                           }}>
                                        <Typography p={1.5}>Użytkownicy:</Typography>
                                        <Divider/>

                                        <MenuList>
                                            {
                                                !!formik.values.roles && !!appState.institutionsUserList && equalizerData(formik.values.roles, appState.institutionsUserList).map((el, index) =>

                                                    <MenuItem key={index} sx={{cursor: 'default'}}> {el} </MenuItem>
                                                )
                                            }

                                        </MenuList>

                                    </Paper>
                                </Grid>
                                :
                                <Grid item marginBottom={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="addHospital-label">Opiekun</InputLabel>
                                        <Select
                                            sx={{maxWidth: 550}}
                                            name="roles"
                                            multiple
                                            error={Boolean(formik.errors.roles)}
                                            value={formik.values.roles}
                                            onChange={formik.handleChange}
                                            label="Opiekun"
                                            disabled={(modalOption === SHOW_OPTION)}
                                        >
                                            <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>

                                            {appState.institutionsUserList && appState.institutionsUserList.map((el => (
                                                <MenuItem
                                                    key={el.id}
                                                    value={el.id}
                                                >
                                                    {el.name} {el.surname} - ({roleNormaliser(el.role)})
                                                </MenuItem>
                                            )))}
                                        </Select>
                                    </FormControl>
                                </Grid>}

                        </Grid>
                        <div
                            className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION? 'alignButton' : ''} `}>
                            <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
                            {modalOption !== SHOW_OPTION && <Button type="submit" color="primary" variant="contained">
                                {modalOption === ADD_OPTION ? 'Dodaj' : 'Zapisz'}
                            </Button>}
                        </div>
                    </form>
                </Stack>
            </div>
            :
            <GlobalLoader/>
    )
}

export default AddHospitalModal;