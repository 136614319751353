import React, {useContext, useState} from 'react';
import {AuthContext} from '../../../context';
import PageTitle from '../../shared/ui/PageTitle';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {NavLink} from 'react-router-dom';

const Login = () => {
    const {authUser} = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false)
    // TODO remove default values
    const [email, setEmail] = useState('wojciech.piotrowicz@softwebo.pl');
    const [password, setPassword] = useState('/9Y%TW8h&1k07Qb(O]I_Tm5d/viL.0jm');
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);

    const showPassHandler = () => setShowPassword(prev => !prev);
    const handleMouseDownPassword = (e) => e.preventDefault();


    const submitHandler = (e) => {
        e.preventDefault()

        setIsEmailError(!/^\S+@\S+\.\S+$/.test(email))
        setIsPasswordError(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password))

        if (/^\S+@\S+\.\S+$/.test(email) && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password)) {
            authUser({username: email, password})
        }
    }

    return (
        <>
            <form
                className="auth"
                onSubmit={submitHandler}
            >
                <PageTitle title="Login:"/>
                <Typography variant="body1" mb={2}>Aby się zalogować, wypełnij proszę poniższe pola:</Typography>
                <FormControl required>
                    <InputLabel htmlFor="email" sx={{width: '100px'}}>Email</InputLabel>
                    <OutlinedInput
                        id="email"
                        label="Email"
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={isEmailError}
                    />
                    <Typography variant="caption" mb={3}
                                sx={{color: 'red', height: '10px'}}
                    >
                        {isEmailError ? 'check your email' : ''}
                    </Typography>
                </FormControl>
                <FormControl required>
                    <InputLabel htmlFor="password" sx={{width: '100px'}}>Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        label="Hasło"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="hasło"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={isPasswordError}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={showPassHandler}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <Typography variant="caption" mb={3}
                                sx={{color: 'red', height: '10px'}}
                    >
                        {isPasswordError ? 'check your password' : ''}
                    </Typography>
                </FormControl>
                <Button
                    type="submit"
                    color="primary" variant="contained"
                    sx={{alignSelf: 'center', marginBottom: '24px'}}
                >
                    Login
                </Button>
                <Typography
                    variant="body1"
                    sx={{alignSelf: 'center'}}
                >
                    Nie pamiętasz hasła?
                    <NavLink to="/reset_password" style={{marginLeft: '10px'}}>Zresetuj hasło</NavLink>
                </Typography>
            </form>
        </>
    )
}

export default Login;