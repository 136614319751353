import React, { useContext, useState } from "react";
import { Paper } from "@mui/material"
import { ReckoningsContext, SettlementsContext } from "../../../../context";
import ReckoningGenerateConfirm from "../infoElements/ReckoningGenerateConfirm";
import { SettlementsMonthCell } from "./SettlementsMonthCell";


export const SettlementsMonthsTable = ({ activeMonths }) => {

	// context
	const { getSettlementsDates } = useContext(SettlementsContext);
	const { confirmReckoning } = useContext(ReckoningsContext);

	// local state
	const [showReckoningGenerated, setShowReckoningGenerated] = useState(false);

	const confirmReckoningGenerated = () => {
		getSettlementsDates();
		confirmReckoning();
		setShowReckoningGenerated(false)
	};

	return (<>
		{showReckoningGenerated && <ReckoningGenerateConfirm clearFunction={confirmReckoningGenerated}/>}
		<Paper sx={{ width: '100%', overflow: 'hidden', bgcolor: '#f5f5f5', padding: '40px 75px' }}>
			<ul className={`settlements__list`}>
				{
					activeMonths.map( monthInfo =>
						<SettlementsMonthCell
							key={`${monthInfo.month}${monthInfo.year}`}
							monthInfo={monthInfo}
							setShowReckoningGenerated={setShowReckoningGenerated}
						/>
					)
				}
			</ul>
		</Paper>
	</>);
}