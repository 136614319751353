import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ReportsContext } from '../../../../../../context';
import {
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import Search from '../../../../../shared/search/Search';
import {
	WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES,
	warehouseWarehouseKeeperItemLabels
} from '../../../../../../data/consts/warehouse/warehouseItemStatuses';
import { usePumpsTableViewModel } from './usePumpsTable.vm';
import { useTableController } from '../../../../../../hooks/useTableController';
import { EnhancedTableHead } from '../../../../../../utils/sortingTable';
import { ActionCell } from '../../../../../shared/customTable/actionCell/ActionCell';

const HEAD_CELLS = [
	{
		id: 'serialNumber',
		label: 'Numer seryjny',
	},
	{
		id: 'model',
		label: 'Model',
	},
	{
		id: 'institution',
		label: 'Nazwa szpitala',
	},
	{
		id: 'status',
		label: 'Status',
	},
	{
		id: 'createdAt',
		label: 'Data importu',
	},
	{
		id: 'actions',
		label: 'Akcje',
	}
]

export const PumpsTable = observer(() => {


	const { getWarehouseReportByAdmin } = useContext(ReportsContext);

	const { warehouseKeeperPumps } = usePumpsTableViewModel()

	const {
		drawArray,
		searchTerm,
		count,
		page,
		rowsPerPage,
		order,
		orderBy,
		filterChangeHandler,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange,
		filters,

	} = useTableController(warehouseKeeperPumps, ['status', 'institution', 'model', 'serialNumber', 'createdAt'], {
		orderBy: 'status',
		filters: [{
			name: 'status',
			values: Object.values(WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES).map(value => warehouseWarehouseKeeperItemLabels(value)),
			selectedValue: '',
		}]
	})

	const reportFileHandler = async (fileType) => {
		const fileURL = await getWarehouseReportByAdmin(fileType);
		window.open(fileURL);
	}

	return (
		<Grid container flexDirection="column" mt={2}>
			<Grid container justifyContent="space-between" mb={2}>
				<Grid item>
					<Button
						color="primary" variant="contained"
						className="formButton"
						onClick={() => reportFileHandler('excel')}
					>
						pobierz raport w .xlsx
					</Button>
					<Button
						color="primary" variant="contained"
						className="formButton"
						onClick={() => reportFileHandler('pdf')}
					>
						pobierz raport w .pdf
					</Button>
				</Grid>
				<Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
			</Grid>
			<TableContainer>
				<Table>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={warehouseKeeperPumps.length}
						headCells={HEAD_CELLS}
						filters={filters}
						onFilterChange={filterChangeHandler}
						customSelectStyle={{ width: '250px' }}
					/>
					{
						drawArray.length > 0
							? (
								<TableBody>
									{
										drawArray.map(pump => (
											<TableRow
												key={pump.id}
												role="row"
												tabIndex={-1}
											>
												<TableCell>
													{pump.serialNumber}
												</TableCell>
												<TableCell align="center">
													{pump.model}
												</TableCell>
												<TableCell align="center">
													{pump.institution ? pump.institution : 'nieprzypisane do szpitala'}
												</TableCell>
												<TableCell align="center">
													{pump.status}
												</TableCell>
												<TableCell align="center">
													{pump.createdAt}
												</TableCell>
												<TableCell sx={{ width: '20%' }}>
													<ActionCell status={pump.status} pumpId={pump.id}/>
												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							)
							: (
								<TableBody>
									<TableRow>
										<TableCell colSpan={5}>
											<Typography textAlign="center">
												Brak danych do wyświetlenia...
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							)
					}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				labelRowsPerPage={'Elementy na stronie'}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Grid>
	)
})