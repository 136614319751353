import React from 'react';
import { Grid } from '@mui/material';

const Content = ({ routes }) => {

	return (
		<Grid className="content">
			{routes}
		</Grid>
	)
}

export default Content;