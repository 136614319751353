import React, {useCallback, useContext, useReducer} from 'react';
import {appReducer} from './appReducer';
import {AppContext} from './appContext';
import {AuthContext} from '../auth/authContext';
import {PoiContext} from '../poi/poiContext';
import axios from '../../axios/axios';
import {
    CLEAR_CERTIFICATES,
    CLEAR_USERS,
    GET_ADDITIONAL_ADVERSE_INFO,
    GET_ADDITIONAL_CREATE_USER_INFO,
    GET_CERTIFICATES,
    GET_INSTITUTION_INFO,
    GET_INSTITUTIONS,
    GET_INTERACTIONS,
    GET_KNOWLEDGE_ITEMS,
    GET_PROFILE_INFO,
    GET_USER_INFO,
    GET_USERS,
    GET_USERS_FROM_INSTITUTIONS,
    GET_WORKING_HOURS,
} from '../types';
import {dateFormatForm, sortArray} from '../../utils/helpfunc';
import {ROLE_ADMIN} from "../../data/consts/users";

export const AppState = ({children}) => {

    const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);
    const {role} = useContext(AuthContext)

    const initialState = {
        adverseEventsName: null,
        adversePatients: null,
        users: null,
        roles: null,
        types: null,
        institutions: null,
        institutionsList: null,
        institutionsUserList: null,
        showUserInfo: null,
        showInstitutionInfo: null,
        showProfileInfoForUser: null,
        certificates: [],
        certificateUrl: null,
        additionalWT: null,
        interactions: null,
        knowledge: null,
    }

    const [state, dispatch] = useReducer(appReducer, initialState);

    const addKnowledgeItem = async (itemData) => {
        setGlobalLoader();

        try {
            await axios.post(
                'knowledge',
                itemData,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Dodano nowy wpis',
                    },
                }
                setModalError(defRes);
                getKnowledgeItems();
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const getKnowledgeItems = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `knowledge`,
            ).then(res => {
                dispatch({type: GET_KNOWLEDGE_ITEMS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const getKnowledgeFile = async (itemID) => {
        setGlobalLoader()
        try {
            await axios.get(
                `knowledge_pdf/${itemID}`, {
                    responseType: "blob"
                },
            ).then(res => {
                const temp = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = temp;
                link.setAttribute('download', 'knowledge.pdf');
                document.body.appendChild(link);
                link.click();
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
//ADMIN USER SUBPAGE ------>
    const getUsers = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `admin/users`,
            ).then(res => {
                dispatch({type: GET_USERS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const getAdditionalUserFormInfo = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `users/form-set`,
            ).then(res => {
                unsetGlobalLoader();
                dispatch({type: GET_ADDITIONAL_CREATE_USER_INFO, payload: res.data})
            })
        } catch (e) {
            setModalError(e.response);
        }
    }

    const getUser = async (userID) => {
        setGlobalLoader()
        try {
            await axios.get(
                `admin/user/${userID}`,
            ).then(res => {
                const payload = {
                    firstName: res.data.user.name,
                    secondName: res.data.user.surname,
                    institutions: [...res.data.institutions.map(el => el.id)],
                    phoneNumber: res.data.user.phone,
                    role: res.data.user.roles[0],
                    type: res.data.user.type,
                    email: res.data.user.email,
                    id: res.data.user.identity,
                    language: [res.data.user.language],
                    contractNO: res.data.user.contractNo,
                    dateContract: new Date(res.data.user.dateContract),
                }
                dispatch({type: GET_USER_INFO, payload})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const editUser = async (userInfo, userID) => {
        setGlobalLoader();

        const data = new FormData();
        data.append('name', userInfo.firstName);
        data.append('surname', userInfo.secondName);
        data.append('email', userInfo.email);
        data.append('phone', userInfo.phoneNumber);
        data.append('roles', userInfo.role);
        data.append('password', userInfo.password);
        data.append('identity', userInfo.id);
        data.append('type', userInfo.type);
        data.append('language', 'polski');
        data.append('contractNo', userInfo.contractNO);
        data.append('dateContract', dateFormatForm(userInfo.dateContract));
        userInfo.institutions.forEach((el, key) => data.append(`institutions[${key}]`, el));

        try {
            await axios.post(
                `admin/user_edit/${userID}`,
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Edycja wprowadzona ',
                    },
                }
                setModalError(defRes);
                getUsers();
            })
            return true;
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const deleteUser = async (userID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `admin/user_delete/${userID}`,
            ).then(res => {
                unsetGlobalLoader();
                getUsers()
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Użytkownik usunięty',
                    },
                }
                setModalError(defRes);
            })
            return true;
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
    const addUser = async (userInfo) => {
        setGlobalLoader();
        const data = new FormData();
        data.append('name', userInfo.firstName);
        data.append('surname', userInfo.secondName);
        data.append('email', userInfo.email);
        data.append('phone', userInfo.phoneNumber);
        data.append('roles', userInfo.role);
        data.append('password', userInfo.password);
        data.append('identity', userInfo.id);
        data.append('type', userInfo.type);
        data.append('language', 'polski');
        data.append('contractNo', userInfo.contractNO);
        data.append('dateContract', dateFormatForm(userInfo.dateContract));
        userInfo.institutions.forEach((el, key) => data.append(`institutions[${key}]`, el));

        try {
            await axios.post(
                'admin/user_create',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Dodano nowego użytkownika',
                    },
                }
                setModalError(defRes);
                getUsers();

            })
            return true;
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const clearUsers = () => dispatch({type: CLEAR_USERS})
//<------  ADMIN USER SUBPAGE
//ADMIN INSTITUTIONS SUBPAGE ------>
    const getInstitutionListInfo = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                'admin/institutions',
            ).then(res => {
                dispatch({type: GET_INSTITUTIONS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
    const getInstitutionInfo = async (hospitalID) => {
        setGlobalLoader()
        try {
            await axios.get(
                `admin/institution/${hospitalID}`,
            ).then(res => {
                const payload =
                    {
                        identifier: res.data.institution.identifier,
                        name: res.data.institution.name,
                        address: res.data.institution.address,
                        zipCode: res.data.institution.zipCode,
                        voivodeship: res.data.institution.voivodeship,
                        city: res.data.institution.city,
                        roles: res.data.users.map(e => e.id),
                        delivery: res.data.institution.delivery
                    }
                dispatch({type: GET_INSTITUTION_INFO, payload})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const editInstitution = async (hospitalInfo, hospitalID) => {
        setGlobalLoader()
        const data = new FormData();

        data.append('identifier', hospitalInfo.identifier);
        data.append('name', hospitalInfo.name);
        data.append('address', hospitalInfo.address);
        data.append('zipCode', hospitalInfo.zipCode);
        data.append('voivodeship', hospitalInfo.voivodeship);
        data.append('delivery', hospitalInfo.delivery);
        data.append('city', hospitalInfo.city);
        hospitalInfo.roles.map((user, key) => data.append(`users[${key}]`, user));

        try {
            await axios.post(
                `admin/institution_edit/${hospitalID}`,
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Edycja placówki wprowadzona ',
                    },
                }
                setModalError(defRes);
                getInstitutionListInfo()

            })
            return true;
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const getUsersFromInstitutions = async () => {
        setGlobalLoader();
        try {
            await axios.get(
                'admin/user_list/ads_kam',
            ).then(res => {
                dispatch({type: GET_USERS_FROM_INSTITUTIONS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }
    const addInstitution = async (hospitalInfo) => {
        setGlobalLoader();

        const data = new FormData();
        data.append('identifier', hospitalInfo.identifier);
        data.append('name', hospitalInfo.name);
        data.append('address', hospitalInfo.address);
        data.append('zipCode', hospitalInfo.zipCode);
        data.append('voivodeship', hospitalInfo.voivodeship);
        data.append('delivery', hospitalInfo.delivery);
        data.append('city', hospitalInfo.city);
        hospitalInfo.roles.map((user, key) => data.append(`users[${key}]`, user));

        try {
            await axios.post(
                'admin/institution_create',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Dodano nową placówkę',
                    },
                }
                setModalError(defRes);
                getInstitutionListInfo();
            })
            return true;
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const deleteInstitution = async (hospitalID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `admin/institution_delete/${hospitalID}`,
            ).then(res => {
                unsetGlobalLoader();
                getInstitutionListInfo()
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Placówka usunięta',
                    },
                }
                setModalError(defRes);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
//<------  ADMIN INSTITUTIONS SUBPAGE
//ADMIN CERTIFICATION SUBPAGE ------>
    const getCertificateList = async () => {
        setGlobalLoader()
        try {
            if (role === ROLE_ADMIN) {
                await axios.get(
                    '/certificates',
                ).then(res => {
                    dispatch({type: GET_CERTIFICATES, payload: res.data})
                    unsetGlobalLoader();
                })
            } else {
                await axios.get(
                    '/certificates',
                ).then(res => {
                    dispatch({type: GET_CERTIFICATES, payload: res.data})
                    unsetGlobalLoader();
                })
            }
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const addCertificate = async (data) => {
        setGlobalLoader();
        try {
            await axios.post(
                'certificate',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Certyfikat został dodany',
                    },
                }
                setModalError(defRes);
                getCertificateList();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const clearCertificateList = () => dispatch({type: CLEAR_CERTIFICATES})

    const getCertificateFile = useCallback(async (certificateId) => {
        setGlobalLoader()
        let data = null;
        try {
            await axios.get(
                `certificate/${certificateId}/pdf`, {
                    responseType: "blob"
                },
            ).then(res => {
                data = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }

        return data;
    }, [setModalError, setGlobalLoader, unsetGlobalLoader])

    const updateCertificate = async (data, id) => {
        setGlobalLoader();
        try {
            await axios.post(
                `certificate/${id}`,
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Certyfikat został zaktualizowany',
                    },
                }
                setModalError(defRes);
                getCertificateList();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }

    }

    const deleteCertificate = async (certificateId) => {
        setGlobalLoader();
        try {
            await axios.delete(
                `certificate/${certificateId}`,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Certyfikat został usunięty',
                    },
                }
                setModalError(defRes);
                getCertificateList();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

//<------  ADMIN CERTIFICATION SUBPAGE


// ADS CERTIFICATION SUBPAGE ----->


// <---- ADS CERTIFICATION SUBPAGE


//ADVERSE EVENTS SUBPAGE ------>
    const getAdditionalAdverseInfo = async () => {
        setGlobalLoader();
        try {
            await axios.get(
                `adverse-events/form-set`,
            ).then(res => {
                const payload = {
                    adverseEventsName: res.data.adverseEventsName,
                    adversePatients: sortArray(true, res.data.patients, 'patient'),
                }
                dispatch({type: GET_ADDITIONAL_ADVERSE_INFO, payload});
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
    const sendAdverseEvent = async (data) => {
        setGlobalLoader();
        try {
            await axios.post(
                'adverse-events',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Formularz został przesłany prawidłowo',
                    },
                }
                setModalError(defRes);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }
//<------  ADVERSE EVENTS SUBPAGE
//PROFILE SUBPAGE ------>
    const getProfileInfoForUser = async () => {
        setGlobalLoader()
        try {
            await axios.get('/profile').then(res => {
                    const payload = {
                        firstName: res.data.user.name,
                        secondName: res.data.user.surname,
                        institutions: [...res.data.institutions.map(el => el)],
                        phoneNumber: res.data.user.phone,
                        role: res.data.user.roles[0],
                        type: res.data.user.type,
                        email: res.data.user.email,
                        id: res.data.user.identity,
                        language: [res.data.user.language],
                        contractNO: res.data.user.contractNo,
                        contractSignDate: null,
                        bankAccount: res.data.user.bankAccount,
                        city: res.data.user.city,
                        zipCode: res.data.user.postCode,
                        street: res.data.user.street,
                    }
                    unsetGlobalLoader()
                    dispatch({type: GET_PROFILE_INFO, payload})
                },
            )
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
    const editProfileInfoByUser = async (userInfo) => {
        setGlobalLoader()
        const data = new FormData()
        data.append('bankAccount', userInfo.bankAccount)
        data.append('city', userInfo.city)
        data.append('postCode', userInfo.zipCode)
        data.append('street', userInfo.street)
        try {
            await axios.post('/user_edit',
                data,
            ).then(res => {
                    unsetGlobalLoader()
                    let defRes = {
                        data: {
                            code: 200,
                            message: 'Edycja wprowadzona',
                        },
                    }
                    setModalError(defRes)
                    getProfileInfoForUser()
                    return true
                },
            )
        } catch
            (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
//<------  PROFILE SUBPAGE

    //additional working hours ----->
    const getAllWT = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                'extra_work_times/index',
            ).then(res => {
                dispatch({type: GET_WORKING_HOURS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);

        }
    }
    const addWT = async (data) => {
        setGlobalLoader()
        try {
            await axios.post(
                'extra_work_times',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Dodano zgłoszenie',
                    },
                }
                setModalError(defRes);
                getAllWT();
                return true


            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);

        }
    }

    const proceedOvertime = async (ticketID) => {
        setGlobalLoader()
        try {
            await axios.put(
                `extra_work_times/${ticketID}/accept`,
                {},
            ).then(res => {
                unsetGlobalLoader();
                getAllWT()
                let defRes = {
                    data: {
                        code: 200,
                        message: "Wniosek oznaczony jako zaakceptowany"
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }
    const cancelOvertime = async (ticketID) => {
        setGlobalLoader()
        try {
            await axios.put(
                `extra_work_times/${ticketID}/reject`,
                {},
            ).then(res => {
                unsetGlobalLoader();
                getAllWT()
                let defRes = {
                    data: {
                        code: 200,
                        message: "Wniosek oznaczony jako odrzucony"
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }

    //interactions

    const getInteractions = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                'interactions',
            ).then(res => {
                dispatch({type: GET_INTERACTIONS, payload: res.data})
                unsetGlobalLoader();
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);

        }
    }
    const proceedCertificate = async (itemID) => {
        setGlobalLoader()
        try {
            await axios.post(
                `certificate/${itemID}/complete`,
                {},
            ).then(res => {
                unsetGlobalLoader();
                getCertificateList()
                let defRes = {
                    data: {
                        code: 200,
                        message: "Certyfikat zaakceptowany"
                    },
                }
                setModalError(defRes)
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }


    return (
        <AppContext.Provider value={{
            appState: state,

            //ADMIN USER SUBPAGE --->
            getUsers,
            clearUsers,
            getAdditionalUserFormInfo,
            addUser,
            getUser,
            deleteUser,
            editUser,
            //<----- ADMIN USER SUBPAGE

            //ADMIN ADVERSE EVENTS SUBPAGE --->
            getAdditionalAdverseInfo,
            sendAdverseEvent,
            //<----- ADMIN ADVERSE EVENTS SUBPAGE

            //ADMIN INSTITUTION SUBPAGE --->
            getInstitutionListInfo,
            getUsersFromInstitutions,
            getInstitutionInfo,
            addInstitution,
            editInstitution,
            deleteInstitution,
            //<----- ADMIN INSTITUTION SUBPAGE

            //PROFILE SUBPAGE ------>
            getProfileInfoForUser,
            editProfileInfoByUser,
            //<------  PROFILE SUBPAGE

            //CERTIFICATION SUBPAGE ------>
            getCertificateList, proceedCertificate,
            addCertificate, getCertificateFile,
            updateCertificate,
            clearCertificateList, deleteCertificate,
            //<------ CERTIFICATION SUBPAGE
            //extra working hours
            getAllWT, addWT, proceedOvertime, cancelOvertime, getInteractions,
            //KNOWLEDGE BASE
            addKnowledgeItem, getKnowledgeItems, getKnowledgeFile,
        }}>
            {children}
        </AppContext.Provider>
    )
}
