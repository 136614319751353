import {Box, Button, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import InfoIcon from '../../../../assets/img/ui/infoIcon.png';

export default function InfoBanner({children, text, button, position = 'static', top, right, setModal, noSlice}) {
    const [checked, setChecked] = useState(false)
    return <Stack color='#D32F2F' direction="row" p={'1px 8px 5px'} bgcolor='red.light' spacing={1} sx={{
        flexGrow: 1, borderRadius: '4px', position, top, right, zIndex: '1',
    }}>
        <Box my={0.8}>
            <img src={InfoIcon} alt=""/>
        </Box>
        <Box pt={0} sx={{width: '100%'}}>
            <Stack spacing={4} direction="row" sx={{justifyContent: 'space-between'}}>
                <Typography mt={0.8}><b>{children.toUpperCase()}</b></Typography>
                {button && <Button onClick={() => {
                    setChecked(true);
                    setModal && setModal(true)
                }} variant='contained'>ZOBACZ WSZYSTKIE UWAGI</Button>}
            </Stack>
            {!checked && <Typography mt={0.5}
                                     variant='body2'>Uwagi: {noSlice ? text : text.slice(0, 25)}{text.length > 25 && !noSlice ? ' (...)' : ''}</Typography>}
        </Box>
    </Stack>
};