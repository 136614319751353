import React from 'react';
import { Grid } from '@mui/material';

const Footer = () => {
	const yearOfCreation = 2022;
	const currentYear = new Date().getFullYear();
	const templateString = `Softwebo© ${yearOfCreation === currentYear ? yearOfCreation : `${yearOfCreation} - ${currentYear}`}`;

	return (
		<Grid className="footer">
			<p>Help desk: <a href="mailto:wojciech.piotrowicz@softwebo.pl">wojciech.piotrowicz@softwebo.pl</a> | <a target={"_blank"} href={"https://softwebo.atlassian.net/servicedesk/customer/portal/12"} rel="noreferrer">Jira Service Management</a></p>
			<p><a href="https://softwebo.pl/" target="_blank" rel="noreferrer">{templateString}</a></p>
		</Grid>
	)
}

export default Footer;