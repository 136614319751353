import {makeAutoObservable} from 'mobx';

export class EmailNotificationStore {
  users = null;
  data = null;

  constructor() {
    makeAutoObservable(this);
  }

  setEmailNotification(formData) {
    if (formData) {
      this.users = formData.users ? formData.users.map((user) => {
        return {id: user.id, title: `${user.fullName} (${user.email})`};
      }) : [];
      this.data = formData.data ?? [];
    }
  }

  clearEmailNotification() {
    this.users = null;
    this.data = null;
  }
}