import React, { memo, useCallback, useEffect, useRef, useState } from 'react';


export const DropZone = memo((props) => {
	const { onDragStateChange, onFilesDrop, onDrag, onDragIn, onDragOut, onDrop, children } = props;

	// Create state to keep track when dropzone is active/non-active:
	const [isDragActive, setIsDragActive] = useState(false);

	// Prepare ref for dropzone element:
	const dropZoneRef = useRef(null);

	// Create helper method to map file list to array of file:
	const mapFileListToArray = (files) => {
		const array = [];
		for (let i = 0; i < files.length; i++) {
			array.push(files.item(i));
		}

		return array;
	};

	// Create handler for dragenter event:
	const handleDragIn = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();
			onDragIn?.();

			if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
				setIsDragActive(true);
			}
		},
		[onDragIn]
	);

	// Create handler for dragleave event:
	const handleDragOut = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();
			onDragOut?.();

			setIsDragActive(false);
		},
		[onDragOut]
	);

	// Create handler for dragover event:
	const handleDrag = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			onDrag?.();
			if (!isDragActive) {
				setIsDragActive(true);
			}
		},
		[isDragActive, onDrag]
	);

	// Create handler for drop event:
	const handleDrop = useCallback(
		(event) => {
			event.preventDefault();
			event.stopPropagation();

			setIsDragActive(false);
			onDrop?.();

			if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
				const files = mapFileListToArray(event.dataTransfer.files);
				onFilesDrop?.(files);
				event.dataTransfer.clearData();
			}
		},
		[onDrop, onFilesDrop]
	);

	// Observe active state and emit changes:
	useEffect(() => {
		onDragStateChange?.(isDragActive);
	}, [isDragActive, onDragStateChange]);

	// Attach listeners to dropzone on mount:
	useEffect(() => {
		const tempZoneRef = dropZoneRef?.current;
		if (tempZoneRef) {
			tempZoneRef.addEventListener('dragenter', handleDragIn);
			tempZoneRef.addEventListener('dragleave', handleDragOut);
			tempZoneRef.addEventListener('dragover', handleDrag);
			tempZoneRef.addEventListener('drop', handleDrop);
		}

		// Remove listeners from dropzone on unmount:
		return () => {
			tempZoneRef?.removeEventListener('dragenter', handleDragIn);
			tempZoneRef?.removeEventListener('dragleave', handleDragOut);
			tempZoneRef?.removeEventListener('dragover', handleDrag);
			tempZoneRef?.removeEventListener('drop', handleDrop);
		};
	}, [handleDragIn, handleDragOut, handleDrag, handleDrop]);

	// Render <div> with ref and children:
	return <div ref={dropZoneRef}>{children}</div>;
})