import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context';
import { useWarehouseApi } from '../../../../apiHooks';
import { ADS_PUMP_ACTIONS, pumpActionsSetter, WAREHOUSE_KEEPER_PUMP_ACTIONS } from './actionSetter';
import { ROLE_ADS, ROLE_KAM, ROLE_WAREHOUSE } from '../../../../data/consts/users';

export const useActionCellViewModel = (status, pumpId) => {
	const { role } = useContext(AuthContext);

	const { addPumpToTrash, addPumpToWarehouse, setPumpToHospital, getPdfLogByWarehouseKeeper } = useWarehouseApi()

	const [actionsArray, setActionsArray] = useState([])

	const [currentAction, setCurrentAction] = useState(null);

	useEffect(() => {
		const newActionsArray = pumpActionsSetter(status, role);
		setActionsArray(newActionsArray)
		setCurrentAction(newActionsArray.length === 0 ? null : newActionsArray[0].id)
	}, [status, role])

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModalHandler = () => setIsModalOpen(true);

	const closeModalHandler = () => setIsModalOpen(false);

	const changeActionHandler = (e) => setCurrentAction(e.target.value)

	const buttonClickHandler = () => {
		if (role === ROLE_WAREHOUSE) {
			switch (currentAction) {
				case WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_TRASH.id:
					void addPumpToTrash(pumpId);
					break;
				case WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_SERVICE.id:
					openModalHandler();
					break;
				case WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_WAREHOUSE.id:
					void addPumpToWarehouse(pumpId);
					break;
				case WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF.id:
					void getPdfLogByWarehouseKeeper(pumpId)
					break;
				default:
					throw new Error('Unknown action');
			}
		}

		if (role === ROLE_KAM || role === ROLE_ADS) {
			switch (currentAction) {
				case ADS_PUMP_ACTIONS.SET_TO_HOSPITAL.id:
					void setPumpToHospital(pumpId);
					break;
				case ADS_PUMP_ACTIONS.SET_TO_WAREHOUSE.id:
					openModalHandler();
					break;
				default:
					throw new Error('Unknown action');
			}
		}
	}

	return {
		currentAction,
		actionsArray,
		changeActionHandler,
		buttonClickHandler,
		isModalOpen,
		closeModalHandler
	}
}