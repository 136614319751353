import {useRootStore} from '../RootStateContext';

export const useEmailNotificationStore = () => {
  const rootStore = useRootStore();

  if (!rootStore.emailNotificationsStore) {
    throw new Error('emailNotificationsStore store should be defined');
  }

  return rootStore.emailNotificationsStore;
};