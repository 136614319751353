import * as yup from 'yup';
import {isIdentifier, isZipCode} from "../../../../utils/helpfunc";

export const headCells = [
    {id: 'id', label: 'LP'},
    {id: 'name', label: 'Nr. placówki'},
    {id: 'identifier', label: 'Nazwa'},
    {id: 'address', label: 'Adres'},
    {id: 'zipCode', label: 'Kod pocztowy'},
    {id: 'voivodeship', label: 'Województwo'},
    {id: 'city', label: 'Miasto'},
    {id: 'users', label: 'Użytkownicy'},
    {id: 'actions', label: 'Działania'},
];

export const validationSchema = yup.object().shape({
    identifier: yup
        .string()
        .test(isIdentifier)
        .required(),
    name: yup
        .string()
        .required(),
    address: yup
        .string()
        .required(),
    zipCode: yup
        .string()
        .test(isZipCode)
        .required(),
    voivodeship: yup
        .string()
        .required(),
    city: yup
        .string()
        .required(),
    roles: yup
        .array()
        .required()
});

export const hospitalDataInit = {
    identifier: '',
    name: '',
    address: '',
    zipCode: '',
    voivodeship: '',
    city: '',
    delivery: '',
    roles: [],
};
