import { createTheme, responsiveFontSizes } from "@mui/material";

export const colors = {
	primary : {
		main: '#30A6A6',
	},
	secondary: {
		light: '#3C6D97',
		main: '#071d49',
		dark: '#202124',
	},
	grey: {
		light: '#8B91A5',
		main: '#757575',
		dark: '#7E8393'
	},
	error: {
		light: '#FFEAEA',
		main: '#D35858',
	},
	magenta: {
		dark: '#242124'
	},
	white: '#FFFFFF'
}


let theme = createTheme({
	palette: {
		...colors
	},
	typography: {
		body1: {
			fontSize: '0.75rem',
			'@media (min-width:1200px)': {
				fontSize: '1rem'
			}
		},
		subtitle1: {
			color: colors.secondary.main,
			fontWeight: 'bold',
		},
		subtitle2: {
			color: colors.secondary.main,
			fontWeight: 'normal',
		},
		formHeader: {
			color: colors.secondary.main,
			fontSize: "2.25rem",
			lineHeight: "1.3",
			fontWeight: "bold",
			width: "100%",
			maxWidth: "950px",
			borderBottom: "1px solid rgba(0, 0, 0, .12)",
			marginBottom: "40px",
		},
		red : {
			color: colors.error.main
		},
		orange : {
			color: "orange"
		}
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				root: {
					zIndex: '100000',
				},
			},
		},
		MuiPopper: {
			styleOverrides: {
				root: {
					zIndex: '100000 !important',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: colors.white,
					":hover": {
						opacity: 0.85
					}
				},
				containedPrimary: {
					":hover": {
						backgroundColor: colors.primary.main
					}
				},
				containedSecondary: {
					backgroundColor: colors.secondary.light,
					":hover": {
						backgroundColor: colors.secondary.light,
					}
				},
				disabled: {
					backgroundColor: '#C9CED6'
				}
			},

			variants: [
				{
					props: {variant: 'blueDark'},
					style: {
						backgroundColor: colors.secondary.dark,
						color: colors.white,
						":hover": {
							backgroundColor: colors.secondary.dark,
						}
					}
				},
				{
					props: {variant: 'greyLight'},
					style: {
						backgroundColor: colors.grey.light,
						color: colors.white,
						":hover": {
							backgroundColor: colors.grey.light,
						}
					}
				},
				{
					props: {variant: 'greyDark'},
					style: {
						backgroundColor: colors.grey.dark,
						color: colors.white,
						":hover": {
							backgroundColor: colors.grey.dark,
						}
					}
				}
			],
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					pointerEvents: 'none'
				}
			}
		},
		MuiInputLabel: {
			styleOverrides : {
				root: {
					backgroundColor: 'white'
				}
			}
		}
	},
})

theme = responsiveFontSizes(theme)

export default theme;