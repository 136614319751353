import React from 'react';
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	OutlinedInput,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material';

const CircumstancesInfo = ({ circumstancesModule, circumstancesModuleHandler, isPatientParam }) => {


	return (
		<Grid container border={`1px solid #071d49`}>
			<Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
				<Typography
					variant="h6"
					color="white"
				>INFORMACJE O OKOLICZNOŚCIACH</Typography>
			</Grid>
			<Grid item p={2} container>
				<Grid item mb={2}>
					<Typography variant="body2" color="#0f5e8d" sx={{ fontStyle: 'italic' }}>(należy uwzględnić
						czynności, jakie wykonywał pacjent w momencie zdarzenia oraz opisać stosowaną terapię, w tym
						przepisane antybiotyki, wyniki badań diagnostycznych / laboratoryjnych):</Typography>
				</Grid>
				<Grid container justifyContent="center" alignItems="center" mb={2}>
					<Grid item xs={12} lg={8}>
						<FormControl fullWidth required>
							<Typography variant="subtitle1">Opis:</Typography>
							<TextField
								fullWidth
								name="description"
								multiline
								minRows="4"
								value={circumstancesModule.description}
								onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12} lg={8}>
						<FormControl fullWidth required>
							<Typography variant="subtitle1">Stan pacjenta / wynik zdarzenia:</Typography>
							<TextField
								fullWidth
								name="condition"
								multiline
								minRows="4"
								value={circumstancesModule.condition}
								onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container className="dynamicTopBorder">
				<Grid item p={2} mb={2}>
					<Typography variant="body2" color="#0f5e8d" sx={{ fontStyle: 'italic' }}>Proszę zaznaczyć wszystkie
						produkty z wymienionych, poniżej, które mają związek z opisywanym zdarzeniem niepożądanym, a
						następnie uzupełnić części formularza dotyczące odpowiedniego produktu:</Typography>
				</Grid>
				<Grid item xs={12} container>
					<Grid item xs={12} md={6} p={2}>
						<Typography variant="subtitle1">Lista produktów: *</Typography>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="pump"
									disabled={isPatientParam}
									checked={circumstancesModule.pump}
									onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
								/>} label="pompa"/>
							<FormControlLabel control={
								<Checkbox
									name="nj"
									disabled={isPatientParam}
									checked={circumstancesModule.nj}
									onChange={(e) => {
										circumstancesModuleHandler(e.target.name, e.target.checked)
										circumstancesModuleHandler('startNJ', null)
									}}
								/>
							} label="sonda nosowo-jelitowa (NJ)"/>
							<FormControlLabel
								control={
									<Checkbox
										name="peg"
										disabled={isPatientParam}
										checked={circumstancesModule.peg}
										onChange={(e) => {
											circumstancesModuleHandler(e.target.name, e.target.checked)
											circumstancesModuleHandler('lastInstallPeg', null)
											circumstancesModuleHandler('pegSNo', '')
										}}
									/>
								}
								label="sonda PEG"
							/>
							<FormControlLabel
								control={
									<Checkbox
										name="jejunum"
										disabled={isPatientParam}
										checked={circumstancesModule.jejunum}
										onChange={(e) => {
											circumstancesModuleHandler(e.target.name, e.target.checked)
											circumstancesModuleHandler('lastInstallJejunum', null)
											circumstancesModuleHandler('jejunumSNo', '')
										}}
									/>
								}
								label="sonda jelitowa (jelito czcze)"
							/>
							<FormControlLabel control={
								<Checkbox
									name="gel"
									disabled={isPatientParam}
									checked={circumstancesModule.gel}
									onChange={(e) => {
										circumstancesModuleHandler(e.target.name, e.target.checked)
										circumstancesModuleHandler('gelSNo', '')
									}}
								/>
							} label="żel / kasetka / lek"/>
							<FormControlLabel
								control={
									<Checkbox
										name="anotherProduct"
										disabled={isPatientParam}
										checked={circumstancesModule.anotherProduct}
										onChange={(e) => {
											circumstancesModuleHandler(e.target.name, e.target.checked)
											if (!e.target.checked) {
												circumstancesModuleHandler('additionalProduct', '')
											}
										}}
									/>}
								label="inny (należy podać jaka)"
							/>
							<Box minHeight={56}>
								{
									circumstancesModule.anotherProduct && <FormControl fullWidth required>
										<OutlinedInput
											id="additionalProduct"
											name="additionalProduct"
											value={circumstancesModule.additionalProduct}
											onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
										/>
									</FormControl>
								}
							</Box>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={6} p={2}>
						<Typography variant="subtitle1">Dostępna próbka reklamacyjna: *</Typography>
						<RadioGroup
							name="complaintSample"
							value={circumstancesModule.complaintSample}
							onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
						>
							<FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
							<FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
						</RadioGroup>
						{
							circumstancesModule.complaintSample === 'Nie' &&
							<>
								<Typography variant="subtitle1">Jeżeli nie, należy zaznaczyć powód:</Typography>
								<FormGroup>
									<FormControlLabel control={
										<Checkbox
											name="discarded"
											checked={circumstancesModule.discarded}
											onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
										/>
									} label="nie zachowano / wyrzucono"/>
									<FormControlLabel control={
										<Checkbox
											name="stillInUse"
											checked={circumstancesModule.stillInUse}
											onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
										/>
									} label="nadal w użyciu"/>
									<FormControlLabel control={
										<Checkbox
											name="submitSample"
											checked={circumstancesModule.submitSample}
											onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.checked)}
										/>
									}
									                  label="pacjent wyraził zgodę na przesłanie próbki, AbbVie podejmie kroki w celu otrzymania próbek"/>
									<FormControlLabel
										control={
											<Checkbox
												name="anotherReason"
												checked={circumstancesModule.anotherReason}
												onChange={(e) => {
													circumstancesModuleHandler(e.target.name, e.target.checked)
													if (!e.target.checked) {
														circumstancesModuleHandler('additionalReason', '')
													}
												}}
											/>}
										label="inny powód (należy podać, jaki)"
									/>
									<Box minHeight={56}>
										{
											circumstancesModule.anotherReason && <FormControl fullWidth required>
												<OutlinedInput
													id="additionalReason"
													name="additionalReason"
													value={circumstancesModule.additionalReason}
													onChange={(e) => circumstancesModuleHandler(e.target.name, e.target.value)}
												/>
											</FormControl>
										}
									</Box>
								</FormGroup>
							</>
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CircumstancesInfo;