import React, {useContext} from 'react';
import {HardwareContext} from '../../../context';
import {ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import noImage from '../../../assets/img/brak_zdjecia.jpg'

const OrdersProductList = ({idSetter}) => {
    const {hardwareState: {hardware}} = useContext(HardwareContext)
    const HOST = process.env.REACT_APP_IMG_URL;

    return <ImageList
        gap={8}
        variant={'standard'}
        cols={2}
        rowHeight={'auto'}
    >
        {!!hardware.length && hardware.map(product => (
                <ImageListItem
                    key={product.id}
                    onClick={() => idSetter(product.id)}
                    sx={{cursor: 'pointer'}}
                >
                    <img
                        src={!!product.images.length ? `${HOST}/uploads/products/${product.images[0].image}` : noImage}
                        loading="lazy"
                        alt="order icon"
                        style={{height: '160px'}}
                    />
                    <ImageListItemBar
                        title={product.name}
                        // subtitle={product.package}
                    />
                </ImageListItem>
            ),
        )}
    </ImageList>
}

export default OrdersProductList