import React, {useContext, useState} from 'react';
import PageTitle from '../../shared/ui/PageTitle';
import {Button, FormControl, InputLabel, OutlinedInput, Typography} from '@mui/material';
import {AuthContext} from "../../../context/auth/authContext";

const ResetPasswordEmail = () => {
    const {resetPasswordEmail} = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();

        setEmailError(!/^\S+@\S+\.\S+$/.test(email))

        if (!emailError) {
            resetPasswordEmail(email);
        }
    }

    return (
        <form
            className="auth"
            onSubmit={submitHandler}
        >
            <PageTitle title="Reset password:"/>
            <Typography variant="body1" mb={2}>Podaj swój adres email. Link do resetowania hasła zostanie przesłany
                wiadomością email.</Typography>
            <FormControl required>
                <InputLabel htmlFor="email" sx={{width: '100px'}}>Email</InputLabel>
                <OutlinedInput
                    id="email"
                    label="Email"
                    placeholder="enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                />
                <Typography variant='caption' mb={3}
                            sx={{color: 'red', height: '10px'}}
                >
                    {emailError ? 'check your email' : ''}
                </Typography>
            </FormControl>
            <Button
                type="submit"
                color="primary" variant="contained"
                sx={{alignSelf: 'center'}}
            >
                Wyślij
            </Button>
        </form>
    )
}

export default ResetPasswordEmail;