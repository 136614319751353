import { makeAutoObservable } from 'mobx';

export class AlertStore {
  alerts = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAlerts(alerts) {
    this.alerts = {
      patients: alerts.patients,
      pumps: alerts.pumps,
      certificates: alerts.certificates,
      messages: alerts.messages
    };
  }

  clearAlerts() {
    this.alerts = null;
  }
}
