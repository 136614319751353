export const headCells = [
    {
        id: 'id',
        label: ' ID',
    },
    {
        id: 'displayName',
        label: 'Inicjały',
    },
    {
        id: 'institution',
        label: 'Placówka',
    },
    {
        id: 'createdBy',
        label: 'Dodający',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'actions',
        label: 'Działania',
    },
];

export const reasonsNoPump = ['Zgon', 'Brak opiekuna', 'Wycofanie zgody na terapie', 'Stan psychiczny - psychoza', 'Wyrwanie PEGa', 'Brak skuteczności', 'Inne']