import React, { useEffect } from 'react';
import { useWarehouseApi } from '../../../../../apiHooks';
import { Grid } from "@mui/material";
import PageTitle from '../../../../shared/ui/PageTitle';
import { PumpsTable } from './pumpsTable/PumpsTable';


export const ADSPumps = () => {

	const { getADSPumps } = useWarehouseApi();

	useEffect(() => {
		void getADSPumps();
	}, [getADSPumps]);

	return (
		<Grid>
			<PageTitle title="Pompy"/>
			<PumpsTable/>
		</Grid>
	)
}