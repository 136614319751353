import { useMemo } from 'react';
import axios from 'axios';
import { useLoadersStore, useNotificationStore } from '../../store/hooks';
import { notificationHandler } from './helper';

export const useApiCall = () => {
	const loadersStore = useLoadersStore();
	const notificationsStore = useNotificationStore();

	const api = useMemo(() => {
		axios.defaults.baseURL = process.env.REACT_APP_API_URL;
		axios.defaults.withCredentials = true;
		axios.defaults.headers.common['Content-Type'] = 'application/json';
		
		const responseBody = (response) => response.data;

		const apiInstance = {
			get: async (url, isGlobalLoader, config) => {
				isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

				return axios
					.get(url, config)
					.then(responseBody)
					.catch((error) => notificationsStore.setNotification(notificationHandler(error.response.data.status, error.response.data.message)))
					.finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
			},

			post: async (url, body, isGlobalLoader) => {
				isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();
				return axios
					.post(url, body)
					.then(responseBody)
					.catch((error) => notificationsStore.setNotification(notificationHandler(error.response.data.status, error.response.data.message)))
					.finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
			},

			put: async (url, body, isGlobalLoader) => {
				isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

				return axios
					.put(url, body)
					.then(responseBody)
					.catch(error => notificationsStore.setNotification(notificationHandler(error.response.data.status, error.response.data.message)))
					.finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
			},
			patch: async (url, body, isGlobalLoader) => {
				isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

				return axios
					.patch(url, body)
					.then(responseBody)
					.catch((error) => notificationsStore.setNotification(notificationHandler(error.response.data.status, error.response.data.message)))
					.finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
			},

			delete: async (url, isGlobalLoader) => {
				isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

				return axios
					.delete(url)
					.then(responseBody)
					.catch((error) => notificationsStore.setNotification(notificationHandler(error.response.data.status, error.response.data.message)))
					.finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
			}
		};

		return { apiInstance };
	}, [loadersStore, notificationsStore]);

	return { api };
}