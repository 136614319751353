import React, { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const SideBar = ({ links }) => {

	const [isOpen, setIsOpen] = useState(false);
	const { width } = useWindowDimensions();

	let draw

	const toggleMenu = () => setIsOpen(prev => !prev);

	if (width >= 1024) {
		draw = (
			<Grid className="sideBar">
				{links}
			</Grid>
		)
	} else {
		draw = (
			<Grid className="menu" style={{ marginLeft: isOpen ? '0px' : '-200px' }}>
				<Grid className="menu__buttonArea" onClick={toggleMenu}>
					{
						isOpen
							?
							<MenuOpenIcon fontSize="large" style={{ cursor: 'pointer' }}/>
							:
							<MenuIcon fontSize="large" style={{ cursor: 'pointer' }}/>
					}
				</Grid>
				{isOpen && links}
			</Grid>
		)
	}

	return draw
}

export default SideBar;