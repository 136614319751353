import React from 'react'
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography,} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";

const AdverseEventsTable = ({
                                adverseEventsTableValue,
                                adverseEventsTableHandler,
                                addAdverseEventsTableRow,
                                delAdverseEventsTableRow,
                            }) => {
    return (
        <>
            <Grid container border={`1px solid #071d49`} display="flex" direction="row">
                <Grid item className="subMenuSidebar">Zdarzenia</Grid>
                <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container>
                    {
                        adverseEventsTableValue.map((el, index) => (
                            <Grid key={index} item xs={12} className="formRow">
                                <Grid item xs={12} container display="flex" direction="row"
                                      justifyContent="space-between">
                                    <p className="subMenuHeader">Zdarzenie {index + 1}</p>
                                    {
                                        adverseEventsTableValue.length > 1 &&
                                        <Button
                                            variant="contained"
                                            color="error"
                                            className="formButton"
                                            onClick={() => delAdverseEventsTableRow(index)}
                                        >
                                            usun zdarzenie
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={12} container>
                                    <Grid item xs={12} lg={4} p={2}>
                                        <FormControl fullWidth >
                                            <Typography variant="subtitle1">Proszę wymienić wszystkie objawy, które
                                                wystąpiły oraz rozpoznania: *</Typography>
                                            <TextField
                                                required
                                                fullWidth
                                                name="details"
                                                multiline
                                                minRows="4"
                                                value={el.details}
                                                onChange={(e) => adverseEventsTableHandler(e.target.name, e.target.value, el, index)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={4} p={2} container display="flex" direction="column"
                                          justifyContent="center">
                                        <Stack spacing={3}>
                                            <DatePickerComponent
                                                disableFuture
                                                allowSameDateSelection
                                                value={el.dateStart}
                                                label="Data wystąpienia zdarzenia"
                                                onChange={(newValue) => adverseEventsTableHandler('dateStart', newValue, el, index)}
                                            />
                                            <DatePickerComponent
                                                disableFuture
                                                allowSameDateSelection
                                                value={el.dateEnd}
                                                label="Data zakończenia zdarzenia"
                                                onChange={(newValue) => adverseEventsTableHandler('dateEnd', newValue, el, index)}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={4} p={2} container display="flex" direction="column"
                                          justifyContent="center">
                                        <Stack spacing={3}>
                                            <FormControl fullWidth required>
                                                <InputLabel id="result-label" sx={{width: '60px'}}>Wynik</InputLabel>
                                                <Select
                                                    labelId="result-label"
                                                    id="result"
                                                    name="result"
                                                    value={el.result}
                                                    label="Wynik"
                                                    onChange={(e) => adverseEventsTableHandler(e.target.name, e.target.value, el, index)}
                                                >
                                                    <MenuItem value="" disabled={true}><em>Wybierz z
                                                        listy</em></MenuItem>
                                                    <MenuItem value="powrót do zdrowia">powrót do zdrowia</MenuItem>
                                                    <MenuItem value="w trakcie leczenia">w trakcie leczenia</MenuItem>
                                                    <MenuItem value="powrót do zdrowia z następstwami">powrót do zdrowia
                                                        z następstwami</MenuItem>
                                                    <MenuItem value="brak powrotu do zdrowia">brak powrotu do
                                                        zdrowia</MenuItem>
                                                    <MenuItem value="skutek śmiertelny">skutek śmiertelny</MenuItem>
                                                    <MenuItem value="skutek nieznany">skutek nieznany</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth required>
                                                <InputLabel id="causation-label" sx={{width: '250px'}}>Związek
                                                    przyczynowy z lekiem</InputLabel>
                                                <Select
                                                    labelId="causation-label"
                                                    id="causation"
                                                    name="causation"
                                                    value={el.causation}
                                                    label="Związek przyczynowy z lekiem"
                                                    onChange={(e) => adverseEventsTableHandler(e.target.name, e.target.value, el, index)}
                                                >
                                                    <MenuItem value="" disabled={true}><em>Wybierz z
                                                        listy</em></MenuItem>
                                                    <MenuItem value="prawdopodobny">prawdopodobny</MenuItem>
                                                    <MenuItem value="możliwy">możliwy</MenuItem>
                                                    <MenuItem value="mało prawdopodobny">mało prawdopodobny</MenuItem>
                                                    <MenuItem value="brak związku">brak związku</MenuItem>
                                                    <MenuItem value="nie zgłoszono">nie zgłoszono</MenuItem>
                                                    <MenuItem value="brak możliwości oceny związku">brak możliwości
                                                        oceny związku</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Button variant="contained" color="success" className="formButton" onClick={addAdverseEventsTableRow}>dodaj
                    zdarzenie</Button>
            </Grid>
        </>
    )
}

export default AdverseEventsTable;