import { useRootStore } from '../RootStateContext';


export const useNotificationStore = () => {
	const rootStore = useRootStore();

	if (!rootStore.notificationsStore) {
		throw new Error('Notifications store should be defined');
	}

	return rootStore.notificationsStore;
}