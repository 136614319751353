import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import PageTitle from '../../../../shared/ui/PageTitle';
import { useWarehouseApi } from '../../../../../apiHooks';
import { PumpsTable } from './pumpsTable/PumpsTable';

export const Pumps = () => {

	const { getWarehouseKeeperPumps } = useWarehouseApi()

	useEffect(() => {
		void getWarehouseKeeperPumps()
	}, [getWarehouseKeeperPumps]);

	return (
		<Grid container>
			<PageTitle title="Pompy"/>
			<PumpsTable/>
		</Grid>
	);
}