import React from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {coachingCheckboxesData, getStyles, MenuProps} from "./coachingData";
import {useCoachings} from "./useCoachings.vm";
import {DatePickerComponent} from "../../../shared/ui/DatePickerComponent";

const CoachingAdd = ({reckoningStatus, setIsAdding, coachingInfo}) => {
    const {
        edition,
        theme,
        checkboxes,
        submitHandler,
        makersHandler,
        checksHandler,
        date,
        duration,
        setDate,
        setDuration,
        makers,
        settlementsState,
        yearMonth,
        lastDay, firstDay
    } = useCoachings(reckoningStatus, setIsAdding, coachingInfo)

    return (
        <Grid item container className="settlements__DatePicker" sx={{bgcolor: '#F5F5F5'}} direction="row"
              alignItems="center"
              mb={1}>
            <Box className="settlements__addBox">
                <Typography className="settlements__addTitle" sx={{
                    fontWeight: "bold",
                    maxWidth: "48rem",
                    borderBottom: "solid 2px #ccc",
                    color: `${!edition ? "grey.main" : null}`
                }}>Coaching</Typography>
                <form onSubmit={submitHandler}>
                    <Grid container pb={4} justifyContent='space-between' sx={{width: '100%'}}>
                        <Grid container item justifyContent='space-between'>
                            <Grid item xs={12} lg={5.8} mt={3}>
                                <DatePickerComponent
                                    required
                                    disableFuture
                                    disabled={!edition}
                                    label='Data coachingu'
                                    openTo="day"
                                    views={['day']}
                                    defaultCalendarMonth={new Date(yearMonth)}
                                    minDate={firstDay}
                                    maxDate={lastDay}
                                    value={date}
                                    onChange={(newValue) => setDate(newValue)}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5.8} mt={3}>
                                <TextField disabled={!edition} label='Czas trwania (minuty)' name="duration"
                                           type="number" value={duration} onChange={(e) => setDuration(e.target.value)}
                                           fullWidth required/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <FormControl fullWidth>
                                <InputLabel id='roles-label'>Uczestnicy</InputLabel>
                                <Select labelId='roles-label' multiple value={makers} disabled={!edition}
                                        onChange={(e) => makersHandler(e, 'who')}
                                        input={<OutlinedInput label='Uczestnicy'/>} MenuProps={MenuProps}>
                                    <MenuItem name='Wszystkie' value='Wszystkie'
                                              style={{
                                                  fontWeight: settlementsState.listOfUsers && !!settlementsState.listOfUsers.length && !!makers.length && makers.length !== settlementsState.listOfUsers.length ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
                                              }}>
                                        Wszystkie
                                    </MenuItem>
                                    {settlementsState.listOfUsers && settlementsState.listOfUsers.map((item) => (
                                        <MenuItem key={item.id} value={item.id}
                                                  style={getStyles(item.name, makers, theme)}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Stack direction="row" alignItems='center' py={4} sx={{borderBottom: "solid 1px #ccc"}}>
                            <Typography sx={{color: `${!edition ? "grey.main" : null}`}} mr={2}><b>Rodzaj kontaktu:</b></Typography>
                            <div>
                                {coachingCheckboxesData.coachings.map(el => (
                                    <FormControlLabel
                                        key={el.name}
                                        control={
                                            <Checkbox
                                                disabled={!edition}
                                                name={el.name}
                                                checked={checkboxes[el.name]}
                                                onChange={(e) => checksHandler(e.target.name, e.target.checked)}
                                            />} label={el.label}/>)
                                )}
                            </div>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} mb={2} border={'1px solid #f5f5f5'} borderRadius={'6px'} bgcolor={'#f5f5f5'}>
                        <FormGroup>
                            <>
                                {coachingCheckboxesData.checkboxes.map(el => (
                                    <FormControlLabel
                                        key={el.name}
                                        control={
                                            <Checkbox
                                                disabled={!edition}
                                                name={el.name}
                                                checked={checkboxes[el.name]}
                                                onChange={(e) => checksHandler(e.target.name, e.target.checked)}
                                            />} label={el.label}/>
                                ))}
                            </>
                        </FormGroup>
                    </Grid>

                    <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                        {edition &&
                            <Button type="submit" color="primary" variant="contained" className="buttonCustom"
                                    sx={{margin: "auto"}}>Zapisz</Button>}
                    </Stack>
                </form>
            </Box>
        </Grid>
    )
};

export default CoachingAdd