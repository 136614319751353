export const formatYearMonth = (dateFromParams) => {
    const year = dateFromParams.slice(0, 4)
    const month = dateFromParams.slice(5, 7)
    const firstDay = new Date(year, month - 1, 1)
    const lastDay = new Date(year, month, 0)

    return {
        year, month, firstDay, lastDay
    }
}

export const convertUTCToDate = (date) => {
    return date.toLocaleDateString();
}