import { useCallback, useState } from 'react';
import { useWarehouseApi } from '../../../../../../apiHooks';


export const useOrdersModalViewModel = (closeModalHandler) => {
	const warehouseApi = useWarehouseApi();

	const [file, setFile] = useState(null);
	const [isError, setIsError] = useState(false);
	const [isDropActive, setIsDropActive] = useState(false);

	const onDrugStateChangeHandler = useCallback((dragActive) => {
		setIsDropActive(dragActive)
	}, [])

	const fileValidator = (tempFile) => {
		setIsError(false)
		return tempFile.name.split('.').at(-1) === 'xlsx';
	}

	const onFileDropHandler = useCallback((newFile) => {
		const currentFile = newFile[0];
		if (fileValidator(currentFile)) {
			setFile(currentFile);
		} else {
			setIsError(true);
		}
	}, [])

	const onChangeHandler = (event) => {
		if (fileValidator(event.target.files[0])) {
			setFile(event.target.files[0]);
			setIsDropActive(true)
		} else {
			setIsError(true);
		}
	}

	const uploadFileHandler = async () => {
		const isFileLoaded = await warehouseApi.uploadFile(file);
		if (isFileLoaded) {
			setFile(null);
			closeModalHandler();
		}
		return isFileLoaded;
	}

	const clearStateHandler = () => {
		setIsError(false);
		setFile(null);
	}

	return {
		isError,
		file,
		isDropActive,
		onFileDropHandler,
		onDrugStateChangeHandler,
		onChangeHandler,
		uploadFileHandler,
		clearStateHandler
	}
}