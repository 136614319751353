import { makeAutoObservable } from 'mobx';

export class NotificationStore {
	notification = null;

	constructor() {
		makeAutoObservable(this);
	}

	setNotification(notification) {
		this.notification = {
			message: notification.message,
			notificationType: notification.type
		};
	}

	clearNotification() {
		this.notification = null;
	}
}