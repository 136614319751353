import {useEffect, useState} from 'react';
import {getComparator, stableSort} from "../utils/sortingTable";

const ASC = 'asc'
const DESC = 'desc'

const initConfig = {
	orderBy: '',
	order: ASC,
	page: 0,
	rowsPerPage: 10,
	searchTerm: '',
	filters: []
}

const areObjectsEqual = (obj1, obj2) => {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
};

const areListsEqual = (list1, list2) => {
	if (list1.length !== list2.length) {
		return false;
	}

	for (let i = 0; i < list1.length; i++) {
		if (!areObjectsEqual(list1[i], list2[i])) {
			return false;
		}
	}

	return true;
};

const mergeArray = (array) => {
	return Array.isArray(array) ? array.reduce(
		(accumulator, currentValue) => `${accumulator === '' ? '' : `${accumulator} `}${currentValue.name === null ? '' : currentValue.name}`,
		''
	) : '';
}


export const defaultFilterData = (data, searchTerm, keysFilter, filters) => {
	const filterDataBySearchTerm = searchTerm !== '' ?  data.filter((row) =>
		keysFilter.some((keysString) => {
				const keys = keysString.split('.');
				const value = keys.length === 2 ? row[keys[0]][keys[1]] : row[keys[0]];
				const convertValue = Array.isArray(value) ? mergeArray(value) : value;

				if (convertValue !== 'LP' || convertValue !== 'actions') {
					return String(convertValue).toLowerCase().includes(searchTerm.toLowerCase())
				}

				return false;
			}
		)
	) : [...data];

	return filterDataBySearchTerm.filter((row) =>
		{
			return filters.every((filter) => row[filter.name] && row[filter.name].includes(String(filter.selectedValue)));
		}
	);
}

export const useTableController = (data, keysFilter, configTable = initConfig) => {
	const [page, setPage] = useState(configTable.page ?? initConfig.page);
	const [rowsPerPage, setRowsPerPage] = useState(configTable.rowsPerPage ?? initConfig.rowsPerPage);
	const [order, setOrder] = useState(configTable.order ?? initConfig.order);
	const [orderBy, setOrderBy] = useState(configTable.orderBy ?? initConfig.orderBy);
	const [searchTerm, setSearchTerm] = useState(configTable.searchTerm ?? initConfig.searchTerm)
	const [filteredData, setFilteredData] = useState(null);
	const [filters, setFilters] = useState(configTable.filters ?? initConfig.filters);
	const [drawArray, setDrawArray] = useState([]);
	const [selected, setSelected] = useState([]);

	useEffect(()=> {
		const newFilteredData = defaultFilterData(data, searchTerm ?? initConfig.searchTerm, keysFilter, filters ?? initConfig.filters);
		const isEquals = areListsEqual(newFilteredData ?? [], filteredData ?? []);

		if(!isEquals){
			setFilteredData(newFilteredData)
		}

	}, [data, filteredData, filters, keysFilter, searchTerm])

	useEffect(()=> {
		if(filteredData !== null){
			const filteredArray = stableSort(filteredData, getComparator(order, orderBy));
			setDrawArray(filteredArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
		}
	}, [filteredData, order, orderBy, page, rowsPerPage])
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(initConfig.page);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === ASC;
		setOrder(isAsc ? DESC : ASC);
		setOrderBy(property);
		setPage(initConfig.page);
	};

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchTerm(value);

		setFilteredData(!value ? data : defaultFilterData(data, value, keysFilter, filters));
		setPage(initConfig.page);
	};

	const filterChangeHandler = (property, value) => {
		const newFilters = filters.map((filter) => {
			filter.selectedValue = property === filter.name ? value : filter.selectedValue;
			return filter;
		})
		setFilters(newFilters);

		setFilteredData(!value ? data : defaultFilterData(data, searchTerm, keysFilter, newFilters));
		setPage(initConfig.page);
	};

	const handleSelectedItem = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	const count =  filteredData ? filteredData.length : 0;

	return {
		drawArray,
		count,
		searchTerm,
		page,
		rowsPerPage,
		order,
		orderBy,
		filters,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange,
		setFilteredData,
		handleSelectedItem,
		filterChangeHandler,
		isSelected,
		selected
	}
}