import {useCallback} from 'react';
import {useEmailNotificationStore} from '../store/hooks';
import {useApiCall} from './apiCall/useApiCall';

export const useEmailNotificationsApi = () => {
  const {api} = useApiCall();
  const emailNotification = useEmailNotificationStore();

  const getEmailNotificationsData = useCallback(async () => {
    const res = await api.apiInstance.get('emails/form-set', true);

    if (res) {
      emailNotification.setEmailNotification(res);
    }
  }, [api.apiInstance, emailNotification]);

  const putEmailNotificationsData = useCallback(async (data) => {
    const res = await api.apiInstance.put('emails', data, true);
    return !!res;
  }, [api.apiInstance]);

  return {
    getEmailNotificationsData, putEmailNotificationsData,
  };
};