import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import { AuthContext, ReckoningsContext, SettlementsContext } from "../../../../../context";
import { Grid } from "@mui/material";
import PageTitle from "../../../../shared/ui/PageTitle";
import { ROLE_ADMIN } from "../../../../../data/consts/users";

export const SettlementTitle = () => {

	const { settlementsState } = useContext(SettlementsContext);
	const { reckoningsState } = useContext(ReckoningsContext);
	const { role } = useContext(AuthContext);

	const userName = reckoningsState?.reckoning?.userName || null;
	const params = useParams();

	const currentPeriod = params.yearMonth?.split("-");
	const currentYear = currentPeriod?.length && +currentPeriod[0];
	const currentMonth = currentPeriod?.length && +currentPeriod[1];
	const currentPolishMonth = settlementsState.settlementsDates.length && settlementsState.settlementsDates[0][1][currentMonth - 1].monthPolish;

	return (
		<Grid item xs={12} md={4}>
			<PageTitle
				marginBottom={0}
				title={
					<>
						<span>Rozliczenie: </span>
						{currentPolishMonth && <b>{currentPolishMonth + " " + currentYear}</b>}
						{
							role === ROLE_ADMIN && userName &&
							<span style={{ marginLeft: '3rem', color: '#777' }}>
								<b>{userName}</b>
							</span>
						}
					</>
				}
			/>
		</Grid>
	)
}