import {Button, Grid, Typography} from "@mui/material";
import React from "react";

export const AdverseEventsErrorModal = ({message, closeModal}) => {
  return (
    <Grid
      className="customModal adverseEventsErrorModal errorModal">
      <Grid
        container
        flexDirection="column"
        className="customModal__content"
      >
        <Typography
          variant="h3">Błąd</Typography>
        <Grid className="customModal__content-errorMessageArea">
          <p>
            {message}
          </p>
        </Grid>
        <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
      </Grid>
    </Grid>
  )
}