import React, {useContext, useEffect, useState} from "react";
import {
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Tooltip, Box
} from "@mui/material"
import {HardwareContext} from "../../../context/hardware/hardwareContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {EnhancedTableHead, getComparator, stableSort} from "../../../utils/sortingTable";
import Search from './../../../components/shared/search/Search'

const HardwareTable = ({openModal, hardwareIDHandler}) => {
    const {hardwareState} = useContext(HardwareContext);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    useEffect(() => {
        let results = [];
        const isSearchStringEmpty = searchTerm === '';
        if (isSearchStringEmpty) {
            results = hardwareState.hardware
        } else {
            const searchTernLowerCase = searchTerm.toLowerCase();
            results = hardwareState.hardware.filter((item) =>
                item.name.toLowerCase().includes(searchTernLowerCase) ||
                item.package.toLowerCase().includes(searchTernLowerCase) ||
                item.details.filter(el => el.code.toLowerCase().includes(searchTernLowerCase)).length > 0
            )
        }
        setFilteredData(results)

    }, [searchTerm, hardwareState.hardware])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const headCells = [
        {id: 'ID', label: 'ID'},
        {id: 'name', label: 'Nazwa'},
        {id: 'package', label: 'Rozmiar'},
        {id: 'details', label: 'Wariant produktu'},
        {id: 'actions', label: 'Działania'},

    ]
    return (
        <>
            <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
                <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
            </Box>

            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredData.length}
                            headCells={headCells}
                        />
                        <TableBody>

                            {stableSort(filteredData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    const isItemSelected = isSelected(item.id);
                                    return (
                                        <TableRow hover
                                                  onClick={(event) => handleClick(event, item.id)}
                                                  role="row"
                                                  tabIndex={-1}
                                                  key={item.id}
                                                  selected={isItemSelected}>
                                            <TableCell component="th"
                                                       scope="row">
                                                {index + 1 + '.'}
                                            </TableCell>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.package}
                                            </TableCell>
                                            <TableCell>
                                                {item.details && item.details.map(el => <p key={el.id}>
                                                    <span>{`${el.name} (${el.code}),`}</span><br/></p>)}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <div className='addUserTable'>
                                                    <Tooltip title="Edytuj" placement="right">
                                                        <button onClick={() => {
                                                            hardwareIDHandler(item.id)
                                                            openModal('edit')
                                                        }}><EditIcon/>
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Wyświetl"  placement="right">
                                                        <button onClick={() => {
                                                            hardwareIDHandler(item.id)
                                                            openModal('show')
                                                        }}><RemoveRedEyeIcon/></button>
                                                    </Tooltip>
                                                    <Tooltip title="Usuń"  placement="right">
                                                        <button onClick={() => {
                                                            hardwareIDHandler(item.id)
                                                            openModal('delete')
                                                        }}><DeleteIcon/></button>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={hardwareState.hardware.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Elementy na stronie"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}
export default HardwareTable