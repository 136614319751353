import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import { useActionCellModalViewModel } from './useActionCellModal.vm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { pl } from 'date-fns/locale';
import { ROLE_WAREHOUSE } from '../../../../data/consts/users';
import { TextFieldEmptyError } from './TextFieldEmptyError';

export const ActionCellModal = observer(({ isModalOpen, closeModalHandler, pumpId }) => {

	const {
		role,
		wzNumber,
		setWzNumber,
		isWzNumberEmptyError,
		date,
		setDate,
		isDateError,
		adsReason,
		setAdsReason,
		isAdsReasonEmptyError,
		warehouseKeeperReason,
		setWarehouseKeeperReason,
		isWarehouseKeeperReasonEmptyError,
		buttonClickHandler
	} = useActionCellModalViewModel(pumpId, closeModalHandler);

	return (
		<Modal open={isModalOpen}>
			<Box sx={styles.boxStyle}>
				<Grid
					container
					flexDirection="column"
				>
					{
						role === ROLE_WAREHOUSE &&
						<>
							<TextField
								value={wzNumber}
								onChange={(event) => setWzNumber(event.target.value)}
								error={isWzNumberEmptyError}
								rows={4}
								placeholder="Numer listu przewozowego"
								label="Numer listu przewozowego"
								sx={{ minWidth: '500px' }}
							/>
							{
								isWzNumberEmptyError && <TextFieldEmptyError/>
							}
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
								<DemoContainer components={['DatePicker']} sx={{ width: '100%', marginTop: '8px' }}>
									<DatePicker
										slotProps={{ textField: { size: 'small' } }}
										label="Data wysyłki"
										value={date}
										onChange={setDate}
										sx={{ width: '500px', outline: 'none', marginBottom: '16px' }}
									/>
								</DemoContainer>
							</LocalizationProvider>
							{
								isDateError && <TextFieldEmptyError/>
							}
						</>
					}
					<TextField
						value={adsReason}
						onChange={(event) => setAdsReason(event.target.value)}
						error={isAdsReasonEmptyError}
						rows={4}
						multiline={true}
						placeholder={role === ROLE_WAREHOUSE ? 'Informacja od ADS' : 'Wpisz przyczynę'}
						label={role === ROLE_WAREHOUSE ? 'Informacja od ADS' : 'Wpisz przyczynę'}
						disabled={role === ROLE_WAREHOUSE}
						sx={{ minWidth: '500px', marginTop: '16px' }}
					/>
					{
						isAdsReasonEmptyError && <TextFieldEmptyError/>
					}
					{
						role === ROLE_WAREHOUSE &&
						<>
							<TextField
								value={warehouseKeeperReason}
								onChange={(event) => setWarehouseKeeperReason(event.target.value)}
								error={isWarehouseKeeperReasonEmptyError}
								rows={4}
								multiline={true}
								placeholder="Wpisz przyczynę"
								label="Wpisz przyczynę"
								sx={{ minWidth: '500px', marginTop: '16px' }}
							/>
							{
								isWarehouseKeeperReasonEmptyError && <TextFieldEmptyError/>
							}
						</>
					}
					<Grid
						container
						justifyContent="space-around"
						mt={2}
					>
						<Button
							className="formButton"
							variant="contained"
							color="success"
							disabled={isWzNumberEmptyError || isDateError || isAdsReasonEmptyError || isWarehouseKeeperReasonEmptyError}
							onClick={buttonClickHandler}
						>
							wyślij
						</Button>
						<Button
							className="formButton"
							variant="contained"
							color="error"
							onClick={closeModalHandler}
						>
							zamknij
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	)
})

const styles = {
	boxStyle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '16px',
		backgroundColor: 'white',
		borderRadius: '8px',
		boxShadow: '8px'
	}
}