import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext, SettlementsContext, ReckoningsContext } from "../../../../context";
import { Button, Grid, Typography } from "@mui/material";
import WarningRejected from "../WarningRejected";
import { ROLE_ADMIN } from "../../../../data/consts/users";
import { SettlementTitle } from "./settlmentTitle/SettlementTitle";
import { buttons } from './settlementData'
import { renderMenuButtons, renderMenuItem } from './settlementHelpers'
import avatar from '../../../../assets/img/ui/checkedIconSmall.png';

const Settlement = () => {
	const {
		getSettlementsInfo,
		clearSettlementsState,
		setGlobalSettlementPeriod,
		getSettlementsDates,
		settlementsState,
		acceptAll
	} = useContext(SettlementsContext);

	const { clearReckoningsState, reckoningsState, getReckoning } = useContext(ReckoningsContext);
	const navigate = useNavigate();
	const params = useParams();

	const adsID = params.adsID
	const reckoningStatus = reckoningsState.reckoning?.status
	const settlementPeriod = params.yearMonth;

	const { role, userId } = useContext(AuthContext);
	const [reckoningUserRole, setReckoningUserRole] = useState('');
	const [activeMenu, setActiveMenu] = useState(1);
	const [isAdding, setIsAdding] = useState(false);

	useEffect(() => {
		if (!reckoningUserRole && !!reckoningsState.reckoning?.userRole) {
			setReckoningUserRole(reckoningsState.reckoning?.userRole);
		}
	}, [reckoningUserRole, reckoningsState.reckoning]);

	useEffect(() => {
		if (role === ROLE_ADMIN) {
			if (!!reckoningsState.reckoning.reckoningId) {
				getSettlementsInfo(settlementPeriod, reckoningsState.reckoning.reckoningId);
			}
		} else {
			getSettlementsInfo(settlementPeriod);
		}
		setGlobalSettlementPeriod(settlementPeriod);
		getSettlementsDates()

		return () => {
			clearSettlementsState();
		};
		// eslint-disable-next-line
	}, [reckoningsState.reckoning]);

	useEffect(() => {
		if (role === ROLE_ADMIN && adsID) {
			getReckoning(settlementPeriod, adsID);
		} else {
			getReckoning(settlementPeriod, userId)
		}

		return () => {
			clearReckoningsState()
		}
		// eslint-disable-next-line
	}, [])

	const isActionButton = (reckoningStatus === 'draft' || reckoningStatus === 'rejected' || !reckoningStatus) && role !== ROLE_ADMIN

	return (
		<>
			<Grid container className='settlements'>
				<Grid
					container
					direction='row'
					flexWrap='nowrap'
					mb={1}
				>
					<SettlementTitle/>
					<Grid item xs={12} md={8}>
						<Grid container flexDirection='row' alignItems='center' height='100%'>
							{
								isActionButton && [1, 2, 3].includes(activeMenu) &&
								<Grid item xs={12} md={6} p={0.5}>
									<Button
										color="primary" variant="contained"
										className="buttonCustom"
										style={styles.additionalButtons}
										onClick={() => {
											// acceptAll( activities[activeMenu - 1], { date: settlementPeriod } )
											acceptAll(settlementPeriod)
										}}
									>
										<Typography mt={0.3} mr={0.7}>zaakceptuj wszystko</Typography>
										<img src={avatar} alt="checked"/>
									</Button>
								</Grid>
							}
							{
								isActionButton && buttons[activeMenu - 1] &&
								<Grid item xs={12} md={6} p={0.5}>
									<Button
										color="primary" variant="contained"
										className="buttonCustom"
										style={styles.additionalButtons}
										onClick={() => {
											setIsAdding(!isAdding);
										}}
									>
										<Typography>dodaj {buttons[activeMenu - 1]?.text}</Typography>
									</Button>
								</Grid>
							}
						</Grid>
					</Grid>
					{
						reckoningStatus?.includes('reject') &&
						<WarningRejected text={reckoningsState.reckoning?.adminRemarks}/>
					}
				</Grid>
				<Grid container mb={1} display='row' wrap='nowrap' justifyContent='space-between'>
					{renderMenuButtons(reckoningUserRole, role, activeMenu, setActiveMenu, setIsAdding)}
				</Grid>
				<Grid item xs={12}>
					{renderMenuItem(isAdding, settlementPeriod, setIsAdding, role, reckoningStatus, reckoningsState, settlementsState, activeMenu)}
				</Grid>
				<Grid container justifyContent='end' p={1}>
					<Button
						variant='greyLight'
						size='small'
						onClick={() => {
							role !== ROLE_ADMIN ?
								navigate("/settlements/")
								: navigate(`/reckonings/`)
						}}
					>
						Powrót
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

const styles = {
	additionalButtons: {
		width: "100%",
		height: "34px",
		padding: "5px 10px",
		color: "white",
	}
}

export default Settlement;
