import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Grid,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { MessagesContext } from '../../../../context';
import { initMessageData } from './helper';
import { ADD_OPTION, SHOW_OPTION } from '../../../../data/consts/modalOptions';
import {roleNormaliser} from "../../../../utils/roleNormalizer";


const MessageAdminModal = ({ closeModal, messageID, modalOption }) => {
	const { addMessage, messageState } = useContext(MessagesContext);
	const [messageData, setMessageData] = useState(initMessageData)

	const [variantUser, setVariantUser] = useState([])
	const [itemsChecked, setItemsChecked] = useState(false);

	useEffect(() => {
		if (modalOption === SHOW_OPTION && messageState.messagesList) {
			setMessageData(...messageState.messagesList.filter(el => el.id === messageID))
		}
	}, [modalOption, messageID, messageState])

	const formValueHandler = (name, value) => setMessageData(prev => ({ ...prev, [name]: value }))

	const submitHandler = (e) => {
		e.preventDefault()
		modalOption === ADD_OPTION && addMessage({ ...messageData, user: variantUser.map(el => el.id) }) && closeModal()
	}

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		const containsAll = value.includes('all');
		setVariantUser(containsAll ? (itemsChecked ? [] : [...messageState.formSet]) : value);
	};

	return <div className="customModal ">
		<Stack component="div" className="customModal__addMenu ">
			<Typography variant="h4" mb={2}>
				{
					modalOption === ADD_OPTION
						? 'Wyślij wiadomość'
						: modalOption === SHOW_OPTION
							? 'Podgląd wiadomości'
							: ''
				}
			</Typography>
			<form onSubmit={(e) => submitHandler(e)}>
				<Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
				      className="customModal__scrollArea">
					{modalOption === ADD_OPTION ? <Grid item>
						<FormControl fullWidth>
							<Typography variant="subtitle1" mt={2} mb={2}>Odbiorca:</Typography>
							<Select
								sx={{ maxWidth: '550px' }}
								id="demo-multiple-checkbox"
								labelId="demo-multiple-checkbox-label"
								value={variantUser}
								onChange={handleChange}
								multiple
								//disabled={(modalOption === 'show')}
								renderValue={(selected) => selected.map((x) => x.name).join(', ')}
								input={<OutlinedInput label="Placówka"/>}
							>
								<MenuItem
									key={'all'}
									value={'all'}
									onClick={() => setItemsChecked(!itemsChecked)}
								>
									<Checkbox
										checked={itemsChecked}
									/>
									<ListItemText primary={'Wybierz wszystkich'}/>
								</MenuItem>
								{messageState.formSet && messageState.formSet.map(user => (<MenuItem
									key={user.id}
									value={user}
								>
									<Checkbox
										checked={variantUser.map(el => el.id).includes(user.id)}
									/>
									<ListItemText primary={`${user.name} ${user.surname} (${roleNormaliser(user.role)})`}/>
								</MenuItem>))}
							</Select>
						</FormControl>
					</Grid> : null}

					<Grid item width={'100%'}>
						<FormControl fullWidth required>
							<Typography variant="subtitle1" mt={2} mb={2}>Tytuł wiadomości:</Typography>
							<TextField
								fullWidth
								disabled={modalOption === SHOW_OPTION}
								name="title"
								minRows="1"
								value={messageData.title || ''}
								onChange={(e) => formValueHandler(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid item width={'100%'}>
						<FormControl fullWidth required>
							<Typography variant="subtitle1" mt={2} mb={2}>Treść wiadomości:</Typography>
							<TextField
								fullWidth
								name="content"
								disabled={modalOption === SHOW_OPTION}
								multiline
								minRows="4"
								value={messageData.content || ''}
								onChange={(e) => formValueHandler(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<div className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION ? 'alignButton' : ''} `}>
					<Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
					{modalOption !== SHOW_OPTION && <Button
						type="submit"
						color="primary" variant="contained">
						{modalOption === ADD_OPTION ? 'Wyślij' : 'Edytuj'}
					</Button>}
					{/*<Button variant="contained"*/}
					{/*        color="primary" onClick={() => readMessage(userID)*/}
					{/*}>Oznacz jako przeczytane</Button>*/}
				</div>
			</form>
		</Stack>
	</div>

}

export default MessageAdminModal;
