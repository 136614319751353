import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { PoiState } from './context/poi/PoiState';
import { AuthState } from './context/auth/AuthState';
import { AppState } from './context/app/AppState';
import { ThemeProvider } from '@mui/material';
import { PatientState } from './context/patient/PatientState';
import { HardwareState } from './context/hardware/HardwareState';
import { OrderState } from './context/orders/OrderState'
import { SettlementsState } from './context/settlements/SettlementsState';
import { ReckoningsState } from './context/reckonings/ReckoningsState';
import { MessagesState } from './context/messages/messagesState';
import { RootStateProvider } from './store/RootStateContext';
import theme from './themeStyle';
import './scss/main.scss';
import {ReportsState} from './context/reports/reportsState';


const app = (
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<PoiState>
				<AuthState>
					<AppState>
						<SettlementsState>
							<PatientState>
								<HardwareState>
									<OrderState>
										<ReckoningsState>
											<MessagesState>
												<ReportsState>
													<RootStateProvider>
														<App/>
													</RootStateProvider>
												</ReportsState>
											</MessagesState>
										</ReckoningsState>
									</OrderState>
								</HardwareState>
							</PatientState>
						</SettlementsState>
					</AppState>
				</AuthState>
			</PoiState>
		</BrowserRouter>
	</ThemeProvider>
)

ReactDOM.render(app, document.getElementById('root'));
