import React, {useContext, useState} from 'react';
import {AppContext} from "../../../context";
import {
  Box,
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";

const PatientInfo = ({patientModule, patientModuleHandler, isPatientParam}) => {
    const {appState} = useContext(AppContext);

    const [eventDateFormat, setEventDateFormat] = useState(['year']);
    const [eventFormat, setEventFormat] = useState('YYYY');


    return (
        <Grid container border={`1px solid #071d49`}>
            <Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
                <Typography
                    variant="h6"
                    color="white"
                >INFORMACJE O PACJENCIE</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Stack spacing={3}>
                            <FormControl fullWidth required>
                                <InputLabel id="initials-label">Inicjały pacjenta:</InputLabel>
                                <Select
                                    labelId="initials-label"
                                    id="initials"
                                    name="initials"
                                    disabled={isPatientParam}
                                    value={patientModule.initials}
                                    label="Zdarzenie numer:"
                                    onChange={(e) => patientModuleHandler(e.target.name, e.target.value)}>
                                    {
                                        appState.adversePatients && appState.adversePatients.map((el, index) =>
                                            <MenuItem value={el.patient} key={el.id}>{el.patient.toUpperCase()}</MenuItem>)
                                    }
                                </Select>



                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="gender-label" sx={{width: '60px'}}>Płeć:</InputLabel>
                                <Select
                                    disabled={true}
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={patientModule.gender}
                                    label="Płeć:"
                                    onChange={(e) => patientModuleHandler(e.target.name, e.target.value)}
                                >
                                    <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                    <MenuItem value="K">K</MenuItem>
                                    <MenuItem value="M">M</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2} className="dynamicLeftBorder">
                        <Stack spacing={2}>
                            <Stack>
                                <Grid container flexDirection="row" alignItems="center">
                                    <Typography variant="subtitle1" mr={3}>Wiek:</Typography>
                                    <Typography>{patientModule.age}</Typography>
                                </Grid>
                                <Box minHeight={24}>
                                    {
                                        patientModule.dateOfBirth
                                        && patientModule.dateOfEvent
                                        && patientModule.age <= 0
                                        && <Typography color="error">Podane daty są nieprawidłowe</Typography>}
                                </Box>
                            </Stack>
                            <Stack spacing={3}>
                                <DatePickerComponent
                                    label="data urodzenia"
                                    disableFuture
                                    allowSameDateSelection
                                    views={['year', 'month', 'day']}
                                    value={patientModule.dateOfBirth}
                                    onChange={(newValue) => {
                                        patientModuleHandler('dateOfBirth', newValue);
                                    }}
                                />
                              <Box display={'flex'}>
                                <RadioGroup
                                  value={eventFormat}
                                  onChange={(e) => {
                                    setEventFormat(e.target.value);
                                    switch (e.target.value){
                                      case 'YYYY' : setEventDateFormat(['year']);  break;
                                      case 'MM.YYYY' : setEventDateFormat(['year', 'month']); break;
                                      default: setEventDateFormat(['year', 'month', 'day']); break;
                                    }
                                  }}>
                                  <FormControlLabel value='YYYY' control={<Radio/>} label='YYYY'/>
                                  <FormControlLabel value='MM.YYYY' control={<Radio/>} label='MM.YYYY' />
                                  <FormControlLabel value='DD.MM.YYYY' control={<Radio/>} label='DD.MM.YYYY'/>
                                </RadioGroup>
                                <DatePickerComponent
                                  required={false}
                                  disableFuture
                                  allowSameDateSelection
                                  label="data zdarzenia:"
                                  views={eventDateFormat}
                                  inputFormat={eventFormat}
                                  value={patientModule.dateOfEventConvert}
                                  onChange={(newValue) => {
                                    patientModuleHandler('dateOfEventConvert', newValue);
                                    patientModuleHandler('dateOfEvent', newValue);
                                  }}
                                  componentsProps={{
                                    actionBar: { actions: ['clear'] },
                                  }}
                                />
                              </Box>

                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PatientInfo;