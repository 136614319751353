import {makeAutoObservable} from 'mobx';
import {WarehouseStore} from './warehouseStore';
import {LoadersStore} from './loadersStore';
import {NotificationStore} from './notificationStore/notificationStore';
import {AlertStore} from './alertStore';
import {EmailNotificationStore} from './emailNotificationStore';

export class RootStore {
  alertStore = new AlertStore();
  loadersStore = new LoadersStore();
  notificationsStore = new NotificationStore();
  warehouseStore = new WarehouseStore();
  emailNotificationsStore = new EmailNotificationStore();

  constructor() {
    makeAutoObservable(this, {
      alertStore: false,
      loadersStore: false,
      notificationsStore: false,
      warehouseStore: false,
      emailNotificationsStore: false,
    });
  }
}