import React, {useContext, useReducer} from 'react';
import axios from "../../axios/axios";
import {PoiContext} from "../poi/poiContext";
import {GET_MESSAGES_LIST, GET_MESSAGES_RECEIVERS,} from "../types";
import {MessagesContext} from "./messagesContext";
import {messagesReducer} from "./messagesReducer";

export const MessagesState = ({children}) => {
    const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);

    const initialState = {
        formSet: '',
        messagesList: '',
    }

    const [state, dispatch] = useReducer(messagesReducer, initialState);

    const addMessage = async (msgInfo) => {
        const data = new FormData()
        const users = msgInfo.user
        users.forEach((el, i) => (
            data.append('user[]', users[i])
        ))
        data.append('title', msgInfo.title)
        data.append('content', msgInfo.content)
        try {
            await axios.post(
                'message',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 200,
                        message: 'Wysłano wiadomość',
                    },
                }
                setModalError(defRes);
                getMessagesList()
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    //
    const getMessagesList = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `messages`,
            ).then(res => {
                dispatch({type: GET_MESSAGES_LIST, payload: res.data})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }
    //
    const getUsers = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `messages/form-set`,
            ).then(res => {
                dispatch({type: GET_MESSAGES_RECEIVERS, payload: res.data})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const readMessage = async (messageID) => {
        setGlobalLoader()

        try {
            await axios.post(
                `message/${messageID}/read`,
                null
            ).then(res => {
                    unsetGlobalLoader()
                    let defRes = {
                        data: {
                            code: 200,
                            message: 'Wiadomość została oznaczona jako przeczytana'
                        },
                    }
                    setModalError(defRes)
                    getMessagesList()
                }
            )
        } catch
            (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }

    const delMessage = async (messageID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `message/${messageID}`,
            ).then(res => {
                unsetGlobalLoader();
                getMessagesList()
                let defRes = {
                    data: {
                        code: 200,
                        message: "Wiadomość została usunięta"
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }

    // const clearHardwareState = () => dispatch({type: CLEAR_HARDWARE_STATE})

    return (
        <MessagesContext.Provider value={{
            messageState: state,
            getMessagesList, addMessage, getUsers, delMessage, readMessage,
        }}>
            {children}
        </MessagesContext.Provider>
    )

}
