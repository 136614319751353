import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Grid, Typography } from '@mui/material';
import { PoiContext } from '../../../../context';
import { useNotificationStore } from '../../../../store/hooks';
import { NOTIFICATION_TYPES } from '../../../../store/notificationStore/notificationType';

export const ErrorModalController = observer(() => {
	const { isGlobalError, errorMessage, errorCode, unsetModalError } = useContext(PoiContext);
	const notificationStore = useNotificationStore();
	const { notification } = notificationStore;

	const onCLickHandler = useCallback(() => {
		if (isGlobalError) {
			unsetModalError()
		} else {
			notificationStore.clearNotification();
		}
	}, [isGlobalError, unsetModalError, notificationStore])


	let textColor;

	if (notification) {
		switch (notification.notificationType) {
			case NOTIFICATION_TYPES.SUCCESS:
				textColor = 'green';
				break
			case NOTIFICATION_TYPES.ERROR:
				textColor = 'red';
				break
			case NOTIFICATION_TYPES.WARNING:
				textColor = 'yellow';
				break
			default:
				textColor = 'black';
		}
	}


	return isGlobalError || notification
		? (
			<Grid
				className="customModal errorModal">
				<Grid
					container
					flexDirection="column"
					className="customModal__content"
				>
					{
						isGlobalError && (
							<>
								<Typography
									variant="h3">{errorCode === 0 || (errorCode >= 200 && errorCode < 300) ? 'Wiadomość' : 'Błąd'}</Typography>
								<Grid className="customModal__content-errorMessageArea">
									<p>
										{errorMessage}
									</p>
								</Grid>
							</>
						)
					}
					{
						notification && (
							<Grid className="customModal__content-errorMessageArea">
								<p style={{ color: textColor, marginRight: 0, textAlign: 'center' }}>
									{notification.message ? notification.message : 'Skontaktuj się z administratorem'}
								</p>
							</Grid>
						)
					}
					<Button onClick={onCLickHandler} color="primary" variant="contained">Powrót</Button>
				</Grid>
			</Grid>
		)
		: null
})