import React, { useContext, useEffect, useState } from 'react';
import { HardwareContext } from '../../../context/hardware/hardwareContext';
import { PoiContext } from '../../../context/poi/poiContext';
import { Button, FormControl, Grid, MenuItem, Select, Stack, TextField, Typography, } from '@mui/material';
import OrdersProductList from './OrdersProductList';
import Carousel from './Carousel';
import OrderProductInfo from './OrderProductInfo';
import HardwareOrdersModalTable from './HardwareOrdersModalTable';
import { OrderContext } from '../../../context/orders/orderContext';
import OrdersViewMode from './OrdersViewMode';
import { AuthContext } from '../../../context/auth/authContext';
import { ADD_OPTION, EDIT_OPTION, SHOW_OPTION } from '../../../data/consts/modalOptions';
import { ROLE_ADMIN } from '../../../data/consts/users';


const HardwareOrdersModal = ({ closeModal, modalOption, currentOrderInfo }) => {

	const { setModalError } = useContext(PoiContext);
	const { hardwareState: { formSet } } = useContext(HardwareContext);
	const { authState: { id: userID, role: userRole } } = useContext(AuthContext);
	const { orderState, addHardwareOrder, editHardwareOrder } = useContext(OrderContext);
	const [orderDetails, setOrderDetails] = useState([])
	const [institution, setInstitution] = useState(0)
	const [maker, setMaker] = useState(0)

	const [productId, setProductId] = useState(null)
	const [comment, setComment] = useState('')

	useEffect(() => {
		const institution = modalOption === ADD_OPTION ? userRole === ROLE_ADMIN ? 0 : formSet[0]?.id : currentOrderInfo?.institution?.id;
		setInstitution(institution ?? 0)
		setMaker(modalOption === ADD_OPTION ? userRole === ROLE_ADMIN ? 0 : userID : currentOrderInfo.notifier)
		setComment(modalOption === ADD_OPTION ? '' : currentOrderInfo.comment)
		setOrderDetails(modalOption === ADD_OPTION ? [] : currentOrderInfo.products.map(el => ({
			product: el.id,
			amount: el.amount,
			detail: el.detail
		})))
	}, [formSet, orderState, currentOrderInfo, userID, userRole, modalOption])

	const idSetter = (id) => setProductId(id)

	const orderDetailsAddHandler = (product, amount, detail) => {
		let isSelected = false
		let index;
		!!orderDetails.length && orderDetails.forEach((el, i) => {
			if (el.product === product && el.detail === detail) {
				isSelected = true
				index = i;
			}
		})
		if (isSelected) {
			let tempOrderDetails = [...orderDetails]
			tempOrderDetails[index] = {
				product, amount, detail,
			}
			setOrderDetails([...tempOrderDetails])
		} else {
			setOrderDetails(prev => [...prev, { product, amount, detail }])

		}
	}

	const delOrderDetailsEl = (index) => {
		let tempOrders = JSON.parse(JSON.stringify(orderDetails.filter((el, i) => i !== index)))
		setOrderDetails([...tempOrders])
	}

	const onSubmitHandler = (e) => {
		e.preventDefault()
		if (orderDetails.length === 0 || institution === 0 || maker === 0) {
			let defRes = {
				data: {
					code: 400,
					message: 'Uzupełnij brakujące pola.',
				},
			}
			setModalError(defRes);
		} else {
			if (modalOption === ADD_OPTION) {
				const data = new FormData()
				data.append('comment', comment)
				orderDetails.forEach((el, index) => {
					data.append(`details[${index}][product]`, el.product)
					data.append(`details[${index}][amount]`, el.amount)
					data.append(`details[${index}][detail]`, el.detail)
				})
				data.append('notifier', maker)
				data.append('institution', institution)
				addHardwareOrder(data)
				closeModal()
			}
			if (modalOption === EDIT_OPTION) {
				const data = {
					comment,
					details: orderDetails,
				}
				editHardwareOrder(currentOrderInfo.id, data)
				closeModal()
			}
		}
	}

	return (
		<div className="customModal">
			<Grid component="form" onSubmit={onSubmitHandler}>
				<Stack component="div" className="customModal__addMenu"
				       width={'100%'} minWidth={'60vw'}
				>
					<Typography variant="h4" mb={2}>
						{
							modalOption === ADD_OPTION ? 'Dodaj zamówienie '
								: modalOption === SHOW_OPTION ? 'Dane zamówienia' : 'Edytuj zamówienie'
						}
					</Typography>
					{modalOption !== SHOW_OPTION ?
						<Grid item xs={12} container>
							{/*LEFT SIDE*/}

							<Grid
								item
								xs={12}
								md={4}
								className="hardwareOrderModal__content"
							>
								<OrdersProductList
									idSetter={idSetter}
								/>
							</Grid>
							{/*RIGHT SIDE*/}
							<Grid item xs={12} md={8} className="hardwareOrderModal__content">
								<Grid item xs={12} sx={{ height: '250px' }}>
									<Carousel productId={productId}/>
								</Grid>
								<Grid item xs={12} pt={6}>
									<OrderProductInfo productId={productId} orderDetails={orderDetails}
									                  orderDetailsAddHandler={orderDetailsAddHandler}/>
								</Grid>
								<Grid item xs={12} p={2}>
									<FormControl fullWidth>
										<Typography variant="subtitle1">Placówka:</Typography>
										<Select
											name="institution"
											value={institution}
											onChange={(e) => {
												setInstitution(e.target.value)
												setMaker(0)
											}}
											disabled={modalOption === EDIT_OPTION}
										>
											<MenuItem value={0} disabled={true}><em>Wybierz z listy</em></MenuItem>
											{
												formSet && formSet.map(el =>
													<MenuItem key={el.id}
													          value={el.id}>{el.name}</MenuItem>)
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} p={2}>
									<FormControl fullWidth>
										<Typography variant="subtitle1">Zgłaszający:</Typography>
										<Select
											name="maker"
											value={maker}
											onChange={(e) => setMaker(e.target.value)}
											disabled={modalOption === EDIT_OPTION}
										>
											<MenuItem value={0} disabled={true}><em>Wybierz z listy</em></MenuItem>
											{
												institution !== 0 && formSet.find(el => el.id === institution).users.map(user =>
													<MenuItem key={user.id}
													          value={user.id}>{user.name} {user.surname}</MenuItem>)
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} p={2}>
									<FormControl fullWidth required>
										<Typography variant="subtitle1">Komentarz:</Typography>
										<TextField
											fullWidth
											name="comment"
											multiline
											minRows="4"
											value={comment}
											onChange={(e) => setComment(e.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} p={2}>
									<HardwareOrdersModalTable
										orderDetails={orderDetails}
										delOrderDetailsEl={delOrderDetailsEl}
										modalOption={modalOption}
									/>
								</Grid>
							</Grid>
						</Grid>
						:
						<OrdersViewMode currentOrderInfo={currentOrderInfo}/>

					}
					{/*BUTTON AREA*/}
					<div
						className={`customModal__addMenu-buttonArea ${modalOption === 'show' ? 'alignButton' : ''} `}
					>
						<Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
						{modalOption !== SHOW_OPTION && <Button type="submit" color="primary" variant="contained">
							{modalOption === ADD_OPTION ? 'Dodaj' : 'Zapisz'}
						</Button>}
					</div>
				</Stack>
			</Grid>
		</div>

	)
}

export default HardwareOrdersModal;