import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, Grid, Stack} from "@mui/material";
import {AuthContext, AppContext} from "../../../context";
import axios from "../../../axios/axios";
import PageTitle from "../../shared/ui/PageTitle";
import {InfoElement} from "../../shared/ui/infoElements/InfoElement";
import {AcceptModal} from "../../shared/modals/AcceptModal";
import {ADD_OPTION, SHOW_OPTION} from "../../../data/consts/modalOptions";


const CertificationModal = ({closeModal, modalOption, currentCertificateInfo}) => {
    const {authState} = useContext(AuthContext)
    const {proceedCertificate} = useContext(AppContext)
    const [pdfResponse, setPdfResponse] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isAcceptedModalOpen, setIsAcceptedModalOpen] = useState(false);

    useEffect(() => {
        setIsDisabled(!!currentCertificateInfo.completed.filter(user => user.id === authState.id).length);
    }, [authState.id, currentCertificateInfo])


    useEffect(() => {
        if(currentCertificateInfo.id){
            axios({
                method: 'GET',
                url: `/certificate/${currentCertificateInfo.id}/pdf`,
                responseType: 'blob',
            })
                .then(response => {
                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    setPdfResponse(fileURL)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [currentCertificateInfo.id]);

    const acceptHandler = () => {
        proceedCertificate(currentCertificateInfo.id)
        setIsDisabled(true);
    }
    const acceptedModalToggler = () => setIsAcceptedModalOpen(prev => !prev);

    return (
        <Grid container className="customModal" style={{overflow: 'auto'}}>
            <Stack component="div" className="customModal__addMenu" style={{minWidth: '80vw'}}>
                <PageTitle title="Certyfikacja:"/>
                <InfoElement title='Tytuł:' value={currentCertificateInfo.title} titleMinWidth='0'/>
                <InfoElement
                    title='Termin ukończenia certifikatu:'
                    value={currentCertificateInfo.datePass}
                    titleMinWidth='0'
                />
                <InfoElement title='Ważność certifikatu:' value={currentCertificateInfo.dateValid} titleMinWidth='0'/>
                {pdfResponse &&
                    <Box maxHeight={"500px"} width={"100%"} id="test">
                        <iframe title={"certificate"} src={pdfResponse} width={"100%"}
                                height={"490px"}
                        />
                    </Box>
                }
                <Box
                    className={`customModal__addMenu-buttonArea ${currentCertificateInfo.completed.id === authState.id ? 'alignButton' : ''} `}>
                    <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
                    {
                        modalOption !== SHOW_OPTION &&
                        <Button
                            type="submit"
                            color="primary" variant="contained">
                            {modalOption === ADD_OPTION ? 'Dodaj' : 'Zapisz'}
                        </Button>

                    }
                    {pdfResponse &&
                        <Button
                            color="primary" variant="contained"
                            disabled={!!isDisabled}
                            onClick={acceptedModalToggler}
                        >{!!isDisabled ? "Ukończony" : "Akceptuję"}</Button>
                    }
                </Box>
            </Stack>
            {
                isAcceptedModalOpen && (
                    <AcceptModal
                        title='Potwierdzam, że zapoznałam się z treścią szkolenia'
                        closeModalHandler={acceptedModalToggler}
                        acceptHandler={acceptHandler}
                    />
                )
            }
        </Grid>
    )
}
export default CertificationModal