
export const CLEAR = 'CLEAR';
export const CHANGE  = 'CHANGE';
export const DISABLED = 'DISABLED';

export const changePumpsStatus = (availablePumps, pumps, changePumpsStatus = null, position= null) => {

 if(availablePumps.length === 0){
  return [DISABLED, DISABLED];
 }
 else if(changePumpsStatus === null && position === null) {
  return [CHANGE, CHANGE];
 }
 else{
  const availablePumpsFilteredLength = availablePumps.filter(pump => -1 === pumps.findIndex(pumpElement => pumpElement.id === pump.id)).length

  return [position === 0 ?
   (changePumpsStatus[0] === CLEAR ? CHANGE : CLEAR)
   : (availablePumpsFilteredLength <= 1 && changePumpsStatus[1] === CHANGE ? DISABLED :
    (changePumpsStatus[0] === DISABLED ? CHANGE : changePumpsStatus[0])),
   position === 1 ?
    (changePumpsStatus[1] === CLEAR ? CHANGE : CLEAR)
   : (availablePumpsFilteredLength <= 1 && changePumpsStatus[0] === CHANGE ? DISABLED :
     (changePumpsStatus[1] === DISABLED ? CHANGE : changePumpsStatus[1]))
   ]
 }
}

export const statusToName = (status) => {
 switch (status){
  case CLEAR : return 'Cofnij';
  case CHANGE: return 'Zmień';
  default: return 'Zmień';
 }
}