import React from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";

export const DatePickerComponent = ({
                                        value,
                                        disabled,
                                        shouldDisableDate,
                                        label,
                                        views = ["year", "month", 'day'],
                                        openTo = "year",
                                        inputFormat = 'dd/MM/yyyy',
                                        required,
                                        onChange,
                                        children,
                                        ...props
                                    }) => {

  const onKeyUpHandler = (e) => {
    e.preventDefault();
    if(e.keyCode === 8){
      onChange(null)
    }
  };

    return (
        <FormControl fullWidth required={required}>
            {children}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                <DatePicker
                    shouldDisableDate={shouldDisableDate}
                    disabled={disabled}
                    label={label}
                    inputFormat={inputFormat}
                    openTo={openTo}
                    views={views}
                    value={value}
                    onChange={onChange}
                    slotProps={{
                      textField: {
                        onKeyUp: onKeyUpHandler,
                      },
                    }}
                    {...props}
                    // renderInput={(params) => <TextField  {...params} />}
                />
            </LocalizationProvider>
        </FormControl>
    )
}