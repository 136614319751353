import { dateFormat } from '../../../utils/helpfunc';

export const dataNormilize = (
	mainModule,
	authState,
	patientModule,
	doctorModule,
	circumstancesModule,
	pompSubmodule,
	pipeSubmodule,
	gelSubmodule,
	adverseEventsTableValue,
	isDrugTable,
	drugTableValue,
	isMedicalInterviewsTable,
	medicalInterviewsTableValue,
	drugAllergies
) => {
	return ({
		eventType: mainModule.type, 		// "nowe", "uzupełniające"
		eventUpdateId: mainModule.number, 	// wymagane jeśli eventType = "uzupełnienie"
		applicantName: `${authState.name} ${authState.surname}`, 		// imię i nazwisko składającego ads/koordynatora
		applicantPhone: typeof authState.telephone == 'boolean' ? '' : authState.telephone, // nr telefonu skłajadajcego ads/koordynatora
		infoSource: {
			fromPatient: mainModule.fromPatient,
			fromFamily: mainModule.fromFamily,
			fromHealthCare: mainModule.fromHealthCare,
			fromAnother: mainModule.additionalContact,
		}, // jeśli zaznaczona "Inna osoba" to po prostu wartość z pola tekstowego zamiast true
		eventDate: dateFormat(patientModule.dateOfEvent, true), // data zdarzenia, na jej podstawie wyliczany wiek
		patientInitials: patientModule.initials, // inicjały i 4 cyfry pesel, z selecta pacjentow przypisanych do składającego ads/koordynatora
		patientIdNumber: patientModule.id,
		patientGender: patientModule.gender, // "M", "K"
		patientBirthDate: dateFormat(patientModule.dateOfBirth, true), // data, na jej podstawie wyliczany wiek
		patientAge: patientModule.age, // liczba wyliczana na podstawie eventDate oraz patientBirthDate
		patientId: patientModule.patientId,
		isContactToDoctorAllow: doctorModule.contactApprove === '' ? null : doctorModule.contactApprove,
		doctorSpecializations: {
			gastrolog: doctorModule.gastrologist,
			neurolog: doctorModule.neurologist,
			radiologist: doctorModule.radiologist,
			other: doctorModule.additionalSpecialization,
		}, // jesli zaznaczona "inna specjalizacja" to wartość z pola tekstowego zamiast true
		doctorName: doctorModule.name,
		doctorPhone: doctorModule.telephone,
		doctorStreet: doctorModule.address,
		doctorCity: doctorModule.city,
		doctorVoivodeship: doctorModule.voivodeship, // 'dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'
		doctorZipCode: doctorModule.zipCode, // kod pocztowy w formacie 11-111
		serialNumberDrug: circumstancesModule.serialNumberDrug,
		eventDescription: circumstancesModule.description,
		patientCondition: circumstancesModule.condition,
		eventProducts: {
			pompa: circumstancesModule.pump,
			nj: circumstancesModule.nj,
			peg: circumstancesModule.peg,
			jejunum: circumstancesModule.jejunum,
			gel: circumstancesModule.gel,
			anotherProduct: circumstancesModule.additionalProduct,
		}, // jeśli zaznaczony "inny (nalezy podac)" to wartosc z pola tekstowego zamiast true
		isPatientDataCorrect: circumstancesModule.anotherProductReload, // boolean, "Potwierdzam, że dane o pacjencie (pompa/peg) są aktualne"
		isProductSample: circumstancesModule.complaintSample, // boolean
		missingSampleReasons: {
			discarded: circumstancesModule.discarded,
			stillInUse: circumstancesModule.stillInUse,
			submitSample: circumstancesModule.submitSample,
			anotherReason: circumstancesModule.additionalReason,
		}, // jesli zaznaczony "inny powód (należy podać jaki)" to wartosc z pola tekstowego zamiast true
		treatmentStartDate: dateFormat(circumstancesModule.startTreatment), // data
		firstProbesDate: dateFormat(circumstancesModule.startPeg), // data
		unknownSerialNumberReasons: {
			noPackage: circumstancesModule.noPackage,
			noAnswer: circumstancesModule.noAnswer,
			noContact: circumstancesModule.noContact,
			noContactInfo: circumstancesModule.noContactInfo,
			noRegistration: circumstancesModule.noRegistration,
			anotherReasonNS: circumstancesModule.additionalReasonNS,
		}, // jesli zaznaczony "inny powód (należy podać jaki)" to wartosc z pola tekstowego zamiast true

		pumpSerialNumber: circumstancesModule.pumpSNo,
		pumps: circumstancesModule.pumps ?? [],
		isPumpAlarm: pompSubmodule.isAlarm, // boolean
		pumpAlarmTypes: {
			continuousAlarm: pompSubmodule.continuousAlarm,
			noConnect: pompSubmodule.noConnect,
			highPressureAlarm: pompSubmodule.highPressureAlarm,
			fourDigitError: pompSubmodule.fourDigitError,
			unspecifiedAlarm: pompSubmodule.unspecifiedAlarm,
		}, // wymagane jeśli "isPumpAlarm" jest "Tak"
		pumpPhysicalDamage: {
			waterDamage: pompSubmodule.waterDamage,
			physicalDamage: pompSubmodule.physicalDamage,
			dropping: pompSubmodule.dropping,
		},
		pumpFunctionalDamage: {
			batteryProblem: pompSubmodule.batteryProblem,
			batteryCover: pompSubmodule.batteryCover,
			keyboard: pompSubmodule.keyboard,
			drugDelivery: pompSubmodule.drugDelivery,
			cassetteHolder: pompSubmodule.cassetteHolder,
			display: pompSubmodule.display,
			onOrOff: pompSubmodule.onOrOff,
			programmingProblem: pompSubmodule.programmingProblem,
			feedRate: pompSubmodule.feedRate,
			usageError: pompSubmodule.usageErrorValue,
		}, // jesli zaznaczony "bład użytkownika / inny (należy podać jaki)" to wartosc z pola tekstowego zamiast true
		isPumpProblemSolved: pompSubmodule.problemResolved, // boolean
		pumpProblemSolvedMethods: {
			redPlugsRemoved: pompSubmodule.redPlugsRemoved,
			batteryReplaced: pompSubmodule.batteryReplaced,
			reLaunchPomp: pompSubmodule.reLaunchPomp,
			reLaunchCassette: pompSubmodule.reLaunchCassette,
			reLaunchCover: pompSubmodule.reLaunchCover,
			noDisposable: pompSubmodule.noDisposable,
		}, // wymagane jeśli "isPumpProblemSolved" jest "Tak"
		isPumpReplacement: pompSubmodule.replacementPomp, // boolean
		pumpReplacementHelped: pompSubmodule.replacementPompComment, // wymagane jesli "isPumpReplacement" jest "Tak"

		njProbeDate: dateFormat(circumstancesModule.startNJ), // data
		lastPegProbeDate: dateFormat(circumstancesModule.lastInstallPeg), // data
		lastPegProbeSerialNumber: circumstancesModule.pegSNo,
		lastIntestinalProbeDate: dateFormat(circumstancesModule.lastInstallJejunum), // data
		lastIntestinalProbeSerialNumber: circumstancesModule.jejunumSNo,

		isTubesProblem: pipeSubmodule.isPipeProblem, // boolean
		tubesTypes: {
			intestinalProbe: pipeSubmodule.intestinalProbe,
			ostomyProbe: pipeSubmodule.ostomyProbe,
			nasoProbe: pipeSubmodule.nasoProbe,
			extensionPipe: pipeSubmodule.extensionPipe,
		}, // wymagane jeśli "isTubesProblem" jest "Tak"
		probeProducers: {
			AbbVie: pipeSubmodule.isAbbVie,
			Freka: pipeSubmodule.isFreka,
			isBostonScientific: pipeSubmodule.isBostonScientific,
			Cook: pipeSubmodule.isCook,
			Covidien: pipeSubmodule.isCovidien,
			nieznany: pipeSubmodule.isUnknown,
			isAnotherName: pipeSubmodule.anotherName,
		}, // wymagane jeśli "isTubesProblem" jest "Tak", jesli zaznaczony "inny (należy podać nazwę)" to wartosc z pola tekstowego zamiast true
		tubesProblemTypes: {
			stomaRelated: pipeSubmodule.stomaRelated,
			blockage: pipeSubmodule.blockage,
			locationChange: pipeSubmodule.locationChange,
			pegDisconnected: pipeSubmodule.pegDisconnected,
			snapDisconnected: pipeSubmodule.snapDisconnected,
			snapDetachment: pipeSubmodule.snapDetachment,
			unspecifiedDisconnection: pipeSubmodule.unspecifiedDisconnection,
			fold: pipeSubmodule.fold,
			entanglement: pipeSubmodule.entanglement,
			isCut: pipeSubmodule.isCut,
			leakyConnector: pipeSubmodule.leakyConnector,
			isUsageError: pipeSubmodule.isUsageErrorValue,
			isAnother: pipeSubmodule.isAnotherValue,
		}, // wymagane jeśli "isTubesProblem" jest "Tak", jesli zaznaczony "Inne" lub "bład użytkownika (należy opisać)" to wartosc z pól tekstowych zamiast true
		isTubesProblemSolved: pipeSubmodule.problemResolved, //boolean
		tubesProblemSolvedMethods: {
			rinse: pipeSubmodule.rinse,
			reconnectConnector: pipeSubmodule.reconnectConnector,
			connectorReplacement: pipeSubmodule.connectorReplacement,
			pegReplaced: pipeSubmodule.pegReplaced,
			jejunumPipeReplace: pipeSubmodule.jejunumPipeReplaceDescription,
			endoscopicSurgery: pipeSubmodule.endoscopicSurgery,
			fluoroscopy: pipeSubmodule.fluoroscopy,
			isAnotherEvent: pipeSubmodule.isAnotherEventValue,
		}, // wymagane jeśli "isTubesProblemSolved" jest "Tak", jesli zaznaczony "Inne (należy opisać)" to wartosc z pola tekstowego zamiast true

		gelCasketSerialNumber: circumstancesModule.gelSNo,
		isCasketUsed: gelSubmodule.wasCassetteInUse, // boolean
		casketAmount: gelSubmodule.cassetteCount, // number
		casketLeakingParts: {
			typeLuer: gelSubmodule.typeLuer,
			innerCase: gelSubmodule.innerCase,
			outerCase: gelSubmodule.outerCase,
			caseConnect: gelSubmodule.caseConnect,
			anotherCassetteDamage: gelSubmodule.anotherCassetteDamageDescription,
		}, // jesli zaznaczona "Inna (należy podać jaka)" to wartosc z pola tekstowego zamiast true
		casketRedPlugProblemTypes: {
			difficultTakeOff: gelSubmodule.difficultTakeOff,
			loose: gelSubmodule.loose,
			broken: gelSubmodule.broken,
		},
		casketTubeProblemTypes: {
			holeInPipe: gelSubmodule.holeInPipe,
			brokenLuer: gelSubmodule.brokenLuer,
			redLabel: gelSubmodule.redLabel,
			anotherProblem: gelSubmodule.anotherProblemValue,
		}, // jezli zazaczony "Inny (należy podać, jaki):" to wartosc z pola tekstowego zamiast true
		isMedicineProblem: gelSubmodule.appearanceDrugProblem, //boolean
		isCasketWrappingDamaged: gelSubmodule.isCassetteCoverDamaged, // boolean
		casketWrappingDesc: gelSubmodule.cassetteCoverDamageDescription, // wymagane jeśli "isCasketWrappingDamage" jest "Tak"
		medicineProblemDesc: gelSubmodule.problemDescription, // wymagane jeśli "isMedicineProblem" jest "Tak"
		medicineProblemWhen: gelSubmodule.problemTime, // wymagane jesli "isMedicineProblem" jest "Tak"
		casketCheckDesc: gelSubmodule.isCassetteChecked, // wymagane jesli "isMedicineProblem" jest "Tak"
		medicineStorageMethod: gelSubmodule.productStorage, // wymagane jesli "isMedicineProblem" jest "Tak"

		isConnectorChanged: circumstancesModule.anotherProductReload, //boolean
		connectorSerialNumber: circumstancesModule.anotherProductSNo,
		accessoriesSerialNumber: circumstancesModule.anotherProductAccessoriesNo,

		eventOccurrences: [...adverseEventsTableValue.map(el => ({
				details: el.details,
				dateStart: dateFormat(el.dateStart),
				dateEnd: dateFormat(el.dateEnd),
				result: el.result,
				causation: el.causation,
			}),
		)],

		areParallelMedicines: isDrugTable,

		parallelMedicines: [...drugTableValue.map(el => ({
			name: el.name,
			dateStart: dateFormat(el.dateStart),
			dateEnd: dateFormat(el.dateEnd),
			dosage: el.dosage,
			method: el.method,
			indication: el.indication,
		})),
		],

		areMedicalInterviews: isMedicalInterviewsTable,
		medicalInterviews: [...medicalInterviewsTableValue.map(el => ({
			period: el.period,
			dateStart: dateFormat(el.dateStart),
			dateEnd: dateFormat(el.dateEnd),
			additionalInfo: el.additionalInfo,
		})),
		],

		medicinesAllergiesDesc: drugAllergies,
	})
}