import React, { useCallback, useContext, useReducer } from 'react';
import axios from '../../axios/axios';
import { PoiContext } from '../poi/poiContext';
import { GET_WAREHOUSE_STATISTIC } from '../types';
import { ReportsContext } from './reportsContext';
import { reportsReducer } from './reportsReducer';

export const ReportsState = ({ children }) => {
	const { setModalError, setGlobalLoader, unsetGlobalLoader } = useContext(PoiContext);
	const initialState = {
		warehouseStatistic: null
	}

	const [state, dispatch] = useReducer(reportsReducer, initialState);

	//STATISTIC
	const getStatistic = useCallback(() => {
		setGlobalLoader();
		axios
			.get(`warehouse-item/statistic`)
			.then((res) => {
				dispatch({ type: GET_WAREHOUSE_STATISTIC, payload: res.data });
				unsetGlobalLoader();
			})
			.catch((error) => {
				unsetGlobalLoader();
				setModalError(error.response);
			});
	}, [setGlobalLoader, unsetGlobalLoader, setModalError]);

	//WAREHOUSE_REPORT_PDF
	const getWarehouseReportByAdmin = useCallback(async (fileType) => {
		setGlobalLoader()
		try {
			return await axios.get(
				`warehouse-item-logs/${fileType}`,
				{
					responseType: 'blob',
				},
			).then(response => {
				const blob = new Blob(
					[response.data],
					{ type: `application/${fileType === 'pdf' ? 'pdf' : 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'}` });
				const fileURL = URL.createObjectURL(blob);
				unsetGlobalLoader();
				return fileURL;
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response)
		}
	}, [setGlobalLoader, unsetGlobalLoader, setModalError])

	return (
		<ReportsContext.Provider value={{
			reportsState: state,
			getStatistic, getWarehouseReportByAdmin
		}}>
			{children}
		</ReportsContext.Provider>
	)

}
