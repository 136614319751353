import React, { useContext, useEffect } from 'react';

import { Button, Grid } from '@mui/material';
import PageTitle from '../../../shared/ui/PageTitle';
import { ReportsContext } from '../../../../context';

export const Reports = () => {
	const { reportsState, getStatistic, getWarehouseReportByAdmin } = useContext(ReportsContext);

	const { warehouseStatistic } = reportsState;

	useEffect(() => {
		void getStatistic();
	}, [getStatistic])

	const reportFileHandler = async (fileType) => {
		const fileURL = await getWarehouseReportByAdmin(fileType);
		window.open(fileURL);
	}

	return (
		<div>
			<PageTitle title="Raporty:"/>
			<Grid container justifyContent="flex-end" mb={4}>
				<Button
					color="primary" variant="contained"
					className="formButton"
					onClick={() => reportFileHandler('excel')}
				>
					pobierz raport w .xlsx
				</Button>
				<Button
					color="primary" variant="contained"
					className="formButton"
					onClick={() => reportFileHandler('pdf')}
				>
					pobierz raport w .pdf
				</Button>
			</Grid>
			{warehouseStatistic &&
				<Grid container display={'flex'} flexDirection={'column'} justifyContent="flex-start" gap={2} ml={1}>
					{
						warehouseStatistic.map(({ title, count }) =>
							<Grid key={title} item>
								{title}: <b>{count}</b>
							</Grid>
						)
					}
				</Grid>}
		</div>
	)
}