import React from 'react';
import logo from '../../assets/img/logo.svg';
import { Grid } from '@mui/material';

const Header = () => {
	return (
		<Grid className="header">
			<a className="header__link" href="/">
				<img className="header__logo" src={logo} alt="logo"/>
			</a>
		</Grid>
	)
}

export default Header;