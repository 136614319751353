import {
    CLEAR_RECKONINGS_STATE,
    GET_RECKONINGS,
    GET_RECKONING,
    RECKONING_ACCEPTED,
    RECKONING_REJECTED,
    RECKONING_HANDLED,
    RECKONING_REPORTS_GENERATED
} from '../types';


const handlers = {
    [GET_RECKONINGS]: (state, {payload}) => ({...state, reckonings: [...payload]}),
    [GET_RECKONING]: (state, {payload}) => ({...state, reckoning: payload}),
    [RECKONING_ACCEPTED]: (state) => ({...state, reckoningAccepted: true}),
    [RECKONING_REJECTED]: (state) => ({...state, reckoningRejected: true}),
    [RECKONING_HANDLED]: (state) => ({
        ...state,
        reckoningAccepted: false,
        reckoningRejected: false,
        reckoningHandled: true,
        reckoningReportsGenerated: false
    }),
    [RECKONING_REPORTS_GENERATED]: (state) => ({...state, reckoningReportsGenerated: true}),
    [CLEAR_RECKONINGS_STATE]: (state, {payload}) => ({...payload}),
    DEFAULT: state => state,
}

export const reckoningsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
}