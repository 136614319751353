import React, {useContext, useEffect, useState} from 'react'
import {SettlementsContext} from '../../../../context/settlements/settlementsContext';
import {Button, Grid, Stack, Typography} from '@mui/material';
import avatar from '../../../../assets/img/ui/windowPerson.png';
import {dateFormat} from '../../../../utils/helpfunc';
import CoachingAdd from './CoachingAdd'
import SearchIcon from "@mui/icons-material/Search";

const Coachings = ({settlementPeriod, reckoningStatus}) => {

    const {settlementsState} = useContext(SettlementsContext);
    const [coachings, setCoachings] = useState([])
    const [openCoachingId, setOpenCoachingId] = useState(null)

    useEffect(() => {
        setCoachings([...settlementsState.couching].sort((a, b) => new Date(b.date) - new Date(a.date)))
    }, [settlementsState])

    return coachings?.length !== 0 ?
        coachings.map(el => {
                const makers = el.participants.map((el) => settlementsState.listOfUsers?.find(item => item.id === el.id)?.name).join(', ');
                return <Grid container key={el.id} p={1}>
                    {/*TRAINING TITLE*/}
                    <Grid
                        item
                        xs={12}
                        container
                        display="row"
                        wrap="nowrap"
                        p={1}
                        alignItems="center"
                        bgcolor="#f5f5f5"
                        padding={"30px 70px 40px"}
                    >
                        <Grid item pr={"45px"}>
                            <img src={avatar} alt="ava" className="settlements__blockImage_XL"/>
                        </Grid>
                        <Grid item ml={1} container justifyContent={'flex-start'}>
                            <Grid item xs={12} sm={4}>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Coaching</b></Typography>
                                </Stack>
                                <Stack className='settlements__status' direction="column">
                                    <Typography><b>Data:</b></Typography>
                                    <Typography>{dateFormat(new Date(el.date))}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item container xs={12} sm={7}>
                                <Grid direction="column" container item xs={12} sm={6} mb={2}
                                      style={{visibility: makers.length ? 'visible' : 'hidden', minHeight: '5rem'}}>
                                    <Stack className='settlements__status' direction="column">
                                        <Typography>Czas trwania:</Typography>
                                        <Typography><i><b>{el.duration} min.</b></i></Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={5} mb={'auto'} mt={2}>
                                        <Button color="secondary" variant="contained" className="buttonCustom"
                                                onClick={() => {
                                                    setOpenCoachingId(openCoachingId === el.id ? el.id : null)
                                                }}>
                                            <SearchIcon/>
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid direction="column" container item xs={12} sm={6} mb={2}
                                      style={{
                                          visibility: makers.trim().length > 1 ? 'visible' : 'hidden',
                                          minHeight: '5rem'
                                      }}>
                                    <Typography>Wykonujący szkolenie:</Typography>
                                    <Typography><i><b>{makers}</b></i></Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        openCoachingId === el.id &&
                        <CoachingAdd settlementPeriod={settlementPeriod} coachingInfo={el} reckoningStatus={reckoningStatus}
                                     setIsAdding={setOpenCoachingId}/>
                    }
                </Grid>
            }
        )
        :
        <Grid container alignItems="center" justifyContent="center" p={2}>
            <Typography>Brak danych</Typography>
        </Grid>
}

export default Coachings;