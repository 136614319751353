import React, { useContext, useEffect, useState } from 'react';
import { PatientContext, PoiContext } from '../../../context';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import PegSubModal from './PegSubModal';
import { patientModalValidate } from './patientModalValidate';
import {ADD_OPTION, EDIT_OPTION, SHOW_OPTION} from '../../../data/consts/modalOptions';
import { DatePickerComponent } from '../../shared/ui/DatePickerComponent';
import {reasonsNoPump} from "./helper";
import {AddPump} from "../../shared/addPump/AddPump";
import {convertUTCToDate} from "../../../utils/formatYearMonth";

const PatientModal = ({ closeModal, modalOption, userID }) => {

	const { setModalError } = useContext(PoiContext)
	const {
		getAdditionalPatientInfo,
		patientState,
		addPatient,
		getPatient,
		clearPatient,
		editPatient,
	} = useContext(PatientContext);

	const initialPatientInfo = {
		displayName: '',
		nameInitial: '',
		surnameInitial: '',
		pumps: [],
		pesel: '',
		probeSerial: '',
		isTherapyInterrupted: false,
		probeInstallDate: null,
		medicineInclusionDate: null,
		hospitalLeftDate: null,
		therapyReason: '',
		isDataProcessAgreement: false,
		institution: '',
		therapySuspensionReason: '',
		therapySuspensionStartDate: null,
		therapySuspensionEndDate: null,
		therapyLeftReason: null,
		therapyLeftDate: null,
		initiationDate: null
	}

	const [patientInfo, setPatientInfo] = useState(initialPatientInfo)
	const [availablePumps, setAvailablePumps] = useState([]);
	const [pegs, setPegs] = useState([])

	useEffect(() => {
		getAdditionalPatientInfo();
		if (modalOption !== 'add') {
			void getPatient(userID);
		}

		return () => {
			clearPatient()
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (patientState.patient) {
			setPatientInfo({ ...patientState.patient.info });
			setPegs([...patientState.patient.pegs]);
		}
	}, [patientState.patient])

	useEffect(() => {
		if (patientInfo.institution && patientInfo.institution !== '' && patientState.institutions) {
			setAvailablePumps(patientState.institutions.find(el => el.id === patientInfo.institution).pumps)
		} else {
			setAvailablePumps([])
		}
	}, [patientInfo.institution, patientState.institutions])


	const changeInfoHandler = (name, value) => {
		if (name === 'pesel' && value.length > 4) return;
		setPatientInfo(prev => ({ ...prev, [name]: value }))
	}

	const pegsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		pegs.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setPegs([...tempArray])
	}

	const addPegsTableRow = () => setPegs(prev => [...prev, {
		serialNumber: '',
		installDate: null,
	}])


	const submitHandler = async (e) => {
		e.preventDefault();
		const validation = patientModalValidate(patientInfo, pegs, modalOption);

		if (validation.isError) {
			setModalError(validation.defRes);
		} else {
			const data = {
				...patientInfo,
				pesel: `${patientInfo.pesel}`,
				institutionId: patientInfo.institution,
				therapyReason: patientInfo.therapyReason.length === 0 ? null : patientInfo.therapyReason,
				therapySuspensionReason: patientInfo.therapySuspensionReason.length === 0 ? null : patientInfo.therapySuspensionReason,
				therapyLeftReason: patientInfo.therapyLeftReason,
				pegs,
				initiationDate: patientInfo.initiationDate ? convertUTCToDate(patientInfo.initiationDate) : null,
				hospitalLeftDate: patientInfo.hospitalLeftDate ? convertUTCToDate(patientInfo.hospitalLeftDate) : null,
				medicineInclusionDate:  patientInfo.medicineInclusionDate ? convertUTCToDate(patientInfo.medicineInclusionDate) : null,
				therapyLeftDate: patientInfo.therapyLeftDate ? convertUTCToDate(patientInfo.therapyLeftDate) : null,
				probeInstallDate: patientInfo.probeInstallDate ? convertUTCToDate(patientInfo.probeInstallDate) : null,
				therapySuspensionStartDate:  patientInfo.therapySuspensionStartDate ? convertUTCToDate(patientInfo.therapySuspensionStartDate) : null,
				therapySuspensionEndDate:  patientInfo.therapySuspensionEndDate ? convertUTCToDate(patientInfo.therapySuspensionEndDate) : null,
				pumps: modalOption === ADD_OPTION ? [...patientInfo.pumps] : [...patientInfo.pumps.filter(pump => !pump.hasOwnProperty('serialNumber'))]
			}

			if (modalOption === 'add') {
				await addPatient(data);
				closeModal()
			}

			if (modalOption === 'edit') {
				editPatient(userID, data);
				closeModal();
			}
		}
	}

	const handleAddPumps = (pumps) => {
		setPatientInfo({
			...patientInfo,
			pumps,
			therapyLeftReason: null,
			therapyLeftDate: null,
		})
	}


 	const changeTherapyInterrupted = () => {
		setPatientInfo(prevState=> {
				return {
					...patientInfo,
					isTherapyInterrupted: !prevState.isTherapyInterrupted,
					pumps: [],
					probeSerial: '',
					probeInstallDate: null,
					medicineInclusionDate: null,
					hospitalLeftDate: null,
					therapySuspensionReason: '',
					therapyReason: ''
				}
			}
		)
		setPegs([])
	}

	const pumpsPatientState = patientState?.patient?.info?.pumps ? patientState.patient.info.pumps : [];

	const therapyInterrupted = (modalOption === EDIT_OPTION && patientInfo.pumps.length > 0) || modalOption === SHOW_OPTION;

	const delPegsTableRow = (index) => setPegs(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))
	const hospitalDisabled = modalOption !== ADD_OPTION;
	const addPumpsEnabled = !patientInfo.isTherapyInterrupted && patientInfo.institution && modalOption !== SHOW_OPTION;

	return (
		<div className="customModal" >
			<Stack component="div" className="customModal__addMenu">
				<Typography variant="h4"
				            mb={2}>{modalOption === 'add' ? 'Nowy pacjent:' : modalOption === 'show' ? 'Dane pacjenta:' : 'Edytuj dane pacjenta'}</Typography>
				<form onSubmit={(e) => submitHandler(e)} >
					<Grid
						container
						display="flex"
						direction="column"
						wrap="nowrap"
						className="customModal__scrollArea"
						style={{width: '80vw'}}
					>
						{
							modalOption === 'add'
								?
								<>
									<Grid item container justifyContent="space-between">
										<Grid item xs={6} pr={1}>
											<FormControl fullWidth required>
												<Typography variant="subtitle1">Imię (inicjały): *</Typography>
												<OutlinedInput
													required
													name="nameInitial"
													value={patientInfo.nameInitial}
													onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6}>
											<FormControl fullWidth required>
												<Typography variant="subtitle1">Nazwisko (inicjały): *</Typography>
												<OutlinedInput
													name="surnameInitial"
													value={patientInfo.surnameInitial}
													onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Grid item mt={2}>
										<FormControl fullWidth required>
											<Typography variant="subtitle1">4 ostatnie cyfry PESEL: *</Typography>
											<OutlinedInput
												name="pesel"
												type="number"
												inputProps={{ maxLength: 4 }}
												onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
												value={patientInfo.pesel}
											/>
										</FormControl>
									</Grid>
								</>
								:
								<Grid item mt={2}>
									<FormControl fullWidth>
										<Typography variant="subtitle1">Inicjały: *</Typography>
										<OutlinedInput
											name="Initials"
											value={patientInfo.displayName}
											disabled={(modalOption !== 'add')}
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										/>
									</FormControl>
								</Grid>
						}

						<Grid container justifyContent="space-between">
							<Grid
								item
								xs={12}
								mt={2}
							>{patientState.institutions &&
									<FormControl fullWidth>
										<InputLabel id="institution-label" shrink={true}>SZPITAL*</InputLabel>
										<Select
											labelId="institution-label"
											id="institution"
											name="institution"
											disabled={hospitalDisabled}
											value={patientInfo.institution}
											label="SZPITAL"
											notched={true}
											displayEmpty
											onChange={(e) =>{
												changeInfoHandler(e.target.name, e.target.value);
												changeInfoHandler('pumps', []);
											}}>
											<MenuItem value="">Wybierz z listy</MenuItem>
											{
												patientState.institutions.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
											}
										</Select>
									</FormControl>
								}
							</Grid>
							<Grid container>
								{(modalOption === ADD_OPTION || ([EDIT_OPTION, SHOW_OPTION].includes(modalOption) && patientInfo.isTherapyInterrupted)) && <Grid xs={6} item mt={2}>
									<FormControlLabel control={
										<Checkbox
											disabled={modalOption !== ADD_OPTION}
											sx={{padding: 0, marginRight: 2}}
											name="isTherapyInterrupted"
											checked={Boolean(patientInfo.isTherapyInterrupted)}
											onChange={(e) => !therapyInterrupted && changeTherapyInterrupted()}
										/>} label="Przerwana terapia w trakcie wdrożenia"/>

								</Grid>}
								{!patientInfo.isTherapyInterrupted && <>{patientInfo.institution === ''
									?
									<Grid xs={6} item>
										<Typography mt={2}>Wybierz szpital, aby móc przypisać pompę do pacjenta</Typography>
									</Grid>
									: <>{availablePumps.length === 0 &&
										<Grid item xs={12}><Typography color="red" mt={2}>Brak pomp na stanie
											szpitala.</Typography></Grid>}</>}</>}
							</Grid>
							{addPumpsEnabled &&
								<AddPump isEdit={modalOption === EDIT_OPTION}
												 availablePumps={availablePumps}
												 initialPumps={pumpsPatientState}
												 pumps={patientInfo.pumps}
												 addPumps={handleAddPumps}/>}

							{modalOption === SHOW_OPTION && pumpsPatientState && <>
								{pumpsPatientState.length !== 0 && <Grid item xs={12} lg={12} mt={2}>{`Pierwsza pompa przypisana do pacjenta (SN): ${pumpsPatientState[0].serialNumber}`}</Grid>}
								{pumpsPatientState.length > 1 && <Grid item xs={12} lg={12} mt={2}>{`Druga pompa przypisana do pacjenta (SN): ${pumpsPatientState[1].serialNumber}`}</Grid>}
							</>}
						</Grid>

						{!patientInfo.isTherapyInterrupted && <>
						<PegSubModal
							pegs={pegs}
							addPegsTableRow={addPegsTableRow}
							pegsTableHandler={pegsTableHandler}
							delPegsTableRow={delPegsTableRow}
							modalOption={modalOption}
						/>
						<Grid item container justifyContent="space-between" mt={2}>
							<Grid item xs={6} pr={1}>
								<section>
								<FormControl fullWidth required>
									<Typography variant="subtitle1">Sonda nr serii: *</Typography>
									<OutlinedInput
										name="probeSerial"
										value={patientInfo.probeSerial}
										disabled={modalOption === 'show'}
										onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
									/>
								</FormControl>
								</section>
							</Grid>
							<Grid item xs={6}>
								<DatePickerComponent
									allowSameDateSelection
									disabled={modalOption === 'show'}
									required
									disableFuture
									value={patientInfo.probeInstallDate}
									onChange={(newValue) => changeInfoHandler('probeInstallDate', newValue)}
								>
									<Typography variant="subtitle1">Data założenia sondy: *</Typography>
								</DatePickerComponent>
							</Grid>
						</Grid>
						<Grid item container justifyContent="space-between" mt={2}>
							<Grid item xs={6} pr={1}>
								<DatePickerComponent
									allowSameDateSelection
									disableFuture
									value={patientInfo.medicineInclusionDate}
									disabled={modalOption === 'show'}
									onChange={(newValue) => changeInfoHandler('medicineInclusionDate', newValue)}
								>
									<Typography variant="subtitle1">Data włączenia leku:</Typography>
								</DatePickerComponent>
							</Grid>
							<Grid item xs={6}>
								<DatePickerComponent
									allowSameDateSelection
									disableFuture
									value={patientInfo.hospitalLeftDate}
									disabled={modalOption === 'show'}
									onChange={(newValue) => changeInfoHandler('hospitalLeftDate', newValue)}
								>
									<Typography variant="subtitle1">Data wypisu ze szpitala:</Typography>
								</DatePickerComponent>
							</Grid>
						</Grid>
						<Grid item mt={2}>
							<FormControl fullWidth>
								<Typography variant="subtitle1">Przyczyna włączenia do terapii:</Typography>
								<Select
									labelId="therapyReason-label"
									name="therapyReason"
									value={patientInfo.therapyReason}
									disabled={modalOption === 'show'}
									label="Przyczyna włączenia do terapii:"
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
								>
									<MenuItem value=""><em>Wyczyść listę</em></MenuItem>
									{patientState.therapyReasons && patientState.therapyReasons.map((el, index) =>
										<MenuItem key={index}
										          value={el}>{el}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>
						</>}
						{
							modalOption === ADD_OPTION &&
							<Grid item mt={2}>
								<DatePickerComponent
									allowSameDateSelection
									disableFuture
									required
									value={patientInfo.initiationDate}
									disabled={modalOption === 'show'}
									onChange={(newValue) => changeInfoHandler('initiationDate', newValue)}
								>
									<Typography variant="subtitle1">Miesiąc-Rok wdrożenia pacjenta*</Typography>
								</DatePickerComponent>
							</Grid>
						}
						{/*{*/}
						{/*	modalOption === EDIT_OPTION && (*/}
						{/*		<Grid item mt={2}>*/}
						{/*			<Grid container flexDirection="row">*/}
						{/*				<Typography variant="subtitle1">Data wdrożenia pacjenta:</Typography>*/}
						{/*				/!*<Typography variant="subtitle1"*!/*/}
						{/*				/!*            ml={1}>{patientInfo.initiationDate.getMonth() + 1}.{patientInfo.initiationDate.getFullYear()}</Typography>*!/*/}
						{/*			</Grid>*/}
						{/*		</Grid>*/}
						{/*	)*/}
						{/*}*/}
						{
							modalOption !== ADD_OPTION &&
							<>
								<Grid item mt={2}>
									<FormControl fullWidth>
										<InputLabel id="therapySuspensionReason-label">Przyczyna zawieszenia
											terapii:</InputLabel>
										<Select
											labelId="therapySuspensionReason-label"
											name="therapySuspensionReason"
											value={patientInfo.therapySuspensionReason}
											disabled={modalOption === 'show'}
											label="Przyczyna włączenia do terapii:"
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										>
											<MenuItem value=""><em>Wyczyść listę</em></MenuItem>
											{
												patientState.therapySuspensionReasons
												&& patientState.therapySuspensionReasons.map((el, index) =>
													<MenuItem
														key={index}
														value={el}
													>
														{el}
													</MenuItem>,
												)
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item container justifyContent="space-between" mt={2}>
									<Grid item xs={6} pr={1}>
										<DatePickerComponent
											allowSameDateSelection
											disableFuture
											value={patientInfo.therapySuspensionStartDate}
											disabled={modalOption === 'show'}
											onChange={(newValue) => changeInfoHandler('therapySuspensionStartDate', newValue)}
										>
											<Typography variant="subtitle1">
												Data zawieszenia terapii początek:
											</Typography>
										</DatePickerComponent>
									</Grid>
									<Grid item xs={6}>
										<DatePickerComponent
											allowSameDateSelection
											disableFuture
											value={patientInfo.therapySuspensionEndDate}
											disabled={modalOption === 'show'}
											onChange={(newValue) => changeInfoHandler('therapySuspensionEndDate', newValue)}
										>
											<Typography variant="subtitle1">
												Data zawieszenia terapii koniec:
											</Typography>
										</DatePickerComponent>
									</Grid>
								</Grid>

							</>
						}
						{patientInfo.isTherapyInterrupted &&  <Grid container mt={2} justifyContent="space-between">
							<Grid item xs={12} lg={6} pr={1}>
								<FormControl fullWidth required>
									<InputLabel sx={{background: 'white'}} id="availablePumps-label" shrink={true}>Data zerwania
										terapii*</InputLabel>
									<DatePickerComponent
										disabled={modalOption === 'show'}
										value={patientInfo.therapyLeftDate}
										disableFuture
										onChange={(newValue) => changeInfoHandler('therapyLeftDate', newValue)}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} lg={6}>
								<FormControl fullWidth>

									<Select
										required
										labelId="reason-label"
										id="therapyLeftReason"
										name="therapyLeftReason"
										disabled={modalOption === 'show'}
										value={patientInfo.therapyLeftReason}
										notched={true}
										displayEmpty
										onChange={(newValue) => changeInfoHandler('therapyLeftReason', newValue.target.value)}>
										<MenuItem value="">Wybierz z listy</MenuItem>
										{
											reasonsNoPump.map(el => (
												<MenuItem
													key={el}
													value={el}>{el}</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>
						</Grid>}
						<Grid item mt={2}>
							<FormControlLabel control={
								<Checkbox
									name="isDataProcessAgreement"
									checked={modalOption !== ADD_OPTION ? true : Boolean(patientInfo.isDataProcessAgreement)}
									disabled={modalOption !== ADD_OPTION}
									onChange={(e) => changeInfoHandler(e.target.name, e.target.checked)}
								/>}
							                  label="Czy przekazano informacje o przetwarzaniu danych osobowych dla pacjenta/opiekuna?"/>
						</Grid>
					</Grid>

					<div className={`customModal__addMenu-buttonArea ${modalOption === 'show' ? 'alignButton' : ''} `}>
						<Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
						{
							modalOption !== 'show' &&
							<Button
								type="submit"
								color="primary" variant="contained">
								{modalOption === 'add' ? 'Dodaj' : 'Zapisz'}
							</Button>
						}
					</div>
				</form>
			</Stack>
		</div>
	)
}

export default PatientModal;