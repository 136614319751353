export const initPatientModule = {
    initials: '',
    id: '',
    gender: '',
    age: '',
    dateOfBirth: null,
    dateOfEvent: null,
}
export const initMainModule = {
    type: 'nowe',
    number: '',
    fromPatient: false,
    fromFamily: false,
    fromHealthCare: false,
    fromAnother: false,
    additionalContact: '',
};

export const initDoctorModule = {
    contactApprove: '',
    gastrologist: false,
    neurologist: false,
    radiologist: false,
    other: false,
    additionalSpecialization: '',
    name: '',
    address: '',
    voivodeship: '',
    telephone: '',
    city: '',
    zipCode: '',
}

export const initCircumstancesModule = {
    description: '',
    condition: '',
    pump: false,
    pumpSNo: '',
    nj: false,
    startNJ: null,
    peg: false,
    lastInstallPeg: null,
    pegSNo: '',
    jejunum: false,
    lastInstallJejunum: null,
    jejunumSNo: '',
    gel: false,
    gelSNo: '',
    anotherProduct: false,
    additionalProduct: '',
    anotherProductReload: 'Tak',
    anotherProductSNo: '',
    anotherProductAccessoriesNo: '',
    confirm: false,
    complaintSample: 'Tak',
    discarded: false,
    stillInUse: false,
    submitSample: false,
    anotherReason: false,
    additionalReason: '',
    startTreatment: null,
    startPeg: null,
    noPackage: false,
    noAnswer: false,
    noContact: false,
    noContactInfo: false,
    noRegistration: false,
    anotherReasonNS: false,
    additionalReasonNS: '',
}

export const initPompSubmodule = {
    isAlarm: 'Tak',
    continuousAlarm: false,
    noConnect: false,
    highPressureAlarm: false,
    fourDigitError: false,
    unspecifiedAlarm: false,
    waterDamage: false,
    physicalDamage: false,
    dropping: false,
    batteryProblem: false,
    batteryCover: false,
    keyboard: false,
    drugDelivery: false,
    cassetteHolder: false,
    display: false,
    onOrOff: false,
    programmingProblem: false,
    feedRate: false,
    usageError: false,
    usageErrorValue: '',
    problemResolved: 'Nie',
    redPlugsRemoved: false,
    batteryReplaced: false,
    reLaunchPomp: false,
    reLaunchCassette: false,
    reLaunchCover: false,
    noDisposable: false,
    replacementPomp: 'Nie',
    replacementPompComment: '',
}

export const initPipeSubmodule = {
    isPipeProblem: 'Nie',
    intestinalProbe: false,
    ostomyProbe: false,
    nasoProbe: false,
    extensionPipe: false,
    isAbbVie: false,
    isFreka: false,
    isBostonScientific: false,
    isCook: false,
    isCovidien: false,
    isUnknown: false,
    isAnotherName: false,
    anotherName: '',
    stomaRelated: false,
    blockage: false,
    locationChange: false,
    pegDisconnected: false,
    snapDisconnected: false,
    snapDetachment: false,
    unspecifiedDisconnection: false,
    fold: false,
    entanglement: false,
    isCut: false,
    leakyConnector: false,
    isUsageError: false,
    isUsageErrorValue: '',
    isAnother: false,
    isAnotherValue: '',
    problemResolved: 'Nie',
    rinse: false,
    reconnectConnector: false,
    connectorReplacement: false,
    pegReplaced: false,
    jejunumPipeReplace: false,
    jejunumPipeReplaceDescription: '',
    endoscopicSurgery: false,
    fluoroscopy: false,
    isAnotherEvent: false,
    isAnotherEventValue: false,
}

export const initGelSubmodule = {
    wasCassetteInUse: 'Tak',
    cassetteCount: 0,
    typeLuer: false,
    innerCase: false,
    outerCase: false,
    caseConnect: false,
    anotherCassetteDamage: false,
    anotherCassetteDamageDescription: '',
    difficultTakeOff: false,
    loose: false,
    broken: false,
    holeInPipe: false,
    brokenLuer: false,
    redLabel: false,
    anotherProblem: false,
    anotherProblemValue: '',
    appearanceDrugProblem: 'Nie',
    isCassetteCoverDamaged: 'Nie',
    cassetteCoverDamageDescription: '',
    problemDescription: '',
    problemTime: '',
    isCassetteChecked: '',
    productStorage: '',
}

export const initAdverseEventsTableValue = {
    details: '',
    dateStart: null,
    dateEnd: null,
    result: '',
    causation: '',
}

export const initDrugTableValue = {
    name: '',
    dateStart: null,
    dateEnd: null,
    dosage: '',
    method: '',
    indication: '',
}

export const initMedicalInterviewsTableValue = {
    period: '',
    dateStart: null,
    dateEnd: null,
    additionalInfo: '',
}


export const setRadioButtonNotRequired = (targetValue, value) => {
    return value !== '' && value === targetValue ? '' :  targetValue
}

export const countGenderFromInitials = (pesel) => {
    return pesel.substring(pesel.length -2, pesel.length-1) %2 === 0 ? 'K' : 'M'
}