import {useContext, useEffect} from 'react';
import {AuthContext} from '../../../context';
// import {Typography} from '@mui/material';

const Logout = () => {
    const {logout} = useContext(AuthContext);

    useEffect(() => {
        logout()
    }, [logout])

    return null
    // (
    //     <div>
    //         <Typography variant='h4'/>
    //     </div>
    // )
}

export default Logout;