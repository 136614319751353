import React, { useContext, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useAlertStore } from '../../store/hooks';
import { useAlertApi } from '../../apiHooks';
import { AuthContext } from '../../context';

import { Alert, Grid, Typography } from '@mui/material';

import { ROLE_ADS } from '../../data/consts/users';

const MainDash = observer(() => {
	const { role } = useContext(AuthContext);
	const { getAlerts } = useAlertApi();
	const { alerts } = useAlertStore();

	useEffect(() => {
		if (role === ROLE_ADS) {
			void getAlerts()
		}
	}, [role, getAlerts]);

	return (
		<Grid className="mainDash">
			<Typography variant="h3">Witamy w aplikacji ADS</Typography>

			{
				role === ROLE_ADS && alerts &&
				<>
					<Grid container spacing={1} mt={4} px={'2px'} width={'auto'}>
						{
							alerts.patients.length > 0 &&
							<Grid item xs={3}>
								<Alert variant="outlined" severity="error" width={'100%'}>
									<p>Pacjenci bez informacji aktywnej o pompie (uzupełnij dane)</p>
									<ol style={{ marginTop: 10 }}>
										{
											alerts.patients.map((element, index) =>
												<li style={{ marginTop: 3 }} key={`patient-${element.patient}-${index}`}>
													Pacjent <b>{element.patient}</b> : brak przypisanej pompy -&nbsp;<a href={`/patients?search=${element.patient}`}>pokaż więcej</a>
												</li>
											)}
									</ol>
								</Alert>
							</Grid>}
						{
							alerts.certificates.length > 0 && <Grid item xs={3}>
								<Alert variant="outlined" severity="error" width={'100%'}>
									<p>Szkolenia do wykonania</p>
									<ol style={{ marginTop: 10 }}>
										{
											alerts.certificates.map(element =>
												<li style={{ marginTop: 3 }} key={`certificate-${element.id}`}>
													Szkolenie <b>{element.title}</b> ({element.date}) : brak wykonanego szkolenia -&nbsp;
													<a href={`/certification?search=${element.title}`}>pokaż więcej</a>
												</li>
											)}
									</ol>
								</Alert>
							</Grid>}
						{
							alerts.pumps.length > 0 &&
							<Grid item xs={3}>
								<Alert variant="outlined" severity="warning" width={'100%'}>
									<p>Niewysłane pompy</p>
									<ol style={{ marginTop: 10 }}>
										{
											alerts.pumps.map(element =>
												<li style={{ marginTop: 3 }} key={`pump-${element.id}`}>
													Pompa <b>{element.serialNumber}</b> ({element.institution}) : nie wysłana pompa
													{element.patientId && <>&nbsp;-&nbsp;<a href={`/adverse_events?idPatient=${element.patientId}&serialNumber=${element.serialNumber}`}>pokaż formularz</a></>}
												</li>)
										}
									</ol>
								</Alert>
							</Grid>}
						{
							alerts.messages.length > 0 && <Grid item xs={3}>
								<Alert variant="outlined" severity="warning" width={'100%'}>
									<p>Nieprzeczytane wiadomości</p>
									<ol style={{ marginTop: 10 }}>
										{
											alerts.messages.map(element =>
												<li style={{ marginTop: 3 }} key={`messages-${element.id}`}>
													{element.date.substring(0, 10)} <b>{element.user}</b>&nbsp;-&nbsp;
													<a href={'/messages'}>pokaż więcej</a>
												</li>
											)}
									</ol>
								</Alert>
							</Grid>}
					</Grid>
				</>
			}
		</Grid>
	)
});


export default MainDash;