import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid, InputLabel, MenuItem, OutlinedInput,
    Radio,
    RadioGroup, Select, Stack,
    Typography
} from "@mui/material";
import {regions} from '../../../utils/regionsNormalizer';
import {setRadioButtonNotRequired} from "./helper";

const DoctorInfo = ({doctorModule, doctorModuleHandler}) => {

    return (
        <Grid container border={'1px solid #071d49'}>
            <Grid item xs={12} bgcolor='#071d49' p={'10px 20px'}>
                <Typography
                    variant='h6'
                    color='white'
                >INFORMACJE O LEKARZU</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant='subtitle1'>Czy pacjent wyraża zgodę na skontaktowanie się z lekarzem
                            prowadzącym?</Typography>
                        <RadioGroup
                            name="contactApprove"
                            value={doctorModule.contactApprove}
                            onClick={(e) => {
                                doctorModuleHandler('contactApprove', setRadioButtonNotRequired(e.target.value, doctorModule.contactApprove))
                            }}>
                            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2}>
                        <Typography variant='subtitle1'>Specjalizacja lekarza:</Typography>
                        <FormGroup
                        >
                            <FormControlLabel control={
                                <Checkbox
                                    name="gastrologist"
                                    checked={doctorModule.gastrologist}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.checked)}
                                />} label="gastrolog"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="neurologist"
                                    checked={doctorModule.neurologist}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.checked)}
                                />
                            } label="neurolog"/>
                            <FormControlLabel control={
                                <Checkbox
                                    name="radiologist"
                                    checked={doctorModule.radiologist}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.checked)}
                                />
                            } label="radiolog interwencyjny"/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="other"
                                        checked={doctorModule.other}
                                        onChange={(e) => {
                                            doctorModuleHandler(e.target.name, e.target.checked)
                                            if (!e.target.checked) {
                                                doctorModuleHandler('additionalSpecialization', '')
                                            }
                                        }}
                                    />}
                                label="inna specjalizacja (należy podać, jaka)"
                            />
                            <Box minHeight={56}>
                                {
                                    doctorModule.other && <FormControl fullWidth required>
                                        <OutlinedInput
                                            id="additionalSpecialization"
                                            name="additionalSpecialization"
                                            value={doctorModule.additionalSpecialization}
                                            onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                        />
                                    </FormControl>
                                }
                            </Box>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className='dynamicTopBorder'>
                <Grid container>
                    <Grid item xs={12} lg={6} p={2}>
                        <Stack spacing={3}>
                            <FormControl fullWidth>
                                <Typography variant='subtitle1'>Imię i nazwisko lekarza:</Typography>
                                <OutlinedInput
                                    id='name'
                                    name="name"
                                    value={doctorModule.name}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant='subtitle1'>Adres (ulica):</Typography>
                                <OutlinedInput
                                    id='address'
                                    name="address"
                                    value={doctorModule.address}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <Box sx={{marginTop: '52px !important'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="voivodeship-label">Województwo:</InputLabel>
                                    <Select
                                        labelId="voivodeship-label"
                                        id="voivodeship"
                                        name="voivodeship"
                                        value={doctorModule.voivodeship}
                                        label="Województwo:"
                                        onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                    >
                                        <MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
                                        {
                                            regions.map((region, index) => <MenuItem key={index}
                                                                                     value={region}>{region}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} p={2} className='dynamicLeftBorder'>
                        <Stack spacing={3}>
                            <FormControl fullWidth>
                                <Typography variant='subtitle1'>Numer telefonu:</Typography>
                                <OutlinedInput
                                    id='telephone'
                                    name="telephone"
                                    value={doctorModule.telephone}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant='subtitle1'>Miejscowość:</Typography>
                                <OutlinedInput
                                    id='city'
                                    name="city"
                                    value={doctorModule.city}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant='subtitle1'>Kod pocztowy:</Typography>
                                <OutlinedInput
                                    id='zipCode'
                                    name="zipCode"
                                    value={doctorModule.zipCode}
                                    onChange={(e) => doctorModuleHandler(e.target.name, e.target.value)}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DoctorInfo;
