import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    ListItem,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import tempImage from '../../../assets/img/ui/windowPerson.png';
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {PoiContext} from '../../../context/poi/poiContext';
import {trainingValidate} from './trainingValidate';
import {AuthContext} from '../../../context/auth/authContext';
import {dateFormatFormDay} from '../../../utils/helpfunc';
import {getStyles, MenuProps} from './settlementsData'
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";
import {formatYearMonth} from "../../../utils/formatYearMonth";
import {useParams} from "react-router-dom";
import {ROLE_ADMIN} from "../../../data/consts/users";

const TrainingAdd = ({trainingInfo, settlementPeriod, setIsAdding, isAuthor = true}) => {
    const {settlementsState, editTraining, acceptTraining, createTraining} = useContext(SettlementsContext)
    const {authState: {id: userID}, role} = useContext(AuthContext);
    const {setModalError} = useContext(PoiContext)
    const theme = useTheme();
    const params = useParams()
    const [makers, setMakers] = useState([userID]);
    const [participants, setParticipants] = useState([]);
    const [date, setDate] = useState(null);
    const [subject, setSubject] = useState('');
    const [comment, setComment] = useState('');
    const status = trainingInfo?.status
    const {yearMonth} = params
    const {lastDay, firstDay} = formatYearMonth(yearMonth)
    useEffect(() => {
        if (settlementPeriod) {
            setSubject(trainingInfo.subject)
            setDate(new Date(trainingInfo.date))
            setMakers(trainingInfo.makers.length ? [...trainingInfo.makers] : [userID])
            setParticipants([...trainingInfo.participants])
            setComment(trainingInfo.comment)
        }
    }, [trainingInfo, settlementPeriod, userID])

    const edition = role !== ROLE_ADMIN && (!settlementPeriod || (isAuthor && !status.includes('zaakcept')) || status.includes('nieuzup'))

    const makersHandler = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === 'Wszystkie') {
            setMakers(makers.length === settlementsState.makers.length ? [] : [...settlementsState.makers.map(el => el.id)]);
            return;
        }
        setMakers(value);
    }

    const participantsTableHandler = (name, value, el, index) => {
        const tempArray = [];
        participants.forEach(el => tempArray.push({...el}))
        tempArray[index] = {...el, [name]: value};
        setParticipants([...tempArray])
    }

    const addParticipantsTableRow = () => setParticipants(prev => ([...prev, {
        name: '',
    }]))

    const delParticipantsTableRow = (index) => setParticipants(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

    const submitHandler = (e) => {
        e.preventDefault();

        const validation = trainingValidate(participants, date, makers)

        if (validation.isError) {
            setModalError(validation.defRes);
        } else {
            const data = {
                subject,
                date: dateFormatFormDay(date),
                participants,
                comment,
                makers: [...makers.map(el => ({id: el}))]
            }
            settlementPeriod ? isAuthor ? editTraining(data, trainingInfo.id, settlementPeriod) : acceptTraining(trainingInfo.id) : createTraining(data);
            setIsAdding(false)
        }
    }

    return (
        <Grid item container className="settlements__DatePicker" direction="row" alignItems="center"
              sx={{bgcolor: '#F5F5F5'}}
              mb={1}>
            <Box className="settlements__addBox">
                <Typography className="settlements__addTitle"
                            sx={{fontWeight: "bold", maxWidth: "48rem", borderBottom: "solid 2px #ccc"}}>Dodawanie
                    nowego szkolenia</Typography>
                <Stack direction="row" sx={{marginTop: "1rem",}} spacing={4} alignItems="start">
                    <img src={tempImage} alt=""/>
                    <form onSubmit={submitHandler}>
                        <fieldset disabled={!edition}>
                            <Grid container pb={4} justifyContent='space-between'>
                                <Grid item xs={12} mt={2}>
                                    <TextField disabled={!edition} name="subject" label='NAZWA szkolenia'
                                               value={subject} fullWidth required
                                               onChange={(e) => setSubject(e.target.value)}/>
                                </Grid>
                                <Grid container justifyContent='space-between'>
                                    <Grid item xs={12} lg={5.8} mt={3}>
                                        <DatePickerComponent
                                            required
                                            allowSameDateSelection
                                            disabled={!edition}
                                            label='DATA szkolenia'
                                            openTo="day"
                                            views={['day']}
                                            value={date}
                                            disableFuture
                                            defaultCalendarMonth={new Date(yearMonth)}
                                            minDate={firstDay}
                                            maxDate={lastDay}
                                            onChange={(newValue) => setDate(newValue)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5.8} mt={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id='roles-label'>ADS wykonujący czynności</InputLabel>
                                            <Select disabled={!edition} labelId='roles-label' multiple value={makers}
                                                    onChange={makersHandler}
                                                    input={<OutlinedInput label='ADS wykonujący czynności'/>}
                                                    MenuProps={MenuProps}>
                                                <MenuItem name='Wszystkie' value='Wszystkie'
                                                          style={{
                                                              fontWeight: settlementsState.makers && !!settlementsState.makers.length && !!makers.length && makers.length !== settlementsState.makers.length ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
                                                          }}>
                                                    Wszystkie
                                                </MenuItem>
                                                {settlementsState.makers
                                                    && settlementsState.makers.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}
                                                                  style={getStyles(item.name, makers, theme)}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={5.8} mt={3}>
                                        <FormControl fullWidth required>
                                            <TextField fullWidth disabled={!edition} name='description'
                                                       label='OPIS szkolenia' multiline minRows='4' value={comment}
                                                       onChange={(e) => setComment(e.target.value)}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={9} lg={5.8} mt={3.5} container justifyContent='space-between'>
                                        <Grid item xs={6} mb={1.5}>
                                            <Typography style={{fontSize: "1rem"}} sx={{
                                                borderBottom: "solid 1px #ccc",
                                                fontWeight: "bold",
                                                color: `${!edition ? "grey.main" : null}`
                                            }}>Uczestnicy:</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {edition && <Button color="secondary" variant="contained" className="buttonCustom" size='small'
                                                                onClick={addParticipantsTableRow}>Dodaj</Button>}
                                        </Grid>
                                        {
                                            participants.map((el, index) => edition ?
                                                <Grid
                                                    key={index}
                                                    container
                                                    flexDirection={'column'}
                                                    mt={1}
                                                    px={1} pt={2} pb={1}
                                                    borderRadius={'5px'}
                                                    bgcolor="#F5F5F5"
                                                    alignSelf='center'
                                                >
                                                    <Grid item>
                                                        <FormControl fullWidth required>
                                                            <OutlinedInput
                                                                disabled={!edition}
                                                                name="name"
                                                                value={el.name}
                                                                onChange={(e) => participantsTableHandler(e.target.name, e.target.value, el, index)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container mt={1} alignItems="center"
                                                          justifyContent="space-between">
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                sx={{backgroundColor: '#D32F2F'}}
                                                                className="formButton"
                                                                onClick={() => delParticipantsTableRow(index)}
                                                            >
                                                                Usuń pole
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> :
                                                <Grid key={index} container flexDirection={'row'}>
                                                    <Grid item sx={{display: 'flex', alignItems: 'center'}}>
                                                        <ListItem sx={{
                                                            display: 'list-item',
                                                            width: '35px',
                                                            color: "grey.main"
                                                        }}/><Typography sx={{color: "grey.main"}}>{el.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </fieldset>
                        <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                            {role !== ROLE_ADMIN && (!settlementPeriod || !status.includes('zaakcept')) &&
                                <Button type="submit" color="primary" variant="contained" className="buttonCustom"
                                        sx={{margin: "auto"}}>{isAuthor ? "Zapisz" : "Akceptuj"}</Button>}
                        </Stack>
                    </form>
                </Stack>
            </Box>
        </Grid>
    )
};

export default TrainingAdd
