import { useEffect, useState } from 'react';
import { useWarehouseApi } from '../../../../../apiHooks';

export const useWarehouseOrdersViewModel = () => {

	const { getWarehouseKeeperOrders } = useWarehouseApi();

	useEffect(() => {
		void getWarehouseKeeperOrders();
	}, [getWarehouseKeeperOrders])

	const [isModalOpen, setIsModalOpen] = useState(false)

	const openModalHandler = () => setIsModalOpen(true)
	const closeModalHandler = () => setIsModalOpen(false)

	return {
		isModalOpen,
		openModalHandler,
		closeModalHandler
	}
}