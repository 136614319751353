import { useContext, useReducer } from 'react';
import { PoiContext } from '../poi/poiContext';
import { reckoningsReducer } from './reckoningsReducer';
import { ReckoningsContext } from './reckoningsContext';
import axios from '../../axios/axios';
import {
	CLEAR_RECKONINGS_STATE,
	GET_RECKONING,
	GET_RECKONINGS,
	RECKONING_ACCEPTED,
	RECKONING_HANDLED,
	RECKONING_REJECTED,
	RECKONING_REPORTS_GENERATED
} from '../types';
import { dateFormat } from '../../utils/helpfunc';


export const ReckoningsState = ({ children }) => {

	const { setGlobalLoader, unsetGlobalLoader, setModalError } = useContext(PoiContext)

	const initialState = {
		reckonings: [],
		reckoning: { status: '' },
		reckoningRejected: false,
		reckoningAccepted: false,
		reckoningCompleted: false,
		reckoningHandled: false,
		reckoningReportsGenerated: false
	}

	const [state, dispatch] = useReducer(reckoningsReducer, initialState);

	const getReckonings = async (date) => {
		setGlobalLoader()
		try {
			await axios.get(
				`reckonings?date=${date}`,
			).then(res => {
				dispatch({ type: GET_RECKONINGS, payload: res.data })
				unsetGlobalLoader()
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const getReckoning = async (date, adsId) => {
		setGlobalLoader()
		try {
			await axios.get(
				`reckoning?date=${date}&userId=${adsId}`,
			).then(res => {
				dispatch({ type: GET_RECKONING, payload: res.data[0] })
				unsetGlobalLoader()
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const sendReckoning = async (reckoningId) => {
		setGlobalLoader()
		try {
			await axios.put(
				`reckonings/${reckoningId}/send-to-admin`,
				{},
			).then(res => {
				unsetGlobalLoader()
				dispatch({ type: RECKONING_ACCEPTED })
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const acceptAction = async (actionId, flag, date) => {
		setGlobalLoader()
		try {
			await axios.put(
				`${flag}/${actionId}/approve`,
				{},
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'AKCJA ZAAKCEPTOWANA',
					},
				}
				setModalError(defRes);
				const tempArray = dateFormat(date, true).split('-')
				getReckonings(`${tempArray[1]}-${tempArray[0]}`)
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const approveReckoning = async (reckoningId, date) => {
		setGlobalLoader()
		try {
			await axios.put(
				`reckonings/${reckoningId}/approve`,
				{},
			).then(res => {
				unsetGlobalLoader();
				dispatch({ type: RECKONING_ACCEPTED })
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const getReckoningReports = async (userId, date) => {
		setGlobalLoader()
		try {
			const [firstRes, secondRes, thirdRes] = await Promise.all([
				await axios.get(
					`report/summary?date=${date}&userId=${userId}`,
					{
						headers:
							{
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							},
						responseType: 'arraybuffer',
					}
				),
				await axios.get(
					`report/summary-main?date=${date}&userId=${userId}`,
					{
						headers:
							{
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							},
						responseType: 'arraybuffer',
					},
				),
				await axios.get(
					`report/summary-activity?date=${date}&userId=${userId}`,
					{
						headers:
							{
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							},
						responseType: 'arraybuffer',
					},
				)
			]);

			let i = 0;
			for (const res of [firstRes, secondRes, thirdRes]) {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `reckoning-report-${i}.xlsx`);
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url)
			}

			unsetGlobalLoader();
			dispatch({ type: RECKONING_REPORTS_GENERATED })
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}


	const getReckoningBill = async (userId, date) => {
		setGlobalLoader()
		try {
			await axios.get(
				`admin/reckoning/pdf?date=${date}&userId=${userId}`,
				{
					headers:
						{
							'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						},
					responseType: 'arraybuffer',
				}
			)
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `reckoning-bill.pdf`);
					document.body.appendChild(link);
					link.click();
					URL.revokeObjectURL(url)
				})
			unsetGlobalLoader();
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	const rejectReckoning = async (reckoningId, data) => {
		setGlobalLoader()
		try {
			await axios.put(
				`reckonings/${reckoningId}/reject`,
				data,
			).then(res => {
				unsetGlobalLoader();
				dispatch({ type: RECKONING_REJECTED })
				// const tempArray = dateFormat(date, true).split('-')
				getReckonings(`${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}`)
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const generateReckoning = async (reckoningId) => {
		setGlobalLoader()
		try {
			await axios.put(
				`reckonings/${reckoningId}/generate`,
				{},
			).then(res => {
				unsetGlobalLoader();
				dispatch({ type: RECKONING_ACCEPTED })
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const rejectAction = async (actionId, flag, data, date) => {
		setGlobalLoader()
		try {
			await axios.put(
				`${flag}/${actionId}/reject`,
				data,
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'AKCJA ODRZUCONA',
					},
				}
				setModalError(defRes);
				const tempArray = dateFormat(date, true).split('-')
				getReckonings(`${tempArray[1]}-${tempArray[0]}`)
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const clearReckoningsState = () => dispatch({ type: CLEAR_RECKONINGS_STATE, payload: initialState })

	const confirmReckoning = () => dispatch({ type: RECKONING_HANDLED })

	return (
		<ReckoningsContext.Provider value={{
			reckoningsState: state,
			getReckonings,
			clearReckoningsState,
			acceptAction,
			rejectAction,
			approveReckoning,
			rejectReckoning,
			getReckoning,
			sendReckoning,
			confirmReckoning,
			generateReckoning,
			getReckoningBill,
			getReckoningReports
		}}>
			{children}
		</ReckoningsContext.Provider>
	)
}