import {createTheme, TableRow} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            lg: 1200,
            lgx: 1500,
        },
    },
});

export const colors = [
    '#4D5366',
    '#7E8393',
    '#8B91A5',
    '#9DA2B0',
    '#202124',
    '#707070'
]

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
        padding: 16,
        borderBottom: 'solid 3px white',
        fontSize: 14,
        '@media (min-width:1200px)': {
            fontSize: 16
        }
    },
    [`&.${tableCellClasses.body}`]: {
        borderBottom: 'solid 3px white',
        fontSize: 13,
        '@media (min-width:1200px)': {
            fontSize: 15
        }
    },
    [`&.${tableCellClasses.footer}`]: {
        color: theme.palette.common.white,
        fontSize: 14,
        '@media (min-width:1200px)': {
            fontSize: 16
        }
    },
}));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F5F5F5'
    },
    // hide last border
    '&:nth-of-type(even)': {
        backgroundColor: '#EBEBEB'
    },
}));