import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import PageTitle from "../../shared/ui/PageTitle";
import {Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {AuthContext} from "../../../context/auth/authContext";

const ResetPasswordToken = () => {

    const {resetPasswordToken} = useContext(AuthContext);

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [password1Error, setPassword1Error] = useState(false);
    const [password2Error, setPassword2Error] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const params = useParams();

    const submitHandler = (e) => {
        e.preventDefault()

        setPassword1Error(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password1))
        setPassword2Error(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password2))

        if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password1)
            && password1 === password2) {
            resetPasswordToken(password2, params.token)
        }
    }

    const handleMouseDownPassword = (e) => e.preventDefault();


    return (
        <form className='auth' onSubmit={(e) => submitHandler(e)}>
            <PageTitle title="Zmiana hasła:"/>
            <Typography mb={1}>Wprowadż nowe hasło:</Typography>
            <FormControl required>
                <InputLabel htmlFor="password1" sx={{width: '200px'}}>Password</InputLabel>
                <OutlinedInput
                    id="password1"
                    label="Password"
                    type={showPassword1 ? 'text' : 'password'}
                    placeholder="enter password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    error={password1Error}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword1(prev => !prev)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {!showPassword1 ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <Typography variant="caption" mb={3}
                            sx={{color: 'red', height: '10px'}}
                >
                    {password1Error ? 'check your password' : ''}
                </Typography>
            </FormControl>
            <FormControl required>
                <InputLabel htmlFor="password2" sx={{width: '200px'}}>Confirm Password</InputLabel>
                <OutlinedInput
                    id="password2"
                    label="Confirm password"
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder="enter password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    error={password2Error}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword2(prev => !prev)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {!showPassword2 ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <Typography variant="caption" mb={3}
                            sx={{color: 'red', height: '10px'}}
                >
                    {password2Error ? 'check your password' : ''}
                </Typography>
            </FormControl>
            <Button
                type="submit"
                color="primary" variant="contained"
                sx={{alignSelf: 'center', marginBottom: '24px'}}
            >
                Login
            </Button>
        </form>
    )
}

export default ResetPasswordToken;