import React from "react";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup, Stack,
    Typography
} from "@mui/material";

import { DatePickerComponent } from '../../shared/ui/DatePickerComponent';


export const DuopopaDrugTherapy = ({duopopaDrugTherapyModule, duopopaDrugTherapyHandler, isPatientSelected}) => {

 return (<><Grid item container className="dynamicTopBorder">
            <Grid item xs={12} bgcolor="#071d49" p={'10px 20px'}>
                <Typography
                    variant="h6"
                    color="white">INFORMACJE O TERAPII LEKIEM DUODOPA</Typography>
            </Grid>
            <Grid item xs={12} lg={12} p={2} container direction="column" justifyContent="space-between">
                <Typography variant="subtitle1">Czy znana seria leku ?</Typography>
                <RadioGroup
                    name="isFamiliarSeries"
                    value={duopopaDrugTherapyModule.isFamiliarSeries}
                    onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
                >
                    <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
                    <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                </RadioGroup>
            </Grid>

            <Grid item xs={12} lg={6} p={2} container direction="column" justifyContent="space-between" className='borderTopContainer' >
                <DatePickerComponent
                    disableFuture
                    allowSameDateSelection
                    value={duopopaDrugTherapyModule.startTreatment}
                    onChange={(newValue) => {
                     duopopaDrugTherapyHandler('startTreatment', newValue);
                    }}
                >
                    <Typography variant="subtitle1" mr={3}>Data rozpoczęcia terapii lekiem Duodopa:</Typography>
                </DatePickerComponent>
            </Grid>
            <Grid item xs={12} lg={6} p={2} className="dynamicLeftBorder borderTopContainer">
                <FormControl fullWidth>
                    <Typography variant="subtitle1">{`Numer serii leku: ${duopopaDrugTherapyModule.isFamiliarSeries === 'Tak' ? '*' : ''}`}</Typography>
                    <OutlinedInput
                        disabled={duopopaDrugTherapyModule.isFamiliarSeries === 'Nie'}
                        required={duopopaDrugTherapyModule.isFamiliarSeries === 'Tak'}
                        id="serialNumberDrug"
                        name="serialNumberDrug"
                        value={duopopaDrugTherapyModule.startPeg}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
                    />
                </FormControl>
            </Grid>
        </Grid>
    {
     duopopaDrugTherapyModule.pump &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12}>
          <p className="subMenuHeader">pompa</p>
         </Grid>
         <Grid item p={2} container justifyContent="center">
          <Grid item xs={12} lg={6}>
              {/*<FormControl fullWidth required>*/}
              {
                  isPatientSelected
                      ? duopopaDrugTherapyModule.pumpSNo !== null
                          ? <Typography variant="subtitle1">Numer seryjny pompy: {duopopaDrugTherapyModule.pumpSNo}</Typography>
                          : <Typography color="red">Pompa nie została przypisana do pacjenta.</Typography>
                      : <Typography color="red">Nie wybrano pacjenta.</Typography>
              }
          </Grid>
         </Grid>
        </Grid>
    }
    {
     duopopaDrugTherapyModule.nj &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12}>
          <p className="subMenuHeader">sonda NJ</p>
         </Grid>
         <Grid item p={2} container justifyContent="center">
          <Grid item xs={12} lg={6}>
           <DatePickerComponent
            required
            disableFuture
            allowSameDateSelection
            value={duopopaDrugTherapyModule.startNJ}
            onChange={(newValue) => {
             duopopaDrugTherapyHandler('startNJ', newValue);
            }}
           >
            <Typography variant="subtitle1" mr={3}>Data założenia sondy NJ:</Typography>
           </DatePickerComponent>
          </Grid>
         </Grid>
        </Grid>
    }
    {
     duopopaDrugTherapyModule.peg &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12} lg={6} container>
          <Grid item xs={12}>
           <p className="subMenuHeader">sonda PEG</p>
          </Grid>
          <Grid item p={2} container justifyContent="center">
           <Grid item xs={12}>
            <DatePickerComponent
             required
             disableFuture
             allowSameDateSelection
             value={duopopaDrugTherapyModule.lastInstallPeg}
             onChange={(newValue) => {
              duopopaDrugTherapyHandler('lastInstallPeg', newValue);
             }}
            >
             <Typography variant="subtitle1" mr={3}>Data ostatniej wymiany sondy
              PEG:</Typography>
            </DatePickerComponent>
           </Grid>
          </Grid>
         </Grid>
         <Grid item xs={12} lg={6} p={2} container alignItems="flex-end" className="dynamicLeftBorder">
          <Grid item xs={12}>
           <FormControl fullWidth required>
            <Typography variant="subtitle1">Numer serii obecnej sondy PEG:</Typography>
            <OutlinedInput
             id="pegSNo"
             name="pegSNo"
             value={duopopaDrugTherapyModule.pegSNo}
             onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
            />
           </FormControl>
          </Grid>
         </Grid>
        </Grid>
    }
    {
     duopopaDrugTherapyModule.jejunum &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12} lg={6} container>
          <Grid item xs={12}>
           <p className="subMenuHeader">sonda jelitowa</p>
          </Grid>
          <Grid item p={2} container justifyContent="center">
           <Grid item xs={12}>
            <DatePickerComponent
             required
             disableFuture
             allowSameDateSelection
             value={duopopaDrugTherapyModule.lastInstallJejunum}
             onChange={(newValue) => {
              duopopaDrugTherapyHandler('lastInstallJejunum', newValue);
             }}
            >
             <Typography variant="subtitle1" mr={3}>Data ostatniej wymiany sondy
              jelitowej:</Typography>
            </DatePickerComponent>
           </Grid>
          </Grid>
         </Grid>
         <Grid item xs={12} lg={6} p={2} container alignItems="flex-end" className="dynamicLeftBorder">
          <Grid item xs={12}>
           <FormControl fullWidth required>
            <Typography variant="subtitle1">Numer serii obecnej sondy jelitowej:</Typography>
            <OutlinedInput
             id="jejunumSNo"
             name="jejunumSNo"
             value={duopopaDrugTherapyModule.jejunumSNo}
             onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
            />
           </FormControl>
          </Grid>
         </Grid>
        </Grid>
    }
    {
     duopopaDrugTherapyModule.gel &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12}>
          <p className="subMenuHeader">żel / kasetka</p>
         </Grid>
         <Grid item p={2} container justifyContent="center">
          <Grid item xs={12} lg={6}>
           <FormControl fullWidth required>
            <Typography variant="subtitle1">Numer serii żelu / kasetki:</Typography>
            <OutlinedInput
             id="gelSNo"
             name="gelSNo"
             value={duopopaDrugTherapyModule.gelSNo}
             onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
            />
           </FormControl>
          </Grid>
         </Grid>
        </Grid>
    }
    {
     duopopaDrugTherapyModule.anotherProduct &&
        <Grid item container borderTop={`1px solid #071d49`}>
         <Grid item xs={12}>
          <p className="subMenuHeader">inny</p>
         </Grid>
         <Grid item container>
          <Grid item xs={12} lg={2} p={2}>
           <Typography variant="subtitle1">Wymienione złącze:</Typography>
           <RadioGroup
            name="anotherProductReload"
            value={duopopaDrugTherapyModule.anotherProductReload}
            onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
           >
            <FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
            <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
           </RadioGroup>
          </Grid>
          <Grid item xs={12} lg={5} p={2} container alignItems="center">
           <FormControl fullWidth required>
            <Typography variant="subtitle1">Numer serii:</Typography>
            <OutlinedInput
             name="anotherProductSNo"
             value={duopopaDrugTherapyModule.anotherProductSNo}
             onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
            />
           </FormControl>
          </Grid>
          <Grid item xs={12} lg={5} p={2} container alignItems="center">
           <FormControl fullWidth required>
            <Typography variant="subtitle1">Akcesoria do noszenia / inne – numer serii</Typography>
            <OutlinedInput
             name="anotherProductAccessoriesNo"
             value={duopopaDrugTherapyModule.anotherProductAccessoriesNo}
             onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
            />
           </FormControl>
          </Grid>
         </Grid>
        </Grid>
    }
    <Grid item xs={12} p={2} borderTop={`1px solid #071d49`} container alignItems="center"
          justifyContent="center" direction="column">
        <Typography variant="subtitle1" mr={3}>{`Jeżeli numer serii jest nieznany, należy zaznaczyć, z jakiego powodu: ${duopopaDrugTherapyModule.isFamiliarSeries === 'Nie' ? '*' : ''}`}</Typography>
        <FormGroup>
            <Stack direction="column" spacing={1}>
                <FormControlLabel control={
                    <Checkbox
                        name="noPackage"
                        checked={duopopaDrugTherapyModule.noPackage}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.checked)}
                    />}
                                  label="osoba zgłaszająca nie posiada informacji na temat numeru serii, ponieważ (np. opakowanie produktu zostało wyrzucone)"/>
                <FormControlLabel control={
                    <Checkbox
                        name="noAnswer"
                        checked={duopopaDrugTherapyModule.noAnswer}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.checked)}
                    />
                } label="osoba zgłaszająca odmówiła podania informacji na temat numeru serii"/>
                <FormControlLabel control={
                    <Checkbox
                        name="noContact"
                        checked={duopopaDrugTherapyModule.noContact}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.checked)}
                    />
                }
                                  label="nie udało się skontaktować z osobą zgłaszającą w celu uzyskania informacji na temat numeru serii"/>
                <FormControlLabel control={
                    <Checkbox
                        name="noContactInfo"
                        checked={duopopaDrugTherapyModule.noContactInfo}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.checked)}
                    />
                } label="dane kontaktowe osoby zgłaszającej były niedostępne"/>
                <FormControlLabel control={
                    <Checkbox
                        name="noRegistration"
                        checked={duopopaDrugTherapyModule.noRegistration}
                        onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.checked)}
                    />
                } label="gabinet lekarski nie prowadzi rejestracji numerów serii"/>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="anotherReasonNS"
                            checked={duopopaDrugTherapyModule.anotherReasonNS}
                            onChange={(e) => {
                             duopopaDrugTherapyHandler(e.target.name, e.target.checked)
                                if (!e.target.anotherReasonNS) {
                                 duopopaDrugTherapyHandler('additionalReasonNS', '')
                                }
                            }}
                        />}
                    label="inny powód (należy podać, jaki)"
                />
                <Box minHeight={56}>
                    {
                     duopopaDrugTherapyModule.anotherReasonNS && <FormControl fullWidth required>
                      <OutlinedInput
                       id="additionalReasonNS"
                       name="additionalReasonNS"
                       value={duopopaDrugTherapyModule.additionalReasonNS}
                       onChange={(e) => duopopaDrugTherapyHandler(e.target.name, e.target.value)}
                      />
                     </FormControl>
                    }
                </Box>
            </Stack>
        </FormGroup>
    </Grid></>)
}