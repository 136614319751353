import {EDIT_OPTION} from '../../../data/consts/modalOptions';

export const patientModalValidate = (patientInfo, pegs, modalOption) => {

	if (patientInfo.nameInitial.length > 1) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wprowadź jedynie inicjały pacjenta',
				},
			}
		})
	}


	if (patientInfo.surnameInitial.length > 1) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wprowadź jedynie inicjały pacjenta',
				},
			}
		})
	}

	if (patientInfo.pesel.length !== 4) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wprowadź 4 ostatnbie cyfry PESEL.',
				},
			}
		})
	}

	if (!patientInfo.institution) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wybierz nazwę instutucji.',
				},
			}
		})
	}

	if(patientInfo.pumps.length > 0){
		const hasNullValue = patientInfo.pumps.some((pump) => {
			return Object.values(pump).includes(null);
		});

		if (hasNullValue) {
			return ({
				isError: true,
				defRes: {
					data: {
						code: 422,
						message: 'Uzupełnij dane wszystkich dodanych pomp.',
					},
				}
			})
		}


	}

	if (!patientInfo.isTherapyInterrupted && !patientInfo.probeInstallDate) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Dodaj datę inicjalizacji sondy.',
				},
			}
		})
	}

	if (pegs.filter(el => !el.installDate).length > 0) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wybierz daty dla PEG',
				},
			}
		})
	}

	if (!patientInfo.initiationDate) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Wybierz datę wdrożenia pacjenta',
				},
			}
		})
	}

	if (patientInfo.isTherapyInterrupted && !patientInfo.therapyLeftDate) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Dodaj datę zerwania terapii.',
				},
			}
		})
	}

	if (modalOption === EDIT_OPTION) {
		if (patientInfo.therapySuspensionReason !== '' || patientInfo.therapySuspensionStartDate !== null || patientInfo.therapySuspensionEndDate !== null) {
			if (patientInfo.therapySuspensionReason === '' || patientInfo.therapySuspensionStartDate === null || patientInfo.therapySuspensionEndDate === null) {
				return ({
					isError: true,
					defRes: {
						data: {
							code: 422,
							message: 'Aby zatwierdzić zawieszenie terapii uzupełnij wszystkie dane',
						},
					}
				})
			}
		}
	}

	if (modalOption === EDIT_OPTION && patientInfo.therapyLeftDate !== null && patientInfo.therapyLeftReason === '') {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Aby zatwierdzić wyłączenie z terapii wybierz przyczynę',
				},
			}
		})
	}

	if (modalOption !== 'edit' && !patientInfo.isDataProcessAgreement) {
		return ({
			isError: true,
			defRes: {
				data: {
					code: 422,
					message: 'Aby zapisać formularz należy przekazać informację o przetwarzaniu danych osobowych do pacjenta / opiekuna i zaznaczyć odpowiednią opcję',
				},
			}
		})
	}

	return ({
		isError: false,
		defRes: null
	})
}