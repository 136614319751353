import { useRootStore } from '../RootStateContext';

export const useAlertStore = () => {
  const rootStore = useRootStore();

  if (!rootStore.alertStore) {
    throw new Error('Alert store should be defined');
  }

  return rootStore.alertStore;
}