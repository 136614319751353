import {useCallback} from 'react';
import {useAlertStore} from "../store/hooks";
import {useApiCall} from "./apiCall/useApiCall";

export const useAlertApi = () => {
  const { api } = useApiCall();
  const alertStore = useAlertStore();

  const getAlerts = useCallback(async () => {
    const res = await api.apiInstance.get('alerts', true)

    if (res) {
      alertStore.setAlerts(res);
    }
  }, [api.apiInstance, alertStore])


  return {
    getAlerts
  }
}