import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import Search from '../../../../../shared/search/Search';
import { useOrderTablesViewModel } from './useOrderTables.vm';
import { useTableController } from '../../../../../../hooks/useTableController';
import { EnhancedTableHead} from '../../../../../../utils/sortingTable';
import { WAREHOUSE_KEEPER_ORDER_STATUSES } from '../warehouseKeeperOrderStutuses';
import { SubTable } from './subTable/SubTable';

const HEAD_CELLS = [
	{
		id: 'institutionName',
		label: 'Nazwa szpitala',
	},
	{
		id: 'createdBy',
		label: 'Zamówione przez',
	},
	{
		id: 'createdAt',
		label: 'Data zamówienia',
	},
	{
		id: 'status',
		label: 'Status',
	}
]

export const OrdersTable = observer(() => {

	const [openedSubTables, setOpenedSubTables] = useState([])

	const { warehouseKeeperOrders } = useOrderTablesViewModel()

	const {
		drawArray,
		count,
		searchTerm,
		page,
		rowsPerPage,
		order,
		orderBy,
		filters,
		filterChangeHandler,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange,
		setFilteredData
	} = useTableController(warehouseKeeperOrders, ['status', 'institutionName', 'createdBy'], {
		orderBy: 'status',
		filters: [{
			name: 'status',
			values: Object.values(WAREHOUSE_KEEPER_ORDER_STATUSES),
			selectedValue: WAREHOUSE_KEEPER_ORDER_STATUSES.IN_PROGRESS,
		}]
	})

	useEffect(() => {
		setFilteredData(warehouseKeeperOrders.filter((patient) => {
			return filters.every((filter) => {
				return filter.selectedValue === '' || filter.selectedValue === patient[filter.name];
			})
		}))
	}, [filters, warehouseKeeperOrders, setFilteredData]);

	const subTableSwitcher = (subTableIndex) => {
		setOpenedSubTables(prevState => {
			if (prevState.includes(subTableIndex)) {
				return prevState.filter((index) => index !== subTableIndex)
			} else {
				return [...prevState, subTableIndex]
			}
		})
	}

	return (
		<Grid container flexDirection="column" mt={2}>
			<Grid container justifyContent="flex-end" mb={2}>
				<Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
			</Grid>
			<TableContainer>
				<Table>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={warehouseKeeperOrders.length}
						headCells={HEAD_CELLS}
						filters={filters}
						onFilterChange={filterChangeHandler}
						customSelectStyle={{ width: '200px' }}
					/>
					{
						drawArray.length > 0
							? (
								<>
									<TableBody>
										{
											drawArray.map((order, index) => (
												<Fragment key={index}>
													<TableRow
														hover
														role="row"
														tabIndex={-1}
														onClick={() => subTableSwitcher(index)}
														style={{ cursor: 'pointer' }}
													>
														<TableCell>
															{order.institutionName}
														</TableCell>
														<TableCell align="center">
															{order.createdBy}
														</TableCell>
														<TableCell align="center">
															{order.createdAt}
														</TableCell>
														<TableCell align="center">
															{order.status}
														</TableCell>
													</TableRow>
													{
														openedSubTables.includes(index)
															?
															<TableRow>
																<TableCell colSpan={4} sx={{ padding: '0 8px 2px' }}>
																	<SubTable order={order}/>
																</TableCell>
															</TableRow>
															: null
													}
												</Fragment>
											))
										}
									</TableBody>
								</>
							)
							: (
								<TableBody>
									<TableRow>
										<TableCell colSpan={4}>
											<Typography textAlign="center">
												Brak danych do wyświetlenia...
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							)
					}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				labelRowsPerPage={'Elementy na stronie'}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Grid>
	)
})