import React, { useContext } from "react";
import { AuthContext, ReckoningsContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ROLE_ADMIN } from "../../../../data/consts/users";
import { ACCEPTED_STATUS } from "../../../../data/consts/settlementsStatuses";
import { getActionHelper } from "./helpers";

export const SettlementsMonthCell = ({ monthInfo, setShowReckoningGenerated }) => {

	const { role } = useContext(AuthContext);
	const { generateReckoning } = useContext(ReckoningsContext);

	const navigate = useNavigate();

	const {
		status,
		canWriting,
		year,
		month,
		monthPolish,
		reckoningId,
		isClosed,
		isEmpty,
		isAccepted
	} = monthInfo;

	const action = getActionHelper(status, isEmpty, isAccepted, role === ROLE_ADMIN && isClosed)

	const handleClick = () => {

		const yearMonth = `${year}-${month}`;

		// TODO: check and delete
		// if (role === ROLE_ADMIN) {
		// 	navigate(yearMonth, { replace: true, state: { monthInfo } });
		// } else {
		// 	if (status !== ACCEPTED_STATUS) {
		// 		navigate(yearMonth, { replace: true, state: { monthInfo } });
		// 	} else {
		// 		generateReckoning(reckoningId);
		// 		setShowReckoningGenerated(true);
		// 	}
		// }

		if (role === ROLE_ADMIN || status !== ACCEPTED_STATUS) {
			navigate(yearMonth);
		} else {
			generateReckoning(reckoningId);
			setShowReckoningGenerated(true);
		}
	};

	return (
		<li className={`settlements__list-item`}>
			<Typography
				className={`settlements__list-item__text`}>{month} {monthPolish}</Typography>
			{
				canWriting && (
					<>
						<Button
							className={'buttonCustom'}
							color={action.buttonColor}
							variant={'contained'}
							onClick={() => handleClick()}
							disabled={action.disabled}
						>
							{action.content}
						</Button>
						<Box
							sx={{ visibility: action.status.length ? 'visible' : 'hidden' }}
							className={`settlements__list-item__status`}>
							<Typography>Status:</Typography>
							<Typography sx={{ color: action.color }}>{action.status}</Typography>
						</Box>
					</>
				)
			}
		</li>
	)
}