import React, {useContext, useReducer} from 'react';
import {OrderContext} from './orderContext';
import {orderReducer} from "./orderReducer"
import axios from "../../axios/axios";
import {PoiContext} from "../poi/poiContext";
import {GET_ORDER, GET_ORDERS_INFO,} from "../types";

export const OrderState = ({children}) => {
    const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);

    const initialState = {
        ordersList: null,
        orderInfo: null,
    }


    const [state, dispatch] = useReducer(orderReducer, initialState);

    const getOrdersList = async () => {
        setGlobalLoader()
        try {
            await axios.get(
                `orders`,
            ).then(res => {
                dispatch({type: GET_ORDERS_INFO, payload: res.data})

                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const addHardwareOrder = async (data) => {
        try {
            await axios.post(
                'order',
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 0,
                        message: 'Zamówienie zostało dodane',
                    },
                }
                setModalError(defRes);
                getOrdersList()
                return true
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const editHardwareOrder = async (orderId, data) => {
        try {
            await axios.put(
                `/order/${orderId}`,
                data,
            ).then(res => {
                unsetGlobalLoader();
                let defRes = {
                    data: {
                        code: 0,
                        message: 'Zamówienie zostało zaktualizowane',
                    },
                }
                setModalError(defRes);
                getOrdersList()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response);
        }
    }

    const deleteOrder = async (orderID) => {
        setGlobalLoader()
        try {
            await axios.delete(
                `/order/${orderID}`,
            ).then(res => {
                unsetGlobalLoader();
                getOrdersList()

                let defRes = {
                    data: {
                        code: 0,
                        message: 'Zamówienie zostało usunięte',
                    },
                }
                setModalError(defRes);
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const getOrder = async (orderID) => {
        setGlobalLoader()
        try {
            await axios.get(
                `/order/${orderID}`,
            ).then(res => {
                dispatch({type: GET_ORDER, payload: res.data})
                unsetGlobalLoader()
            })
        } catch (e) {
            unsetGlobalLoader();
            setModalError(e.response)
        }
    }

    const acceptOrder = async (orderID) => {
        setGlobalLoader()
        try {
            await axios.post(
                `order/${orderID}/accept`,
                null,
            ).then(res => {
                unsetGlobalLoader();
                getOrdersList()
                let defRes = {
                    data: {
                        code: 0,
                        message: "Zamówienie zostało zaakceptowane"

                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }
    const proceedOrder = async (orderID) => {
        setGlobalLoader()
        try {
            await axios.post(
                `order/${orderID}/done`,
                null,
            ).then(res => {
                unsetGlobalLoader();
                getOrdersList()
                let defRes = {
                    data: {
                        code: 0,
                        message: "Zamówienie oznaczone jako wykonane"
                    },
                }
                setModalError(defRes)
            })
        } catch (e) {
            unsetGlobalLoader()
            setModalError(e.response)
        }
    }

    return (
        <OrderContext.Provider value={{
            orderState: state,
            getOrdersList,
            deleteOrder,
            addHardwareOrder,
            getOrder,
            acceptOrder,
            proceedOrder,
            editHardwareOrder
        }}>
            {children}
        </OrderContext.Provider>
    )

}