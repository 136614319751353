import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, ImageList, ImageListItem, Stack, TextField, Typography, } from '@mui/material';
import ProductSubModal from '../hardwareOrders/ProductSubModal';
import basket from '../../../assets/img/ui/basket.png';
import { HardwareContext, PoiContext } from '../../../context';
import { HardwareModalValidate } from '../../../utils/hardwareModalValidate';

const HardwareProductsModal = ({ closeModal, modalOption, productID, openModal }) => {

	const HOST = process.env.REACT_APP_HOST;

	const { hardwareState, addProduct, editProduct, getProduct} = useContext(HardwareContext);
	const { setModalError } = useContext(PoiContext)


	const [formValue, setFormValue] = useState({
		productName: '',
		package: '',
	})
	const [productData, setProductData] = useState([])
	const [files, setFiles] = useState([]);
	const [images, setImages] = useState([])
	const [imagesDeleted, setImagesDeleted] = useState([])
	const [activeDropZone, setActiveDropZone] = useState(false);


	useEffect(() => {
		if (modalOption === 'show') {
			getProduct(productID)
		}
		if (modalOption === 'edit') {
			getProduct(productID)
		}
		// eslint-disable-next-line
	}, [modalOption])

	useEffect(() => {
		hardwareState.hardwareInfo && hardwareState.hardwareInfo.images.length && setImages([...hardwareState.hardwareInfo.images])
	}, [hardwareState.hardwareInfo])

	useEffect(() => {

			if (modalOption !== 'add' && hardwareState.hardwareInfo) {
				setFormValue({
					productName: hardwareState.hardwareInfo.name,
					package: hardwareState.hardwareInfo.package,
				})
				let tempArray = [];
				hardwareState.hardwareInfo.details.forEach(el => tempArray.push({
					productCode: el.code,
					productCatalogName: el.name,
					productSize: el.size,
				}))
				setProductData([...tempArray])
			}
		}, [hardwareState, modalOption]
	)
	const formValueHandler = (name, value) => setFormValue(prev => ({ ...prev, [name]: value }))
	const handlerID = (productID) => {
		setImages(prev => prev.filter(el => el.id !== productID))
		setImagesDeleted(prev => ([...prev, productID]))
	}
	// eslint-disable-next-line
	const delFileHandler = (index) => setFiles(prev => [...prev.filter((el, i) => i !== index)])

	const onFilesInputChangeHandle = (event) => {
		event.preventDefault()
		setActiveDropZone(true)
		setFiles(prev => ([...prev, ...event.target.files]))
	}
	const handleDrop = (event) => {
		event.preventDefault();
		setFiles(prev => [...prev, ...event.dataTransfer.files])
	}

	const productTableHandler = (name, value, el, index) => {
		const tempArray = [];
		productData.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setProductData([...tempArray])
	}

	const addProductTableRow = () => setProductData(prev => ([...prev, {
		productCode: '',
		productCatalogName: '',
		productSize: '',
	}]))

	const delProductTableRow = (index) => setProductData(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const submitHandler = (e) => {
		e.preventDefault()
		const validation = HardwareModalValidate(formValue, productData, files)
		if (validation.isError) {
			setModalError(validation.defRes);
		} else {
			const data = new FormData()
			data.append('name', formValue.productName);
			data.append('package', formValue.package);

			if (!!files.length) {
				files.forEach((el, index) => data.append(`images[${index}]`, el))
			}
			if (!!imagesDeleted.length) {
				imagesDeleted.forEach((el, index) => data.append(`imagesDeleted[${index}]`, el))
			}
			productData.forEach((el, index) => {
				data.append(`details[${index}][code]`, el.productCode)
				data.append(`details[${index}][name]`, el.productCatalogName)
				data.append(`details[${index}][size]`, el.productSize)
			})
			if (modalOption === 'add') {
				addProduct(data);
				closeModal()
			}
			if (modalOption === 'edit') {
				editProduct(productID, data)
				closeModal()
			}
		}
	}

	return (
		<div className="customModal">
			<Stack component="div" className="customModal__addMenu">
				<Typography variant="h4" mb={2}>
					{modalOption === 'add' ? 'Nowy produkt:' : modalOption === 'show' ? 'Informację o produkcie:' : 'Edytuj produkt'}
				</Typography>
				<form onSubmit={(e) => submitHandler(e)}>
					<Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
					      className="customModal__scrollArea">
						<Grid item>
							<FormControl fullWidth>
								<TextField
									required
									label="Nazwa produktu"
									name="productName"
									disabled={(modalOption === 'show')}
									value={formValue.productName}
									onChange={(e) => formValueHandler(e.target.name, e.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl fullWidth>
								<TextField
									required
									label="Minimum logistyczne"
									disabled={(modalOption === 'show')}
									name="package"
									value={formValue.package}
									onChange={(e) => formValueHandler(e.target.name, e.target.value)}
								/>
							</FormControl>
						</Grid>
						{modalOption !== 'add' && !!images.length &&
							<Grid item alignItems={'center'}>
								<Grid
									container
									spacing={0}
								>
									<ImageList gap={8} variant={'standard'}
									           sx={{ marginLeft: images.length < 2 ? 7 : '' }}
									           cols={images.length > 2 ? 3 : images.length} rowHeight={'auto'}>
										{modalOption !== 'add' && !!images.length &&
											images.map((el => (
												<ImageListItem key={el.id} sx={{ position: 'relative' }}>
													<img
														src={`${HOST}/uploads/products/${el.image}?w=164&h=164&fit=crop&auto=format`}
														loading="lazy"
														alt="product images" key={el.id}
													/>
													{modalOption !== 'show' &&
														<Box onClick={() => {
															handlerID(el.id)
														}
														} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
															<img src={basket} alt={'remove Icon'}
															/>
														</Box>}
												</ImageListItem>
											)))}
									</ImageList>
								</Grid>
							</Grid>
						}
						<ProductSubModal
							modalOption={modalOption}
							productData={productData}
							delProductTableRow={delProductTableRow}
							productTableHandler={productTableHandler}
							addProductTableRow={addProductTableRow}/>
						{modalOption !== 'show' &&
							<Grid item
							      alignItems="center"
							      justifyContent="space-between"
							      flexDirection={'column'} mt={2}>
								<Grid container bgcolor={'#F5F5F5'} p={2} alignItems="center">
									<Typography variant="subtitle1">Dodaj zdjęcia produktu:</Typography>
								</Grid>
								<Grid item mt={2}>
									<Box
										component="div"
										width={'100%'}
										className={`dropZone ${activeDropZone ? 'activeZone' : ''}`}
										onDragOver={event => {
											setActiveDropZone(true)
											event.preventDefault()
										}}
										onDragLeave={event => {
											setActiveDropZone(false)
											event.preventDefault();
										}}
										onDrop={event => {
											event.preventDefault();
											handleDrop(event)
										}}
									>
										<ol>
											{
												files.length !== 0
													?
													files.map((el, index) => (
														<li key={index}>
															<span>{index + 1}. </span>
															<p>{el.name.length > 40 ? `${el.name.slice(0, 15)} ... ${el.name.slice(-10)}` : el.name} ({(el.size / 1000000).toFixed(2)}Mb)</p>
															<img
																onClick={() => delFileHandler(index)}
																src={basket}
																alt="basket"
															/>
														</li>
													))
													:
													null
											}
										</ol>
										<label className="addFile-label" htmlFor="addFileButton">
											<div className="addFile-img" />
											<input
												id="addFileButton"
												className="addFile-input"
												type="file"
												name="files"
												multiple
												onChange={(event) => onFilesInputChangeHandle(event)}
												onClick={(event) => event.target.value = null}
											/>
											<span>wybierz plik z obrazem (maks. rozmiar 2 MB)</span>
										</label>
									</Box>
								</Grid>
							</Grid>}
					</Grid>
					<div
						className={`customModal__addMenu-buttonArea ${modalOption === 'show' ? 'alignButton' : ''} `}>
						<Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
						{
							modalOption !== 'show' &&
							<Button
								type="submit"
								color="primary" variant="contained">
								{modalOption === 'add' ? 'Dodaj' : 'Zapisz'}
							</Button>
						}
					</div>
				</form>
			</Stack>
		</div>
	)
}

export default HardwareProductsModal;

