//POI TYPES
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const UNSET_GLOBAL_LOADER = 'UNSET_GLOBAL_LOADER';
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR';
export const UNSET_MODAL_ERROR = 'UNSET_MODAL_ERROR';

//INTERACTIONS
export const GET_INTERACTIONS = 'GET_INTERACTIONS';
//KNOWLEDGE BASE
export const GET_KNOWLEDGE_ITEMS = 'GET_KNOWLEDGE_ITEMS';
//Cert
export const GET_DOCUMENT = 'GET_DOCUMENT';

//EXTRA WORKING TIME
export const GET_WORKING_HOURS = 'GET_WORKING_HOURS';

//MESSAGES TYPES
export const GET_MESSAGES_LIST = 'GET_MESSAGES_LIST'
export const GET_MESSAGES_RECEIVERS = 'GET_MESSAGES_RECEIVERS'

//AUTH TYPES
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_USER = 'AUTH_USER';

//APP TYPES
export const GET_ADDITIONAL_ADVERSE_INFO = 'GET_ADDITIONAL_ADVERSE_INFO';
export const GET_ADDITIONAL_CREATE_USER_INFO = 'GET_ADDITIONAL_CREATE_USER_INFO';
export const GET_USERS = 'GET_USERS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS';
export const GET_INSTITUTION_INFO = 'GET_INSTITUTION_INFO';
export const GET_USERS_FROM_INSTITUTIONS = 'GET_USERS_FROM_INSTITUTIONS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_CERTIFICATES = 'GET_CERTIFICATES';
export const CLEAR_CERTIFICATES = 'CLEAR_CERTIFICATES';

//PATIENT TYPES
export const GET_ADDITIONAL_PATIENT_INFO = 'GET_ADDITIONAL_PATIENT_INFO';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT_INFO = 'GET_PATIENT_INFO';
export const CLEAR_PATIENTS = 'CLEAR_PATIENTS';

//PRODUCT TYPES
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const CLEAR_PATIENT = 'CLEAR_PATIENT';

//SETTLEMENTS TYPES
export const GET_SETTLEMENTS_INFO = 'GET_SETTLEMENTS_INFO';
export const GET_SETTLEMENTS_LIST = 'GET_SETTLEMENTS_LIST';
export const GET_SETTLEMENTS_DATES = 'GET_SETTLEMENTS_DATES';
export const CLEAR_SETTLEMENTS_STATE = 'CLEAR_SETTLEMENTS_STATE';
export const SET_GLOBAL_SETTLEMENT_PERIOD = 'SET_GLOBAL_SETTLEMENT_PERIOD';
export const SETTLEMENT_COMPLETED = 'SETTLEMENT_COMPLETED';
export const SETTLEMENT_CONFIRMED = 'SETTLEMENT_CONFIRMED';

//HARDWARE TYPES
export const GET_HARDWARE_LIST = 'GET_HARDWARE_LIST';
export const GET_HARDWARE_INFO = 'GET_HARDWARE_INFO';
export const CLEAR_HARDWARE_STATE = 'CLEAR_HARDWARE_STATE';

//ORDER TYPES
export const GET_ORDERS_INFO = 'GET_ORDERS_INFO';
export const GET_ORDER = 'GET_ORDER';

//RECKONINGS TYPES
export const GET_RECKONINGS = 'GET_RECKONINGS';
export const GET_RECKONING = 'GET_RECKONING';
export const CLEAR_RECKONINGS_STATE = 'CLEAR_RECKONINGS_STATE';
export const RECKONING_ACCEPTED = 'RECKONING_ACCEPTED';
export const RECKONING_REJECTED = 'RECKONING_REJECTED';
export const RECKONING_HANDLED = 'RECKONING_HANDLED';
export const RECKONING_REPORTS_GENERATED = 'RECKONING_REPORTS_GENERATED';

//REPORTS TYPES
export const GET_WAREHOUSE_STATISTIC = 'GET_WAREHOUSE_STATISTIC';