import React from 'react';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useActionCellViewModel } from './useActionCell.vm';
import { ActionCellModal } from './ActionCellModal';

export const ActionCell = ({ status, pumpId }) => {

	const { currentAction, actionsArray, changeActionHandler, buttonClickHandler, isModalOpen, closeModalHandler } = useActionCellViewModel(status, pumpId)

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="flex-end"
			flexWrap="nowrap"
		>
			{
				actionsArray.length > 0 && currentAction !== null
					? (
						<>
							<Select
								size="small"
								name="action"
								value={currentAction}
								onChange={changeActionHandler}
								sx={{ width: '200px' }}
							>
								{
									actionsArray.map(action => (
										<MenuItem key={action.id} value={action.id}>
											{action.label}
										</MenuItem>
									))
								}
							</Select>
							<Button
								className="formButton"
								variant="contained"
								color="success"
								onClick={buttonClickHandler}
							>
								zatwierdź
							</Button>
						</>
					)
					: (
						<Typography textAlign="center" width='100%'>
							brak akcji
						</Typography>
					)
			}
			<ActionCellModal isModalOpen={isModalOpen} closeModalHandler={closeModalHandler} pumpId={pumpId}/>

		</Grid>
	)
}