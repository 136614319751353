import React, {useEffect, useState} from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem, Radio,
  RadioGroup,
  Select,
  Typography
} from '@mui/material';
import {DatePickerComponent} from '../ui/DatePickerComponent';
import {CHANGE, changePumpsStatus, CLEAR, DISABLED, statusToName} from './helper';

export const AddPump = ({isEdit, availablePumps,  pumps, addPumps, initialPumps= []}) => {
  const [changePumps, setChangePumps] = useState(changePumpsStatus(availablePumps, pumps));

  useEffect(()=> {
    setChangePumps(changePumpsStatus(availablePumps, pumps))
    // eslint-disable-next-line
  }, [availablePumps.length])

  const handleAddPumps = (pump, position) => {
    const newPumpsState = [...pumps];
    newPumpsState[position] = {...pump};
    addPumps([...newPumpsState])
  }

  const handleRemovePump = (position) => {
    const newPumpsState = [...pumps];
    newPumpsState.splice(position, 1);
    addPumps([...newPumpsState]);
  }

  const handleAddPump = () => {
    const newPumpsState = [...pumps];
    newPumpsState.push({id: null, date: null});
    addPumps(newPumpsState)
  }

  const handleEditButton = (position) => {
    if(initialPumps.length >= (position+1) && changePumps[position] === CLEAR){
      handleAddPumps( {
        id: initialPumps[position].id,
        serialNumber: initialPumps[position].serialNumber
      }, position);
    }

    setChangePumps(prevState => changePumpsStatus(availablePumps, pumps, prevState, position))
  }

  const handleChangeReason = (value, position) =>  {
    handleAddPumps( {
      id: pumps[position]?.id ?? null,
      date: pumps[position]?.date ?? null,
      changePumpId: initialPumps[position].id,
      changePumpType: value,
    }, position);
  }

  const handleChangeDate = (date, position) => {
    if(isEdit && initialPumps.length >= (position+1)){
      handleAddPumps( {
        id: pumps[position]?.id ?? null,
        date: date,
        changePumpId: initialPumps[position].id,
        changePumpType: pumps[position]?.changePumpType ?? null,
      }, position);
    }
    else{
      handleAddPumps( {
        id: pumps[position]?.id ?? null,
        date
      }, position);
    }
  }

  const handleSelectPump = (value, position) => {
    const newPump = availablePumps.find(pump => pump.id === value);

    if(isEdit && initialPumps.length >= (position+1)){
      handleAddPumps( {
        id: newPump.id,
        date: pumps[position]?.date ?? null,
        changePumpId: initialPumps[position].id,
        changePumpType: pumps[position]?.changePumpType ?? null,
      }, position);
    }
    else{
      if(newPump){
        handleAddPumps( {
          id: newPump.id,
          date: pumps[position]?.date ?? null
        }, position);
      }
    }
  }

  const secondEdit = isEdit && initialPumps.length >= 2;
  const firstEdit = isEdit && initialPumps.length >= 1;
  const firstPumpExist = pumps.length >= 1 || firstEdit;
  const secondPumpExist = pumps.length >= 2 || secondEdit;
  const firstDisabled = firstEdit && (changePumps[0] === DISABLED || changePumps[0] === CHANGE);
  const secondDisabled = secondEdit && (changePumps[1] === DISABLED || changePumps[1] === CHANGE);
  const firstPumpDate = pumps[0] ? (!firstDisabled ? pumps[0]?.date : null) : null;
  const secondPumpDate = pumps[1] ? (!secondDisabled ? pumps[1]?.date : null) : null;
  const disabledAddedPumpButton = !((pumps.length === 0 && availablePumps.length >= 1) || (pumps.length === 1 && availablePumps.length >= 1));

  return (
    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
      {firstPumpExist && <>
        {firstEdit && <>
          <Grid item xs={12} lg={12} mt={2}>{`Pierwsza pompa przypisana do pacjenta (SN): ${initialPumps[0].serialNumber}`}</Grid>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={10} mt={1} display={'flex'} alignItems={'center'} gap={3}>
          <Typography>POWÓD ZMIANY:</Typography>
          <RadioGroup value={pumps[0]?.changePumpType ?? ''} style={{flexDirection: 'row'}} onChange={(e) => handleChangeReason(e.target.value, 0)}>
            <FormControlLabel disabled={firstDisabled} value="damaged" control={<Radio/>} label="uszkodzona"/>
            <FormControlLabel disabled={firstDisabled} value="other" control={<Radio/>} label="inny powód"/>
          </RadioGroup>
        </Grid>
        <Grid item xs={12} lg={2} mt={2} sx={{pr: {xs: 0, lg: 2}}}>
          <Button
            disabled={changePumps[0] === DISABLED}
            variant="contained"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#228B21' }}
            onClick={() => handleEditButton(0)}
          >
            {statusToName(changePumps[0])}
          </Button>
        </Grid></>}
        <Grid item xs={12} lg={5} mt={2} >
        <FormControl fullWidth>
          <InputLabel id="availablePumps-label" shrink={true}>Wybierz pompę</InputLabel>
          <Select
            labelId="availablePumps-label"
            id="availablePumps"
            name="availablePumps"
            value={pumps.length > 0 ? pumps[0].id : ''}
            disabled={firstDisabled}
            label="Wybierz pompę"
            notched={true}
            displayEmpty
            onChange={(e) => handleSelectPump(e.target.value, 0)}
          >
            {
              availablePumps.map(el => (
                <MenuItem
                  key={el.id}
                  value={el.id}
                  disabled={pumps.some(pump=> pump?.id === el.id)}
                >
                  {el.serialNumber}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
        <Grid item xs={12} lg={5} mt={2} sx={{pl: {xs: 0, lg: 2}}}>
          <DatePickerComponent
            label="Data przypisania"
            disabled={firstDisabled}
            value={firstPumpDate ?? null}
            disableFuture
            onChange={(date) => handleChangeDate(date, 0)}
          />
        </Grid>
         {!firstEdit && <Grid item xs={12} lg={2} mt={2} sx={{pl: {xs: 0, lg: 2}}}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#228B21' }}
            onClick={() => handleRemovePump(0)}
          >
            Usuń
          </Button>
        </Grid>}
      </>}
      {secondPumpExist &&
        <>{secondEdit && <>
          <Grid item xs={12} lg={12} mt={2}>{`Druga pompa przypisana do pacjenta (SN): ${initialPumps[1].serialNumber}`}</Grid>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={10} mt={1} display={'flex'} alignItems={'center'} gap={3}>
          <Typography>POWÓD ZMIANY:</Typography>
          <RadioGroup value={pumps[1]?.changePumpType ?? ''} style={{flexDirection: 'row'}} onChange={(e) => handleChangeReason(e.target.value, 1)}>
            <FormControlLabel disabled={secondDisabled} value="damaged" control={<Radio/>} label="uszkodzona"/>
            <FormControlLabel disabled={secondDisabled} value="other" control={<Radio/>} label="inny powód"/>
          </RadioGroup>
        </Grid>
        <Grid item xs={12} lg={2} mt={2} sx={{pr: {xs: 0, lg: 2}}}>
          <Button
            disabled={changePumps[1] === DISABLED}
            variant="contained"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#228B21' }}
            onClick={() => handleEditButton(1)}
          >
            {statusToName(changePumps[1])}
          </Button>
        </Grid></>}
        <Grid item xs={12} lg={5} mt={2}>
        <FormControl fullWidth>
          <InputLabel id="availablePumps-label" shrink={true}>Wybierz pompę</InputLabel>
          <Select
            labelId="availablePumps-label"
            id="availablePumps"
            name="availablePumps"
            disabled={secondDisabled}
            value={pumps.length > 1 ? pumps[1].id : ''}
            label="Wybierz pompę"
            notched={true}
            displayEmpty
            onChange={(e) => handleSelectPump(e.target.value, 1)}
          >
            {
              availablePumps.map(el => (
                <MenuItem
                  key={el.id}
                  value={el.id}
                  disabled={pumps.some(pump=> pump?.id === el.id)}
                >
                  {el.serialNumber}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
        <Grid item xs={12} lg={5} mt={2} sx={{pl: {xs: 0, lg: 2}}}>
          <DatePickerComponent
            label="Data przypisania"
            disabled={secondDisabled}
            value={secondPumpDate ?? null}
            disableFuture
            onChange={(date) => handleChangeDate(date, 1)}
          />
        </Grid>
       {!secondEdit && <Grid item xs={12} lg={2} mt={2} sx={{pl: {xs: 0, lg: 2}}}>
          <Button
            size="small"
            fullWidth
            color="primary" variant="contained"
            className="buttonCustom"
            onClick={() => handleRemovePump(1)}
          >
            Usuń
          </Button>
        </Grid>}
      </>}
      {(pumps.length !== 2 && availablePumps.length !== 0) && <Grid item xs={12} mt={2} display={'flex'} justifyContent={'center'}>
        <Button
          size="small"
          disabled={disabledAddedPumpButton}
          color="primary" variant="contained"
          onClick={() => !disabledAddedPumpButton && handleAddPump()}
        >
          Dodaj pompę
        </Button>
      </Grid>}
    </Grid>
  )
}