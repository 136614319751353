import React from 'react'
import {Box, Stack, Typography} from '@mui/material';
import InfoIcon from '../../../assets/img/ui/infoIcon.png';

export default function OverTimeWarning() {
    return <Stack color='#D32F2F' direction="row" px={2} py={1} bgcolor='rgba(211, 47, 47, 0.47)' spacing={1} sx={{
        flexGrow: 1,
        borderRadius: '4px',
        top: "1.7rem",
        right: '13rem',
        zIndex: '10',
        maxWidth: '970px',
        margin: '0 auto 1rem',
    }}>
        <Box my={0.8}>
            <img src={InfoIcon} alt=""/>
        </Box>
        <Box pt={0} sx={{width: '100%'}}>
            <Typography mt={0.8}><b>UWAGI DO ROZLICZENIA:</b></Typography>
            <Typography mt={0.5} variant='body2'>
                Wyłącznie <span style={{color: '#fff'}}> zaakceptowane </span> nadgodziny są automatycznie dodawane do miesięcznego rozliczenia, według wskazanej daty wniosku o nadgodziny. W przypadku <span style={{color: '#fff'}}>odrzucenia </span> wniosku o nadgodziny - możesz złożyć wniosek o nadgodziny ponownie, o ile rozliczenie danego miesiąca nie zostało jeszcze wysłane. Wniosek o nadgodziny możesz złożyć wyłącznie przed datą wykonania nadgodzin.
             </Typography>
        </Box>
    </Stack>
}