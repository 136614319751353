import React, {useContext} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import {AppContext} from "../../../../context/app/appContext";
import {
    Paper, Table, Tooltip, Box, TableBody, TableCell, TableContainer, TablePagination, TableRow
} from "@mui/material";
import {EnhancedTableHead} from "../../../../utils/sortingTable";
import Search from "../../../shared/search/Search";
import {useTableController} from "../../../../hooks/useTableController";
import {headCells} from "./helper";

export default function HospitalDataTable({openModal, hospitalIDHandler}) {
    const {appState} = useContext(AppContext);

    const {
        drawArray,
        count,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem
    } = useTableController(appState.institutionsList, ['name', 'zipCode', 'address', 'city', 'voivodeship', 'identifier'], {orderBy: 'name'})

    return (<>
        <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
            <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
        </Box>
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={appState.institutionsList.length}
                        headCells={headCells}
                    />
                    <TableBody>
                        {drawArray.map((item, index) => {
                                const isItemSelected = isSelected(item.id);
                                return (<TableRow
                                    hover
                                    onClick={(event) => handleSelectedItem(event, item.id)}
                                    role="row"
                                    aria-rowindex={isItemSelected}
                                    tabIndex={-1}
                                    key={item.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell align={"center"}>{index + 1 + '.'}</TableCell>
                                    <TableCell align={"left"}>{item.name}</TableCell>
                                    <TableCell align={"center"}>{item.identifier}</TableCell>
                                    <TableCell align={"center"}>{item.address}</TableCell>
                                    <TableCell align={"center"}>{item.zipCode}</TableCell>
                                    <TableCell align={"center"}>{item.voivodeship}</TableCell>
                                    <TableCell align={"center"}>{item.city}</TableCell>
                                    <TableCell align={"center"}>
                                        <ul>
                                            {item.users.map(el => <li key={el.id}>{el.name}</li>)}
                                        </ul>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <div className='addUserTable'>
                                            <Tooltip title="Edytuj" placement="right">
                                                <button onClick={() => {
                                                    hospitalIDHandler(item.id)
                                                    openModal('edit')
                                                }}><EditIcon/>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Wyświetl"  placement="right">
                                                <button onClick={() => {
                                                    hospitalIDHandler(item.id)
                                                    openModal('show')
                                                }}><RemoveRedEyeIcon/>
                                                </button>
                                            </Tooltip>

                                        </div>
                                    </TableCell>
                                </TableRow>)
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Elementy na stronie"}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </>);
}