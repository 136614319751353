import React from 'react';
import {Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";
import {setRadioButtonNotRequired} from "./helper";

const MedicalInterviewsTable = ({
                                    isMedicalInterviewsTable,
                                    addMedicalInterviewsTableRow,
                                    delMedicalInterviewsTableRow,
                                    medicalInterviewsTableHandler,
                                    medicalInterviewsTableToggler,
                                    medicalInterviewsTableValue,
                                }) => {
    return (
        <>
            <Grid border={`1px solid #071d49`} p={2}>
                <Typography variant="subtitle1">Wywiad medyczny:</Typography>
                <RadioGroup
                    aria-labelledby="adverseType-label"
                    name="medicalInterviews"
                    value={isMedicalInterviewsTable}
                    onClick={(e) => medicalInterviewsTableToggler(setRadioButtonNotRequired(e.target.value, isMedicalInterviewsTable))}

                >
                    <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                    <FormControlLabel value="Nie zgłoszono" control={<Radio/>} label="Nie zgłoszono"/>
                    <FormControlLabel value="Tak (opisać poniżej):" control={<Radio/>}
                                      label="Tak (opisać poniżej):"/>
                </RadioGroup>
                <Typography variant="body2" color="#0f5e8d" sx={{fontStyle: 'italic'}}>Wywiad medyczny, chirurgiczny i
                    psychospołeczny oraz informacje na temat palenia tytoniu i spożywania alkoholu.</Typography>
            </Grid>
            {
                isMedicalInterviewsTable === 'Tak (opisać poniżej):' &&
                <>
                    <Grid container border={`1px solid #071d49`} borderTop="none" display="flex" direction="row">
                        <Grid item className="subMenuSidebar">Wywiady</Grid>
                        <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container>
                            {
                                medicalInterviewsTableValue.map((el, index) => (
                                    <Grid key={index} item xs={12} className="formRow">
                                        <Grid item xs={12} container display="flex" direction="row"
                                              justifyContent="space-between">
                                            <p className="subMenuHeader">Wywiad {index + 1}</p>
                                            {
                                                medicalInterviewsTableValue.length > 1 &&
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    className="formButton"
                                                    onClick={() => delMedicalInterviewsTableRow(index)}
                                                >
                                                    usuń lek
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={12} container>
                                            <Grid item xs={12} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Wywiad medyczny -
                                                        termin</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="period"
                                                        value={el.period}
                                                        onChange={(e) => medicalInterviewsTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} p={2}>
                                                <DatePickerComponent
                                                    allowSameDateSelection
                                                    label="Data rozpoczęcia"
                                                    disableFuture
                                                    value={el.dateStart}
                                                    onChange={(newValue) => medicalInterviewsTableHandler('dateStart', newValue, el, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} p={2}>
                                                <DatePickerComponent
                                                    allowSameDateSelection
                                                    label="Data zakończenia"
                                                    disableFuture
                                                    value={el.dateEnd}
                                                    onChange={(newValue) => medicalInterviewsTableHandler('dateEnd', newValue, el, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Dodatkowe informacje</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="additionalInfo"
                                                        value={el.additionalInfo}
                                                        onChange={(e) => medicalInterviewsTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained" color="success" className="formButton"
                                onClick={addMedicalInterviewsTableRow}>dodaj
                            wywiad</Button>
                    </Grid>
                </>
            }
        </>
    )
}

export default MedicalInterviewsTable;