import React, { useContext, useEffect, useState } from 'react';
import PageTitle from '../../../shared/ui/PageTitle';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import AdminAddCertificateModal from './AdminAddCertificateModal';
import { AppContext } from '../../../../context/app/appContext';
import AdminCertificate from './AdminCertificate';
import certificateImg from '../../../../assets/img/ui/certyfikat.svg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminDeleteCertificateModal } from './AdminDeleteCertificateModal';
import { initDeleteModalObject } from './helper';
import { ROLE_ADS } from '../../../../data/consts/users';

const AdminCertification = () => {

	const { appState: { certificates }, clearCertificateList, getCertificateList } = useContext(AppContext);

	const [openCertificateId, setOpenCertificateId] = useState(null);
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(initDeleteModalObject);
	const [isEditModalOpen, setEditModalOpen] = useState(null);

	useEffect(() => {
		getCertificateList();
		return () => clearCertificateList();
		// eslint-disable-next-line
	}, [])

	const addModalToggler = () => setAddModalOpen(prev => !prev)
	const openDeleteModal = (id, title) => setDeleteModalOpen({ isOpen: true, id, title })
	const closeDeleteModal = () => setDeleteModalOpen(initDeleteModalObject)
	const editModalToggler = (certificateInfo = null) => setEditModalOpen(certificateInfo)


	return (
		<>
			<div className="certification">
				<PageTitle title="Certyfikaty:"/>
				<Grid container justifyContent="flex-end" pb={1}>
					<Button
						color="primary" variant="contained"
						className="formButton"
						onClick={addModalToggler}
					>
						dodaj certyfikat
					</Button>
				</Grid>

			</div>
			{
				certificates && !!certificates.length
				&& certificates.map(certificate => (
					<Grid container key={certificate.id} p={1}>
						<Grid
							item
							xs={12}
							container
							display="row"
							wrap="nowrap"
							p={1}
							alignItems="center"
							sx={{ cursor: 'pointer' }}
							bgcolor="#E0E0E2"
							onClick={() => openCertificateId === certificate.id ? setOpenCertificateId(null) : setOpenCertificateId(certificate.id)}
						>
							<Grid item>
								<img src={certificateImg} alt="ava" className="settlements__blockImage_XL"/>
							</Grid>
							<Grid item ml={1}>
								<Stack direction="row" alignItems="center">
									<Typography variant="subtitle1">Nazwa:</Typography>
									<Typography ml={2}>{certificate.title}</Typography>
								</Stack>
								<Stack direction="row" alignItems="center">
									<Typography variant="subtitle1">Termin ukończenia certyfikatu:</Typography>
									<Typography ml={2}>{certificate.datePass}</Typography>
								</Stack>
								<Stack direction="row" alignItems="center">
									<Typography variant="subtitle1">Ważność certyfikatu:</Typography>
									<Typography ml={2}>{certificate.dateValid}</Typography>
								</Stack>
								<Stack direction="row" alignItems="center">
									<Typography variant="subtitle1">Przypisana rola:</Typography>
									<Typography
										ml={2}>{certificate.roles.split(',').map(el => el === ROLE_ADS ? 'ADS, ' : 'Coordynator ADS')}</Typography>
								</Stack>
							</Grid>
							<Grid item flexGrow={1}>
								<Grid container flexDirection="column" justifyContent="flex-end" alignItems={'flex-end'}
								      component="div"
								      className="addUserTable">
									<Tooltip title="Edytuj" placement="right">
										<button onClick={(e) => {
											e.stopPropagation()
											editModalToggler(certificate)
										}}><EditIcon/>
										</button>
									</Tooltip>
									<Tooltip title="Usuń" placement="right">
										<button
											onClick={(e) => {
												e.stopPropagation()
												openDeleteModal(certificate.id, certificate.title)
											}}
										>
											<DeleteIcon/>
										</button>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						{
							openCertificateId === certificate.id &&
							<AdminCertificate
								completed={certificate.completed}
								notCompleted={certificate.notCompleted}
								certificateId={certificate.id}
							/>
						}
					</Grid>
				))
			}
			{
				isAddModalOpen &&
				<AdminAddCertificateModal
					closeModal={addModalToggler}
				/>
			}
			{
				isDeleteModalOpen.isOpen &&
				<AdminDeleteCertificateModal
					deleteCertificateId={isDeleteModalOpen.id}
					deleteCertificateTitle={isDeleteModalOpen.title}
					closeDeleteModel={closeDeleteModal}
				/>
			}
			{
				isEditModalOpen !== null &&
				<AdminAddCertificateModal
					closeModal={editModalToggler}
					certificate={isEditModalOpen}
				/>
			}
		</>
	)
}

export default AdminCertification;