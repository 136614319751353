export const NOTIFICATION_MESSAGES = {
	DEFAULT_ERROR: 'Coś poszło nie tak. Skontaktuj się z administratorem i zgłoś błąd',
	SESSION_LOST: 'Twoja sesja wygasła!',
	API_ERROR: 'Akcja nie powiodła się. Spróbuj ponownie później',
	PUBLISH_ERROR: 'Opublikowanie przypadku nie powiodło się',
	FILE_ERROR: 'Niestety ale próba wgrania pliku "{0}" zakończyła się niepowodzeniem. Spróbuj ponownie',
	POPUP_ERROR: 'Zezwól na "wyskakujące okienka" w ustawieniach przeglądarki, aby pobrać wszystkie pliki'
}

export const NOTIFICATION_TYPES = {
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
	SUCCESS: 'success'
}