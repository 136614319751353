import React, {useContext, useEffect, useState} from 'react'
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import PegSubModal from '../patients/PegSubModal';
import {PatientContext, PoiContext} from '../../../context';
import {patientModalValidate} from '../patients/patientModalValidate';
import tempImage from '../../../assets/img/ui/addPerson.png';
import GlobalLoader from '../../shared/ui/GlobalLoader';
import {DatePickerComponent} from '../../shared/ui/DatePickerComponent';
import {ADD_OPTION, SHOW_OPTION} from '../../../data/consts/modalOptions';
import {reasonsNoPump} from "../patients/helper";
import {AddPump} from "../../shared/addPump/AddPump";

const ImplementationAdd = ({settlementPeriod, modalOption = ADD_OPTION, userID, setIsAdding}) => {
	const {setModalError} = useContext(PoiContext)
	const {
		getAdditionalPatientInfo,
		patientState,
		addPatient,
		getPatient,
		clearPatient,
		editPatient,
	} = useContext(PatientContext);

	// TODO: clear component

	const initialPatientInfo = {
		displayName: '',
		nameInitial: '',
		surnameInitial: '',
		pumps: [],
		pesel: '',
		probeSerial: '',
		isTherapyInterrupted: false,
		probeInstallDate: null,
		medicineInclusionDate: null,
		hospitalLeftDate: null,
		therapyReason: '',
		isDataProcessAgreement: false,
		institution: '',
		therapySuspensionReason: '',
		therapySuspensionStartDate: null,
		therapySuspensionEndDate: null,
		therapyLeftReason: null,
		therapyLeftDate: null,
		initiationDate: new Date(settlementPeriod),
	}

	const [patientInfo, setPatientInfo] = useState(initialPatientInfo);
	const [availablePumps, setAvailablePumps] = useState([]);
	const [pegs, setPegs] = useState([])

	useEffect(() => {
		getAdditionalPatientInfo();
		if (modalOption !== ADD_OPTION) {
			getPatient(userID);
		}

		return () => {
			clearPatient()
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (patientState.patient) {
			setPatientInfo({...patientState.patient.info});
			setPegs([...patientState.patient.pegs]);
		}
	}, [patientState.patient])

	useEffect(() => {
		if (patientInfo.institution && patientInfo.institution !== '' && patientState.institutions) {
			setAvailablePumps(patientState.institutions.find(el => el.id === patientInfo.institution).pumps)
		} else {
			setAvailablePumps([])
		}
	}, [patientInfo.institution, patientState.institutions])

	const changeTherapyInterrupted = () => {
		setPatientInfo(prevState=> {
				return {
					...patientInfo,
					isTherapyInterrupted: !prevState.isTherapyInterrupted,
					pumps: [],
					probeSerial: '',
					probeInstallDate: null,
					medicineInclusionDate: null,
					hospitalLeftDate: null,
					therapySuspensionReason: '',
				}
			}
		)
		setPegs([])
	}

	const changeInfoHandler = (name, value) => {
		if (name === 'pesel' && value.length > 4) return
		setPatientInfo(prev => ({...prev, [name]: value}))
	}

	const pegsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		pegs.forEach(el => tempArray.push({...el}))
		tempArray[index] = {...el, [name]: value};
		setPegs([...tempArray])
	}


	const addPegsTableRow = () => setPegs(prev => [...prev, {
		serialNumber: '',
		installDate: null,
	}])

	const handleAddPumps = (pumps) => {
		setPatientInfo({
			...patientInfo,
			pumps,
			therapyLeftReason: null,
			therapyLeftDate: null,
		})
	}

	const delPegsTableRow = (index) => setPegs(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const submitHandler = async (e) => {
		e.preventDefault();

		const validation = patientModalValidate(patientInfo, pegs, modalOption);

		if (validation.isError) {
			setModalError(validation.defRes);
		} else {
			const data = {
				...patientInfo,
				pesel: `${patientInfo.pesel}`,
				institutionId: patientInfo.institution,
				therapyReason: patientInfo.therapyReason.length === 0 ? null : patientInfo.therapyReason,
				therapySuspensionReason: patientInfo.therapySuspensionReason.length === 0 ? null : patientInfo.therapySuspensionReason,
				therapyLeftReason: patientInfo.therapyLeftReason,
				pegs,
				initiationDate: patientInfo.initiationDate
			}
			if (modalOption === 'add') {
				await addPatient(data);
			}

			if (modalOption === 'edit') {
				await editPatient(userID, data);
			}
			setIsAdding(false);
		}
	}

	return patientInfo ?
		<Grid
			item
			container
			className="settlements__DatePicker"
			direction="row"
			alignItems="center"
			mb={1}
		>
			<Box className="settlements__addBox">
				<Typography
					className="settlements__addTitle"
					sx={{
						fontWeight: 'bold',
						maxWidth: '48rem',
						borderBottom: 'solid 2px #ccc'
					}}
				>
					Dodawanie nowego pacjenta
				</Typography>
				<Stack direction="row" sx={{marginTop: '1rem',}} spacing={4} alignItems="start">
					<img src={tempImage} alt="" style={{marginTop: '1rem'}}/>
					<form
						onSubmit={(e) => submitHandler(e)}
					>
						<Grid container pb={4} justifyContent="space-between">
							<Grid
								item
								xs={12}
								lg={4}
								mt={2}
								sx={{pr: {xs: 0, lg: 1}}}
							>
								<TextField
									name="nameInitial"
									label="IMIĘ (inicjały)"
									fullWidth
									required
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={4}
								mt={2}
								sx={{pr: {xs: 0, lg: 1}}}
							>
								<TextField
									name="surnameInitial"
									label="NAZWISKO (inicjały)"
									fullWidth
									required
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={4}
								mt={2}
							>
								<TextField
									name="pesel"
									label="4 ostatnie cyfry PESEL"
									fullWidth
									required
									type="number"
									inputProps={{maxLength: 4}}
									onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
									value={patientInfo.pesel}/>
							</Grid>
							<Grid container justifyContent="space-between">
								<Grid
									item
									xs={12}
									mt={2}
								>
									{
										patientState.institutions &&
										<FormControl fullWidth>
											<InputLabel id="institution-label" shrink={true}>SZPITAL*</InputLabel>
											<Select
												labelId="institution-label"
												id="institution"
												name="institution"
												value={patientInfo.institution}
												label="SZPITAL"
												notched={true}
												displayEmpty
												onChange={(e) =>{
													changeInfoHandler(e.target.name, e.target.value);
													changeInfoHandler('pumps', []);
												}}
											>
												<MenuItem value="">Wybierz z listy</MenuItem>
												{
													patientState.institutions.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
												}
											</Select>
										</FormControl>
									}
								</Grid>
								<Grid container>
									<Grid xs={6} item mt={2}>
										<FormControlLabel control={
											<Checkbox
												sx={{padding: 0, marginRight: 2}}
												name="isTherapyInterrupted"
												checked={patientInfo.isTherapyInterrupted}
												onChange={(e) => changeTherapyInterrupted()}
											/>} label="Przerwana terapia w trakcie wdrożenia"/>
									</Grid>
									{!patientInfo.isTherapyInterrupted && <>{patientInfo.institution === ''
										?
										<Grid xs={6} item>
											<Typography mt={2}>Wybierz szpital, aby móc przypisać pompę do pacjenta</Typography>
										</Grid>
										: <>{availablePumps.length === 0 &&
											<Grid item xs={12}><Typography color="red" mt={2}>Brak pomp na stanie
												szpitala.</Typography></Grid>}</>}</>}
								</Grid>
								{!patientInfo.isTherapyInterrupted && patientInfo.institution && availablePumps.length !== 0 &&
									<AddPump availablePumps={availablePumps}
													 pumps={patientInfo.pumps}
													 addPumps={handleAddPumps}/>}
							</Grid>
							{!patientInfo.isTherapyInterrupted && <>
								<Grid container mt={4} alignItems="center">
									<PegSubModal
										pegs={pegs}
										addPegsTableRow={addPegsTableRow}
										pegsTableHandler={pegsTableHandler}
										delPegsTableRow={delPegsTableRow}
										modalOption={modalOption}
									/>
								</Grid>
								<Grid container mt={2} justifyContent="space-between">
									<Grid item xs={12} lg={5.8} mt={2}>
										<TextField
											name="probeSerial"
											label="SONDA nr serii"
											fullWidth
											required
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										lg={5.8}
										mt={2}
									>
										<DatePickerComponent
											allowSameDateSelection
											required={true}
											label="DATA założenia sondy *"
											disableFuture
											value={patientInfo.probeInstallDate}
											onChange={(newValue) => changeInfoHandler('probeInstallDate', newValue)}
										/>
									</Grid>
								</Grid>
								<Grid container justifyContent="space-between">
									<Grid
										item
										xs={12}
										lg={5.8}
										mt={2}
									>
										<DatePickerComponent
											allowSameDateSelection
											label="DATA włączenia leku"
											disableFuture
											value={patientInfo.medicineInclusionDate}
											onChange={(newValue) => changeInfoHandler('medicineInclusionDate', newValue)}
										/>
									</Grid>
									<Grid item xs={12} lg={5.8} mt={2}>
										<DatePickerComponent
											allowSameDateSelection
											label="DATA wypisu ze szpitala"
											disableFuture
											value={patientInfo.hospitalLeftDate}
											onChange={(newValue) => changeInfoHandler('hospitalLeftDate', newValue)}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={5.8} mt={2}>
									<FormControl fullWidth>
										<InputLabel id="therapySuspensionReason-label" shrink={true}>Przyczyna włączenia do
											terapii:</InputLabel>
										<Select
											labelId="therapySuspensionReason-label"
											name="therapySuspensionReason"
											value={patientInfo.therapySuspensionReason}
											disabled={modalOption === SHOW_OPTION}
											label="Przyczyna włączenia do terapii:"
											displayEmpty
											notched={true}
											onChange={(e) => changeInfoHandler(e.target.name, e.target.value)}
										>
											<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
											{
												patientState.therapySuspensionReasons
												&& patientState.therapySuspensionReasons.map((el, index) =>
													<MenuItem
														key={index}
														value={el}
													>
														{el}
													</MenuItem>,
												)
											}
										</Select>
									</FormControl>
								</Grid>
							</>}
							{patientInfo.pumps.length === 0 && <Grid container mt={2} justifyContent="space-between">
								<Grid item xs={12} lg={5.8}>
									<FormControl fullWidth required>
										<InputLabel sx={{background: 'white'}} id="availablePumps-label" shrink={true}>Data zerwania
											terapii*</InputLabel>
										<DatePickerComponent
											value={patientInfo.therapyLeftDate}
											disableFuture
											onChange={(newValue) => changeInfoHandler('therapyLeftDate', newValue)}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} lg={5.8} pr={1}>
									<FormControl fullWidth>
										<InputLabel sx={{background: 'white'}} id="availablePumps-label" shrink={true}>Przyczyna
											wypadnięcia z terapii*</InputLabel>
										<Select
											required
											labelId="reason-label"
											id="therapyLeftReason"
											name="therapyLeftReason"
											value={patientInfo.therapyLeftReason}
											notched={true}
											displayEmpty
											onChange={(newValue) => changeInfoHandler('therapyLeftReason', newValue.target.value)}>
											<MenuItem value="">Wybierz z listy</MenuItem>
											{
												reasonsNoPump.map(el => (
													<MenuItem
														key={el}
														value={el}>{el}</MenuItem>
												))
											}
										</Select>
									</FormControl>
								</Grid>
							</Grid>}
							<Grid item mt={3}>
								<FormControlLabel control={
									<Checkbox
										sx={{padding: 0, marginRight: 2}}
										name="isDataProcessAgreement"
										checked={patientInfo.isDataProcessAgreement}
										onChange={(e) => changeInfoHandler(e.target.name, e.target.checked)}
									/>}
																	label="Czy przekazano informacje o przetwarzaniu danych osobowych dla pacjenta/opiekuna?"
								/>
							</Grid>
						</Grid>
						<Stack sx={{borderTop: 'solid 1px #ccc', width: '42rem', height: '5rem', margin: 'auto'}}>
							<Button type="submit" color="primary" variant="contained" sx={{
								margin: 'auto',
							}}>zapisz</Button>
						</Stack>
					</form>
				</Stack>

			</Box>
		</Grid>
		:
		<GlobalLoader/>
};

export default ImplementationAdd
