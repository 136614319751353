import React, {useContext} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {AppContext} from '../../../../context/app/appContext';
import {roleNormaliser} from '../../../../utils/roleNormalizer';
import {Box, Tooltip} from "@mui/material";
import {EnhancedTableHead } from "../../../../utils/sortingTable";
import Search from '../../../shared/search/Search'
import {useTableController} from "../../../../hooks/useTableController";
import {headCells} from "./helper";

const UserDataTable = ({openModal, userIDHandler}) => {
    const {appState} = useContext(AppContext);

    const {
        drawArray,
        count,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem
    } = useTableController(appState.users, ['name', 'surname', 'email', 'institutions', 'phone'], {orderBy: 'name'})

    return (<>
            <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
                <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={appState.users.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {drawArray.map((item, index) => {
                                    const isItemSelected = isSelected(item.id);
                                    return (<TableRow hover
                                                      onClick={(event) => handleSelectedItem(event, item.id)}
                                                      role="row"
                                                      aria-rowindex={isItemSelected}
                                                      tabIndex={-1}
                                                      key={index}
                                                      selected={isItemSelected}>
                                        <TableCell align={'center'}>
                                            {index + 1 + '.'}
                                        </TableCell>

                                        <TableCell align={'center'}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {item.surname}
                                        </TableCell>
                                        <TableCell>
                                            <ul>
                                                {Array.isArray(item.institutions) && item.institutions.map(el => <li
                                                    key={el.id}>{el.name}</li>)}
                                            </ul>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {item.phone}</TableCell>
                                        <TableCell>
                                            {roleNormaliser(item.roles)}
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {item.email}</TableCell>
                                        <TableCell align={'center'}>
                                            <div className='addUserTable'>
                                                <Tooltip title="Edytuj" placement="right">

                                                    <button onClick={() => {
                                                        userIDHandler(item.id)
                                                        openModal('edit')
                                                    }}><EditIcon/></button>
                                                </Tooltip>
                                                <Tooltip title="Wyświetl" placement="right">
                                                    <button onClick={() => {
                                                        userIDHandler(item.id)
                                                        openModal('show')
                                                    }}><RemoveRedEyeIcon/></button>
                                                </Tooltip>
                                                <Tooltip title="Usuń" placement="right">
                                                    <button onClick={() => {
                                                        userIDHandler(item.id)
                                                        openModal('delete')
                                                    }}><DeleteIcon/>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Elementy na stronie"}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>

    );
}
export default UserDataTable
