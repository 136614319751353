import { NOTIFICATION_MESSAGES, NOTIFICATION_TYPES } from '../../store/notificationStore/notificationType';

export const notificationHandler = (status, message) => {

	switch (true) {
		case status >= 500:
			//default error for any code status  >= 500
			return {
				type: NOTIFICATION_TYPES.ERROR,
				// message: NOTIFICATION_MESSAGES.DEFAULT_ERROR
				message
			}
		case status === 403:
			//forbidden status when session is expired
			return {
				type: NOTIFICATION_TYPES.ERROR,
				message: NOTIFICATION_MESSAGES.SESSION_LOST
			}
		case status >= 400:
			//default error for any code status  >= 400
			return {
				type: NOTIFICATION_TYPES.ERROR,
				message
			}
		default:
			return {
				type: NOTIFICATION_TYPES.ERROR,
				message
			}
	}
}