import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Grid, InputLabel, ListItemText, MenuItem, Select, Typography} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import PageTitle from '../../../shared/ui/PageTitle';
import {useEmailNotificationsApi} from '../../../../apiHooks/useEmailNotifications';
import {useEmailNotificationStore} from '../../../../store/hooks';

const EmailNotifications = observer(() => {
  const emailNotificationStore = useEmailNotificationStore();
  const {users, data} = emailNotificationStore;
  const {getEmailNotificationsData, putEmailNotificationsData} = useEmailNotificationsApi();

  const [dataEmailNotifications, setDataEmailNotifications] = useState([]);

  useEffect(() => {
    if (data === null) {
      void getEmailNotificationsData();
    } else {
      setDataEmailNotifications([...data]);
    }

  }, [data, getEmailNotificationsData, emailNotificationStore]);

  useEffect(() => {
    return () => emailNotificationStore.clearEmailNotification();
  }, [emailNotificationStore]);

  const setDataSliceHandler = (id, selectedUsers) => {
    const newData = JSON.parse(JSON.stringify(dataEmailNotifications));
    const index = newData.findIndex(element => element.id === id);

    if (index === -1) {
      return;
    }

    if (selectedUsers[selectedUsers.length - 1] === 'all') {
      newData[index].users = newData[index].users.length === users.length ? [] : users.map(user => user.id);
    } else {
      newData[index].users = [...selectedUsers];
    }

    setDataEmailNotifications(newData);
  };

  const saveDataHandler = () => {
    void putEmailNotificationsData({
      data: [...dataEmailNotifications.map(element => {
        return {
          id: element.id,
          users: element.users,
        };
      })],
    });
  };

  const isData = dataEmailNotifications && users;

  return (<div>
    <PageTitle title="Powiadomienia:"/>
    <Grid container display={'flex'} flexDirection={'column'} gap={2} ml={1} pr={2}>
      {isData && <>
        {dataEmailNotifications.map(emailNotificationElement =>
          <Grid item xs={12}>
            <Typography variant="subtitle1" mb={1}>{emailNotificationElement.title}</Typography>
            <FormControl fullWidth>
              <InputLabel id={emailNotificationElement.title}>Wybierz</InputLabel>
              <Select
                sx={{maxWidth: '600px'}}
                multiple
                name={emailNotificationElement.title}
                placeholder={'Wybierz...'}
                onChange={(e) => setDataSliceHandler(emailNotificationElement.id, e.target.value)}
                value={emailNotificationElement.users}>
                <MenuItem
                  key={'all'}
                  value={'all'}
                >
                  <ListItemText
                    primary={emailNotificationElement.users.length === users.length ? 'Usuń wszystkich' : 'Wybierz wszystkich'}/>
                </MenuItem>
                {users.map(user => <MenuItem key={user.id} value={user.id}>{user.title}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>)}
        <Grid item xs={12}>
          <Button
            type="submit"
            onClick={saveDataHandler}
            color="primary" variant="contained">
            Zapisz
          </Button>
        </Grid>
      </>}
    </Grid>
  </div>);
});

export default EmailNotifications;