import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import {
	WAREHOUSE_ITEM_SERVER_ADS_STATUSES,
	warehouseAdsItemLabels
} from '../../../../../../data/consts/warehouse/warehouseItemStatuses';
import { useTableController } from '../../../../../../hooks/useTableController';
import { useADSPumpsTableViewModel } from './useADSPumpsTable.vm';
import { EnhancedTableHead} from '../../../../../../utils/sortingTable';
import Search from '../../../../../shared/search/Search';
import { ActionCell } from '../../../../../shared/customTable/actionCell/ActionCell';


const HEAD_CELLS = [
	{
		id: 'serialNumber',
		label: 'Numer seryjny',
	},
	{
		id: 'patient',
		label: 'Pacjent',
	},
	{
		id: 'institution',
		label: 'Nazwa szpitala',
	},
	{
		id: 'status',
		label: 'Status',
	},
	{
		id: 'actions',
		label: 'Akcje',
	}
]

export const PumpsTable = observer(() => {
	const { ADSPumps } = useADSPumpsTableViewModel()

	const {
		drawArray,
		count,
		searchTerm,
		page,
		rowsPerPage,
		order,
		orderBy,
		filters,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange,
		filterChangeHandler
	} = useTableController(ADSPumps, ['status', 'institution', 'patient', 'serialNumber'],
		{
			orderBy: 'status',
			filters:
			[{
				name: 'status',
				values: Object.values(WAREHOUSE_ITEM_SERVER_ADS_STATUSES).map(value => warehouseAdsItemLabels(value)),
				selectedValue: '',
			}]
	})

	return (
		<Grid container flexDirection="column" mt={2}>
			<Grid container justifyContent="flex-end" mb={2}>
				<Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
			</Grid>
			<TableContainer>
				<Table>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={ADSPumps.length}
						headCells={HEAD_CELLS}
						filters={filters}
						onFilterChange={filterChangeHandler}
						customSelectStyle={{ width: '250px' }}
					/>
					{
						drawArray.length > 0
							? (
								<TableBody>
									{
										drawArray.map(pump => (
											<TableRow key={pump.id} role="row" tabIndex={-1}>
												<TableCell>{pump.serialNumber}</TableCell>
												<TableCell align="center">{pump.patient ? pump.patient : 'nieprzypisane do pacjenta'}</TableCell>
												<TableCell align="center">{pump.institution}</TableCell>
												<TableCell align="center">{pump.status}</TableCell>
												<TableCell sx={{ width: '25%' }}>
													<ActionCell status={pump.status} pumpId={pump.id}/>
												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							)
							: (
								<TableBody>
									<TableRow>
										<TableCell colSpan={5}>
											<Typography textAlign="center">
												Brak danych do wyświetlenia...
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							)
					}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				labelRowsPerPage={'Elementy na stronie'}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Grid>
	)
})