import { useRootStore } from '../RootStateContext';

export const useWarehouseStore = () => {
	const rootStore = useRootStore();

	if (!rootStore.warehouseStore) {
		throw new Error('Warehouse store should be defined');
	}

	return rootStore.warehouseStore;
}