import React, { useContext, useEffect } from 'react';
import MessagesDataTable from './MessagesDataTable';
import PageTitle from '../../../shared/ui/PageTitle';
import { Button, Grid, Typography } from '@mui/material';
import MessageAdminModal from './MessageAdminModal';
import { AuthContext, MessagesContext } from '../../../../context';
import { ROLE_ADMIN } from '../../../../data/consts/users';
import { useModalController } from '../../../../hooks/useModalController';
import {ADD_OPTION, DELETE_OPTION} from '../../../../data/consts/modalOptions';
import {MessageModalDelete} from "./MessageModalDelete";

const Messages = () => {
	const { role } = useContext(AuthContext);
	const { messageState, getMessagesList, getUsers } = useContext(MessagesContext);

	const {
		modalId, modalIDHandler, modalInfo, openModal, closeModal
	} = useModalController();

	useEffect(() => {
		getMessagesList();
		getUsers();
		// eslint-disable-next-line
	}, [])

	return (<>
		<div className="users">
			<PageTitle title="Wiadomości:"/>
			<Grid container justifyContent="flex-end" pb={1}>
				{
					role === ROLE_ADMIN &&
					<Button
						color="primary" variant="contained"
						className="formButton"
						onClick={() => openModal(ADD_OPTION)}
					>
						Nowa wiadomość
					</Button>
				}
			</Grid>
			{
				messageState.messagesList
					? <MessagesDataTable openModal={openModal} userIDHandler={modalIDHandler}/>
					: <Typography>Brak danych</Typography>
			}
		</div>

		{
			modalInfo.isOpen
			&& (modalInfo.option === DELETE_OPTION ?
				<MessageModalDelete
					closeModal={closeModal}
					messageID={modalId}
				/>
				:
				<MessageAdminModal
					closeModal={closeModal}
					modalOption={modalInfo.option}
					messageID={modalId}
				/>)
		}
	</>)
}

export default Messages;
