import React from 'react';
import {Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography,} from '@mui/material';
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";
import {setRadioButtonNotRequired} from "./helper";

const DrugTable = ({
                       isDrugTable,
                       addDrugTableRow,
                       delDrugTableRow,
                       drugTableHandler,
                       drugTableToggler,
                       drugTableValue,
                   }) => {
    return (
        <>
            <Grid border={`1px solid #071d49`} p={2}>
                <Typography variant="subtitle1">Leki stosowane równocześnie:</Typography>
                <RadioGroup
                    aria-labelledby="adverseType-label"
                    name="drug"
                    value={isDrugTable}
                    onClick={(e) => drugTableToggler(setRadioButtonNotRequired(e.target.value, isDrugTable))}
                >
                    <FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
                    <FormControlLabel value="Nie zgłoszono" control={<Radio/>} label="Nie zgłoszono"/>
                    <FormControlLabel value="Tak (wymienić poniżej, jakie):" control={<Radio/>}
                                      label="Tak (wymienić poniżej, jakie):"/>
                </RadioGroup>
            </Grid>
            {
                isDrugTable === 'Tak (wymienić poniżej, jakie):' &&
                <>
                    <Grid container border={`1px solid #071d49`} borderTop="none" display="flex" direction="row">
                        <Grid item className="subMenuSidebar">Leki</Grid>
                        <Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container>
                            {
                                drugTableValue.map((el, index) => (
                                    <Grid key={index} item xs={12} className="formRow">
                                        <Grid item xs={12} container display="flex" direction="row"
                                              justifyContent="space-between">
                                            <p className="subMenuHeader">Lek {index + 1}</p>
                                            {
                                                drugTableValue.length > 1 &&
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    className="formButton"
                                                    onClick={() => delDrugTableRow(index)}
                                                >
                                                    usuń lek
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={12} container>
                                            <Grid item xs={12} lg={4} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Lek stosowany
                                                        równocześnie</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="name"
                                                        value={el.name}
                                                        onChange={(e) => drugTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={4} p={2} container alignItems='flex-end'>
                                                <DatePickerComponent
                                                    allowSameDateSelection
                                                    label="Data rozpoczęcia terapii"
                                                    disableFuture
                                                    value={el.dateStart}
                                                    onChange={(newValue) => drugTableHandler('dateStart', newValue, el, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4} p={2} container alignItems='flex-end'>
                                                <DatePickerComponent
                                                    allowSameDateSelection
                                                    label="Data zakończenia terapii"
                                                    disableFuture
                                                    value={el.dateEnd}
                                                    onChange={(newValue) => drugTableHandler('dateEnd', newValue, el, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Dawka</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="dosage"
                                                        value={el.dosage}
                                                        onChange={(e) => drugTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Droga podania</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="method"
                                                        value={el.method}
                                                        onChange={(e) => drugTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={4} p={2}>
                                                <FormControl fullWidth required>
                                                    <Typography variant="subtitle1">Wskazanie</Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="indication"
                                                        value={el.indication}
                                                        onChange={(e) => drugTableHandler(e.target.name, e.target.value, el, index)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained" color="success" className="formButton" onClick={addDrugTableRow}>dodaj
                            lek</Button>
                    </Grid>
                </>
            }
        </>
    )
}

export default DrugTable;