import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../../shared/ui/PageTitle';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { AuthContext, ReckoningsContext } from '../../../../context';
import AdsPerson from '../../../../assets/img/ui/adsPerson.png';
import SearchIcon from '@mui/icons-material/Search';
import SummaryApproveConfirm from '../../settlements/infoElements/SummaryApproveConfirm';
import { ModalApprove } from './ModalApprove';
import { ROLE_ADMIN } from '../../../../data/consts/users';
import { polishMonthName } from './polishMonth';

const Reckonings = () => {
	const navigate = useNavigate();
	const params = useParams();
	const settlementPeriod = params.yearMonth;
	const currentReckoningYear = settlementPeriod.split('-')[0];
	const currentReckoningMonth = settlementPeriod.split('-')[1];
	const currentReckoningMonthPolish = polishMonthName(currentReckoningMonth);

	const { role } = useContext(AuthContext);
	const {
		reckoningsState,
		getReckonings,
		approveReckoning,
		confirmReckoning,
		getReckoningReports,
		getReckoningBill
	} = useContext(ReckoningsContext);
	const [approveState, setApproveState] = React.useState(null);

	const openApproveModal = (id, period, relatedReckonings) => setApproveState({ id, period, relatedReckonings });
	const closeApproveModal = () => setApproveState(null);
	const approveReckoningHandler = () => approveReckoning(approveState.id, approveState.period);

	useEffect(() => {
		getReckonings(settlementPeriod);
		// eslint-disable-next-line
	}, [reckoningsState.reckoningAccepted, settlementPeriod])

	return (<>
			{reckoningsState.reckoningAccepted && role === ROLE_ADMIN &&
				<SummaryApproveConfirm clearFunction={confirmReckoning}/>}
			<Box container className="settlements">
				<Box mb={9}>
					<PageTitle
						title={
							<>
								<span>Rozliczenie: </span><b>{currentReckoningMonthPolish + ' ' + currentReckoningYear}</b>
							</>
						}
					/>
				</Box>
				{
					approveState !== null &&
					<ModalApprove
						approveReckoningHandler={approveReckoningHandler}
						closeModal={closeApproveModal}
						relatedReckonings={approveState.relatedReckonings}
					/>
				}
				{!!reckoningsState.reckonings.length ?
					reckoningsState.reckonings.map((user, index) => {
							return <Grid container key={index} py={0.5}>
								{/*PATIENT TITLE*/}
								<Grid
									item
									xs={12}
									container
									display="row"
									wrap="nowrap"
									alignItems="center"
									sx={{ position: 'relative' }}
									bgcolor="#f5f5f5"
									padding={'30px 70px 40px'}
								>
									<Grid item pr={'45px'}>
										<img src={AdsPerson} style={{ width: 'auto' }} alt="ava"
										     className="settlements__blockImage_XL"/>
									</Grid>
									<Grid item ml={1} container justifyContent={'flex-start'} columnSpacing={{ xs: 3 }}>
										<Grid item xs={9} lg={7}>
											<Stack className="settlements__status" direction="column">
												<Typography variant="subtitle1">Imię i Nazwisko:</Typography>
												<Typography>{user.userName}</Typography>
											</Stack>
											<Stack className="settlements__status" direction="column">
												<Typography variant="subtitle1">Szpital:</Typography>
												<Typography>{user.institution}</Typography>
											</Stack>
										</Grid>
										<Grid item xs={3} lg={2}>
											<Stack>
												{
													user.status === 'waiting' &&
													<Button
														color="primary" variant="contained"
														className="buttonCustom"
														style={{
															color: 'white',
															marginTop: '0.5rem'
														}}
														onClick={() => {
															if (user.relatedReckonings.length > 0) {
																openApproveModal(user.reckoningId, settlementPeriod, user.relatedReckonings)
															} else {
																approveReckoning(user.reckoningId, settlementPeriod)
															}
														}
														}
													>
														Akceptuj
													</Button>
												}
												{
													user.status !== 'draft' &&
													<Button
														mt={4}
														color="secondary" variant="contained"
														className="buttonCustom"
														style={{
															color: 'white',
															marginTop: '1.5rem'
														}}
														onClick={() => navigate(`/reckonings/${settlementPeriod}/${user.userId}`)}><SearchIcon/></Button>
												}
												{
													(user.status === 'accepted' || user.status === 'closed') &&
													<>
														<Button
															color="primary" variant="contained"
															className="buttonCustom"
															style={{
																color: 'white',
																marginTop: '0.5rem'
															}}
															onClick={() => getReckoningReports(user.userId, settlementPeriod)}>
															Pobierz raport
														</Button>
														<Button
															color="primary" variant="contained"
															className="buttonCustom"
															style={{
																color: 'white',
																marginTop: '0.5rem'
															}}
															onClick={() => getReckoningBill(user.userId, settlementPeriod)}
														>
															Pobierz RACHUNEK
														</Button>
													</>
												}
											</Stack>
										</Grid>
										{['accepted', 'closed', 'rejected'].includes(user.status) &&
											<Grid item xs={12} lg={3} container alignItems="center">
												<Stack
													color={user.status === 'rejected' ? 'red' : user.status !== 'closed' ? 'green' : 'cornflowerblue'}>
													<Typography variant="subtitle1">Status:</Typography>
													<Typography>{user.statusPl}</Typography>
												</Stack>
											</Grid>
										}
									</Grid>
								</Grid>
							</Grid>
						}
					)
					:
					<Grid container alignItems="center" justifyContent="center" p={2}>
						<Typography>Brak danych</Typography>
					</Grid>}
			</Box>
		</>
	)
}

export default Reckonings;
