export const headCells = [
    {id: 'id', label: 'LP'},
    {id: 'createdAt', label: 'YYYY-MM-DD'},
    {id: 'user', label: 'Odbiorca'},
    {id: 'title', label: 'Tytuł'},
    {id: 'content', label: 'Treść'},
    {id: 'isRead', label: 'Przeczytana'},
    {id: 'actions', label: 'Działania'},
];

export const initMessageData = {
    title: '',
    content: '',
}
