import React, {useContext, useEffect} from 'react';
import {AppContext, PoiContext} from '../../../../context';
import {
	Box,
	Button,
	Divider,
	Grid,
	InputLabel, ListItemText,
	MenuItem,
	MenuList,
	OutlinedInput,
	Paper,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GlobalLoader from '../../../shared/ui/GlobalLoader';
import { equalizerData } from '../../../../utils/helpfunc';
import { pl } from 'date-fns/locale';
import {userDataInit, validationSchema, validationSchemaAdd} from './helper';
import { ROLE_ADMIN, ROLE_ADS, ROLE_COORD_ADS, ROLE_KAM, ROLE_WAREHOUSE } from '../../../../data/consts/users';
import { ADD_OPTION, DELETE_OPTION, EDIT_OPTION, SHOW_OPTION } from '../../../../data/consts/modalOptions';
import {useForm} from "../../../../hooks/useForm";
import {generateValidationObject} from '../certification/helper';

const AdminUserModal = ({ closeModal, userID, modalOption }) => {
	const { setModalError } = useContext(PoiContext)

	const { addUser, editUser, getAdditionalUserFormInfo, getUser, deleteUser, appState } = useContext(AppContext);

	useEffect(() => {
		void getAdditionalUserFormInfo();
		if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {
			void getUser(userID);
		}
		// eslint-disable-next-line
	}, [])

	const submitHandler = async (e) => {
		let isAdd = false;
		if (modalOption === ADD_OPTION) {
			isAdd = await addUser(formik.values);
		} else if (modalOption === EDIT_OPTION) {
			isAdd = await editUser(formik.values, userID);
		} else if (modalOption === DELETE_OPTION) {
			isAdd = await deleteUser(userID);
		}

		isAdd && closeModal();
	}
	const {
		formik
	} = useForm(userDataInit, (modalOption === ADD_OPTION ? validationSchemaAdd : (modalOption === EDIT_OPTION  ? validationSchema : null)), submitHandler, []);

	useEffect(() => {
		if (appState.showUserInfo) {
			if (modalOption === SHOW_OPTION || modalOption === EDIT_OPTION) {
				formik.setValues({...appState.showUserInfo});
			}
		}
		// eslint-disable-next-line
	}, [appState.showUserInfo])

	useEffect(()=> {
		if(formik.isSubmitting && Object.keys(formik.errors).length > 0){
			if(formik.errors.password && Object.keys(formik.errors).length === 1){
				setModalError(generateValidationObject('Hasło powinno zawierać przynajmniej jedną dużą literę, cyfrę lub znak specjalny').defRes);
			}
			else{
				setModalError(generateValidationObject('Proszę o uzupełnienie wymaganych pól.').defRes);
			}
		}
	}, [formik, setModalError])

	const institutionSelectionHandler = (event) => {
		const value = event.target.value;

		if(value[value.length - 1] === 'all'){
			appState.institutions &&
				formik.setFieldValue('institutions', formik.values.institutions.length === appState.institutions.length ? [] : appState.institutions.filter(el => el !== 'all').map(institution => institution.id));
		}
		else{
			formik.setFieldValue('institutions', value);
		}
	}


	const selectAllInstitutionsText = formik.values.institutions.length ===  appState.institutions?.length ? 'Usuń wszystkie' : 'Wybierz wszystkie';

	return formik.values
		?
		<Grid className="customModal ">
			<Stack component="div" className="customModal__addMenu ">

				{
					modalOption === DELETE_OPTION ?
						<Box style={{ display: 'flex', justifyContent: 'center' }}> <Typography variant="h5" mb={2} mt={3}>
							Czy jesteś pewny, że chcesz usunąć użytkownika?
						</Typography></Box> :
						<Typography variant="h4" mb={2}>
							{
								modalOption === ADD_OPTION ? 'Dodaj użytkownika'
									: modalOption === SHOW_OPTION ? 'Dane użytkownika' : 'Edytuj'
							}
						</Typography>
				}
				<form onSubmit={formik.handleSubmit} noValidate>
					{
						modalOption !== DELETE_OPTION && <Grid container spacing={2} display="flex" direction="column" wrap="nowrap"
						                                       className="customModal__scrollArea">
							<Grid item>
								<TextField
									required
									label="Imię"
									name="firstName"
									error={Boolean(formik.errors.firstName)}
									value={formik.values.firstName}
									onChange={formik.handleChange}
									fullWidth
									disabled={(modalOption === SHOW_OPTION)}

								/>
							</Grid>
							<Grid item>
								<TextField
									required
									label="Nazwisko"
									name="secondName"
									error={Boolean(formik.errors.secondName)}
									value={formik.values.secondName}
									onChange={formik.handleChange}
									fullWidth
									disabled={(modalOption === SHOW_OPTION)}
								/>
							</Grid>
							{
								modalOption === ADD_OPTION &&
								<Grid item>
									<TextField
										required
										label="Hasło"
										name="password"
										type="password"
										autoComplete={'none'}
										error={Boolean(formik.errors.password)}
										value={formik.values.password}
										onChange={formik.handleChange}
										fullWidth
										disabled={(modalOption === SHOW_OPTION)}
									/>

								</Grid>
							}
							{
								modalOption === SHOW_OPTION
									?
									<Grid item>
										<Paper mt={2}
										       sx={{
											       width: '100%',
											       border: '0.5px solid #9e9e9e',
											       color: 'text.disabled',
											       borderBottom: 'none',
										       }}>
											<Typography p={1.5}>Placówka:</Typography>
											<Divider/>

											<MenuList>
												{
													formik.values.institutions && appState.institutions && equalizerData(formik.values.institutions, appState.institutions).map((el, index) =>
														<MenuItem key={index} sx={{ cursor: 'default' }}> {el} </MenuItem>)
												}
											</MenuList>
										</Paper>
									</Grid>
									:
									<Grid item>
										<FormControl fullWidth required>
											<InputLabel id="institution">Placówka</InputLabel>
											<Select
												sx={{ maxWidth: '550px' }}
												multiple
												name="institutions"
												label="Placówka"
												error={Boolean(formik.errors.institutions)}
												value={formik.values.institutions}
												onChange={institutionSelectionHandler}
												disabled={(modalOption === SHOW_OPTION)}
												input={<OutlinedInput label="Placówka"/>
												}
											>
												<MenuItem
													key={'all'}
													value={'all'}
												>
													<ListItemText primary={selectAllInstitutionsText}/>
												</MenuItem>
												{
													appState.institutions && appState.institutions.map(institution => (
														<MenuItem
															key={institution.id}
															value={institution.id}
														>
															{institution.name}
														</MenuItem>
													))
												}
											</Select>
										</FormControl>
									</Grid>
							}
							<Grid item>
								<TextField
									required
									label="Numer telefonu"
									name="phoneNumber"
									error={Boolean(formik.errors.phoneNumber)}
									value={formik.values.phoneNumber}
									onChange={formik.handleChange}
									fullWidth
									disabled={(modalOption === SHOW_OPTION)}
								/>
							</Grid>
							<Grid item>
								<FormControl fullWidth required>
									<InputLabel id="addUser-roles">Rola</InputLabel>
									<Select
										labelId="addUser-roles"
										name="role"
										label="Rola"
										disabled={(modalOption === 'show')}
										error={Boolean(formik.errors.role)}
										value={formik.values.role}
										onChange={formik.handleChange}>
										<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
										<MenuItem value={ROLE_ADS}>ADS</MenuItem>
										<MenuItem value={ROLE_COORD_ADS}>KOORDYNATOR ADS</MenuItem>
										<MenuItem value={ROLE_KAM}>KAM</MenuItem>
										<MenuItem value={ROLE_ADMIN}>ADMIN</MenuItem>
										<MenuItem value={ROLE_WAREHOUSE}>MAGAZYNIER</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<FormControl fullWidth required>
									<InputLabel id="addUser-types">Zawód</InputLabel>
									<Select
										labelId="addUser-types"
										name="type"
										label="Zawód"
										disabled={(modalOption === 'show')}
										error={Boolean(formik.errors.type)}
										value={formik.values.type}
										onChange={formik.handleChange}>
										<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
										<MenuItem value="LEKARZ">LEKARZ</MenuItem>
										<MenuItem value="PIELĘGNIARKA">PIELĘGNIARKA</MenuItem>
										<MenuItem value="PIELĘGNIARKA DYPLOMOWANA">PIELĘGNIARKA DYPLOMOWANA</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<TextField
									required
									label="Adres e-mail"
									name="email"
									error={Boolean(formik.errors.email)}
									value={formik.values.email}
									onChange={formik.handleChange}
									disabled={(modalOption === SHOW_OPTION)}
									fullWidth
								/>
							</Grid>
							<Grid item>
								<TextField
									required
									label="Numer Identyfikatora"
									name="id"
									error={Boolean(formik.errors.id)}
									value={formik.values.id}
									onChange={formik.handleChange}
									disabled={(modalOption === SHOW_OPTION)}
									fullWidth
								/>
							</Grid>
							<Grid item>
								<FormControl fullWidth required>
									<InputLabel id="addUser-lang">Język</InputLabel>
									<Select
										labelId="addUser-lang"
										name="language"
										multiple
										error={Boolean(formik.errors.language)}
										value={formik.values.language}
										onChange={formik.handleChange}
										label="Język"
									>
										<MenuItem value="" disabled={true}><em>Wybierz z listy</em></MenuItem>
										<MenuItem value="polski">Polski</MenuItem>
										<MenuItem value="angielski">Angielski</MenuItem>
										<MenuItem value="niemiecki">Niemiecki</MenuItem>
										<MenuItem value="ukraiński">Ukraiński</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item>
								<TextField
									required
									label="Numer umowy"
									name="contractNO"
									error={Boolean(formik.errors.contractNO)}
									value={formik.values.contractNO}
									onChange={formik.handleChange}
									disabled={(modalOption === SHOW_OPTION)}
									fullWidth
								/>
							</Grid>
							<Grid item marginBottom={2}>
								<FormControl fullWidth required>
									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
										<DatePicker
											allowSameDateSelection
											disableFuture
											label="Data podpisania umowy *"
											name="dateContract"
											inputFormat="dd/MM/yyyy"
											views={['year', 'month', 'day']}
											openTo="year"
											disabled={(modalOption === SHOW_OPTION)}
											slotProps={{
												textField: {
													error: Boolean(formik.errors.dateContract),
												},
											}}
											value={formik.values.dateContract}
											onChange={(newValue) => formik.setFieldValue("dateContract", newValue)}
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
						</Grid>}
					<div className={`customModal__addMenu-buttonArea ${modalOption === SHOW_OPTION ? 'alignButton' : ''} `}>
						<Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
						{
							modalOption !== SHOW_OPTION &&
							<Button
								type="submit" color="primary" variant="contained">
								{modalOption === ADD_OPTION ? 'Dodaj' : modalOption === DELETE_OPTION ? 'Usuń' : 'Zapisz'}
							</Button>
						}
					</div>

				</form>
			</Stack>
		</Grid>
		:
		<GlobalLoader/>
}

export default AdminUserModal;
