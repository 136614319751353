import React, {useContext} from "react";
import {PatientContext} from "../../../context";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography
} from "@mui/material";

export const PatientModalDelete = ({ closeModal, userID }) => {
  const { delPatient } = useContext(PatientContext);

  const submitHandler = async (e) => {
    await delPatient(userID);
    closeModal();
  }

  return (
    <Grid className="customModal ">
      <Stack component="div" className="customModal__addMenu">
        <Box style={{ display: 'flex', justifyContent: 'center' }}> <Typography variant="h5" mb={2} mt={3}>
          Czy jesteś pewny, że chcesz usunąć pacjenta?
        </Typography></Box>
        <div className={`customModal__addMenu-buttonArea`}>
         <Button onClick={closeModal} color="primary" variant="contained">Powrót</Button>
         <Button
           type="submit"
           onClick={submitHandler}
           color="primary" variant="contained">Usuń</Button>
        </div>
      </Stack>
    </Grid>
  )
}