import React, {useContext, useState} from 'react'
import {Box, Button, Grid, Stack, TextField, Typography} from '@mui/material';
import tempImage from '../../../assets/img/ui/addClock.png';
import {SettlementsContext} from '../../../context/settlements/settlementsContext';
import {PoiContext} from '../../../context/poi/poiContext';
import {additionalTimeValidate} from "./additionalTimeValidate"
import {dateFormatForm} from "../../../utils/helpfunc"
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";


const AdditionalTimeAdd = ({setIsAdding, isReckoningAvailable}) => {

    const {setModalError} = useContext(PoiContext)

    const {createAdditionalTime} = useContext(SettlementsContext)

    const [date, setDate] = useState(null);
    const [duration, setDuration] = useState('');
    const [reason, setReason] = useState('');

    function disabledDates(date) {
        const disabledReckoning = isReckoningAvailable ? (new Date(date).getDate() === new Date().getDate() && new Date(date).getMonth() === new Date().getMonth()) //not today
            : date.getMonth() < new Date().getMonth() + 1
        // const noWeekends= date.getDay() === 0 || date.getDay() === 6;
        const disabled = disabledReckoning /* || noWeekends */
        return disabled
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const validation = additionalTimeValidate(reason, date, duration)

        if (validation.isError) {
            setModalError(validation.defRes);
        } else {

            const data = {
                date: dateFormatForm(date),
                duration: parseInt(duration),
                reason,
            }
            createAdditionalTime(data);
            setIsAdding(false)
        }
    }

    return (
        <Grid item container className="settlements__DatePicker" direction="row" alignItems="center"
              sx={{bgcolor: '#F5F5F5'}}
              mb={1}>
            <Box className="settlements__addBox">
                <Typography className="settlements__addTitle"
                            sx={{fontWeight: "bold", maxWidth: "48rem", borderBottom: "solid 2px #ccc"}}>Dodawanie
                    nadgodzin</Typography>
                <Stack direction="row" sx={{marginTop: "1rem", width: '944px'}} spacing={4} alignItems="start">
                    <img src={tempImage} alt=""/>
                    <form onSubmit={submitHandler}>
                        <fieldset>
                            <Grid container item pb={4} xs={12} columnSpacing={2} justifyContent='space-between'
                                  sx={{width: '100%'}}>
                                <Grid item xs={12} lg={6} mt={3}>
                                    <DatePickerComponent
                                        shouldDisableDate={disabledDates}
                                        allowSameDateSelection
                                        disablePast
                                        required
                                        label='DATA'
                                        value={date}
                                        onChange={(newValue) => setDate(newValue)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} mt={3}>
                                    <TextField label='ILOŚĆ NADGODZIN (podane w minutach)' fullWidth type="number"
                                               value={duration} required inputProps={{maxLength: 3}} onChange={(e) => setDuration(e.target.value)}
                                               InputProps={{
                                                    inputProps: {
                                                        max: 600, min: 0
                                                    }}}/>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <TextField fullWidth name='description' label='OPIS' multiline minRows='4'
                                               value={reason} onChange={(e) => setReason(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                                <Button type="submit" color="primary" variant="contained" className="buttonCustom" sx={{
                                    margin: "auto"
                                }}><Typography>zapisz</Typography></Button>
                            </Stack>
                        </fieldset>
                    </form>
                </Stack>
            </Box>
        </Grid>
    )
};

export default AdditionalTimeAdd