import {
	WAREHOUSE_ITEM_LOCAL_ADS_STATUSES,
	WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES
} from '../../../../data/consts/warehouse/warehouseItemStatuses';
import { ROLE_ADS, ROLE_KAM, ROLE_WAREHOUSE } from '../../../../data/consts/users';

export const WAREHOUSE_KEEPER_PUMP_ACTIONS = {
	SET_TO_TRASH: {
		id: 'set to trash',
		label: 'do utylizacji',
	},
	SET_TO_SERVICE: {
		id: 'set to service',
		label: 'do serwisu',
	},
	SET_TO_WAREHOUSE: {
		id: 'set to warehouse',
		label: 'do magazynu',
	},
	GET_PDF: {
		id: 'get pdf',
		label: 'pobierz raport',
	}
}

export const ADS_PUMP_ACTIONS = {
	SET_TO_HOSPITAL: {
		id: 'set to hospital',
		label: 'dostarczone'
	},
	SET_TO_WAREHOUSE: {
		id: 'set to warehouse',
		label: 'wyślij do magazynu'
	},
}

export const pumpActionsSetter = (status, role) => {
	if (role === ROLE_WAREHOUSE) {
		switch (status) {
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_INSTITUTION:
				return [WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF];
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_SERVICE:
				return [WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_TRASH, WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_WAREHOUSE, WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF];
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_TRASH:
				return [WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_WAREHOUSE, WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF];
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_WAREHOUSE:
				return [WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_SERVICE, WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_TRASH, WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF];
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_INSTITUTION:
			case WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_WAREHOUSE:
				return [WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_WAREHOUSE, WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_SERVICE, WAREHOUSE_KEEPER_PUMP_ACTIONS.SET_TO_TRASH, WAREHOUSE_KEEPER_PUMP_ACTIONS.GET_PDF];
			default:
				return [];
		}
	}

	if (role === ROLE_ADS || role === ROLE_KAM) {
		switch (status) {
			case WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.AWAITING_RETURN_IN_INSTITUTION:
				return [ADS_PUMP_ACTIONS.SET_TO_HOSPITAL, ADS_PUMP_ACTIONS.SET_TO_WAREHOUSE]
			case WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.IN_INSTITUTION:
				return [ADS_PUMP_ACTIONS.SET_TO_WAREHOUSE]
			default:
				return []
		}
	}

	return [];
}