import React, {useContext, useEffect} from 'react';
import PageTitle from "../../shared/ui/PageTitle";
import {Button, Grid, Typography} from "@mui/material";
import KnowledgeTable from "./KnowledgeTable";
import KnowledgeModal from "./KnowledgeModal";
import {AppContext} from "../../../context/app/appContext";
import {AuthContext} from "../../../context/auth/authContext";
import {ROLE_ADMIN} from "../../../data/consts/users";
import {useModalController} from "../../../hooks/useModalController";

const KnowledgeBase = () => {
    const {appState, getKnowledgeItems} = useContext(AppContext)
    const {role} = useContext(AuthContext)

    const {
        modalId, modalIDHandler, modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
            getKnowledgeItems()
        }
        // eslint-disable-next-line
        , [])

    return (<>
            <div className='knowledgeBase'>
                <PageTitle title="Baza wiedzy:"/>
                <Grid container justifyContent="flex-end" pb={1}>
                    {role === ROLE_ADMIN && <Button color="primary" variant="contained" className="formButton"
                                                    onClick={() => openModal('add')}>dodaj
                        wpis</Button>}
                </Grid>
                {appState.knowledge ? <KnowledgeTable openModal={openModal} hardwareIDHandler={modalIDHandler}/> :
                    <Typography>Brak danych</Typography>}
            </div>
            {modalInfo.isOpen &&
                <KnowledgeModal openModal={openModal} closeModal={closeModal} modalOption={modalInfo.option}
                                productID={modalId}/>}
        </>)
}

export default KnowledgeBase;

