import React, {useCallback, useContext, useEffect, useReducer} from 'react';

import { PoiContext } from '../poi/poiContext';
import { AuthContext } from './authContext';
import { authReducer } from './authReducer';

import axios from '../../axios/axios';
import {AUTH_USER, LOGIN_USER, LOGOUT_USER} from '../types';
import { useNavigate } from 'react-router-dom';

// let logOutTimer;

export const AuthState = ({ children }) => {

	const { setModalError, setGlobalLoader, unsetGlobalLoader, errorCode } = useContext(PoiContext);

	const initialState = {
		isLoggedIn: null,
		id: null,
		token: null,
		role: null,
		name: null,
		surname: null,
		email: null,
		telephone: null,
		hasCoworkers: null,
		checkPassDate: null,
		// tokenExpirationDate: null,
	}

	const [state, dispatch] = useReducer(authReducer, initialState);

	const navigate = useNavigate();

	const login = useCallback((id, role, name, surname, email, telephone, hasCoworkers, checkPassDate) => {

		// const tokenExpirationTime = tokenExpirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 4); //4h
		dispatch({
			type: LOGIN_USER,
			payload: {
				id,
				role,
				name,
				surname,
				email,
				telephone,
				hasCoworkers,
				checkPassDate,
				// tokenExpirationDate: tokenExpirationTime,
			},
		});


	}, [])

	const logout = useCallback(async() => {
		try {
			await axios.post('logout').then((res) => {
				unsetGlobalLoader();
				dispatch({type: LOGOUT_USER});
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}, [setModalError, unsetGlobalLoader])


	useEffect(() => {
		if (errorCode === 401 || errorCode === '401') {
			logout();
		}
	}, [errorCode, logout])

	// useEffect(() => {
	// 	if (state.token && state.tokenExpirationDate) {
	// 		const remainingTime = state.tokenExpirationDate.getTime() - new Date().getTime()
	// 		logOutTimer = setTimeout(logout, remainingTime)
	// 	} else {
	// 		clearTimeout(logOutTimer);
	// 	}
	// }, [state, logout])


	const authUser = async (data) => {
		setGlobalLoader();
		try {
			await axios.post(
				'login_check',
				data,
			).then((res) => {
				unsetGlobalLoader();
				login(res.data.userId, res.data.userRole[0], res.data.userName, res.data.userSurname, res.data.userEmail, res.data.userPhone, res.data.hasCoworkers, res.data.checkPassDate)
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}

	const isAuth = async () => {
		setGlobalLoader();
		try {
			await axios.get('is-auth').then((res) => {
				unsetGlobalLoader();
					if(res.data.userId !== null){
						login(res.data.userId, res.data.userRole[0], res.data.userName, res.data.userSurname, res.data.userEmail, res.data.userPhone, res.data.hasCoworkers, res.data.checkPassDate)
					}
				})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
		dispatch({type: AUTH_USER})
	}

	const changePassword = async (password) => {
		setGlobalLoader();
		const data = {
			password
		}
		try {
			await axios.post(
				`change_password`,
				data,
			).then(res => {
				logout();
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'Twoje hasło zostało zmienione',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}

	const resetPasswordEmail = async (email) => {
		const data = {
			email
		}
		setGlobalLoader()
		try {
			await axios.put(
				'reset_password',
				data,
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'Na Twoją skrzynkę email zostały dostarczone dalsze instrukcje.',
					},
				}
				setModalError(defRes);
				navigate('/')
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	const resetPasswordToken = async (password, token) => {
		const data = {
			password,
			token
		}
		setGlobalLoader()
		try {
			await axios.put(
				`reset_password/${token}`,
				data,
			).then(res => {
				unsetGlobalLoader();
				let defRes = {
					data: {
						code: 200,
						message: 'Twoje hasło zostało zmienione',
					},
				}
				setModalError(defRes);
				navigate('/')
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}

	return (
		<AuthContext.Provider value={{
			isLoggedIn: state.isLoggedIn,
			userId: state.id,
			token: state.token,
			role: state.role,
			hasCoworkers: state.hasCoworkers,
			sendIsAuth: state.sendIsAuth,
			authState: state,
			logout,
			authUser,
			changePassword,
			resetPasswordEmail,
			resetPasswordToken,
			isAuth
		}}>
			{children}
		</AuthContext.Provider>
	)
}