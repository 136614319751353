import React, {useContext} from "react";
import {AppContext} from "../../../context/app/appContext";
import {Box, Paper, Table, TableBody, TableCell, Tooltip} from "@mui/material";
import {AuthContext} from "../../../context/auth/authContext";
import TableContainer from "@mui/material/TableContainer";
import {EnhancedTableHead} from "../../../utils/sortingTable";
import TableRow from "@mui/material/TableRow";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TablePagination from "@mui/material/TablePagination";
import Search from "../../shared/search/Search";
import {headCells} from "./helper";
import {useTableController} from "../../../hooks/useTableController";
import {useSearchParams} from "react-router-dom";

const CertificationDataTable = ({openModal, hardwareIDHandler}) => {
    const {appState} = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const {authState} = useContext(AuthContext)

    const {
        drawArray,
        count,
        searchTerm,
        page,
        rowsPerPage,
        order,
        orderBy,
        isSelected,
        selected,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRequestSort,
        handleSearchChange,
        handleSelectedItem
    } = useTableController(appState.certificates, ['title'], {searchTerm : searchParams.get("search"), orderBy: 'date'})

    return (<>
        <Box justifyContent={'flex-end'} paddingBottom={2} sx={{display: 'flex'}}>
            <Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
        </Box>

        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        {drawArray
                            .map((item, index) => {
                                const isItemSelected = isSelected(item.id);
                                return (<TableRow
                                    hover
                                    onClick={(event) => handleSelectedItem(event, item.id)}
                                    role="row"
                                    aria-rowindex={isItemSelected}
                                    tabIndex={-1}
                                    key={item.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell align={"center"}>
                                        {index + 1 + "."}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {item.title}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {item.datePass}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {item.dateValid}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {item.notCompleted.filter((coach) => coach.id === authState.id).map((el) => !!el ? "Nie ukończono" : '')}
                                        {item.completed.filter((coach) => coach.id === authState.id).map((el) => !!el ? "Ukończono" : '')}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <div className='addUserTable'>
                                            <Tooltip title="Wyświetl"  placement="right">
                                                <button onClick={() => {
                                                    hardwareIDHandler(item)
                                                    openModal('show')
                                                }}><RemoveRedEyeIcon/></button>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>)
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Elementy na stronie"}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>

    </>)
}
export default CertificationDataTable