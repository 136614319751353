import React, {useContext, useEffect, useState} from "react";
import {SettlementsContext} from "../../../context/settlements/settlementsContext";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {implementationPartsNormalizer} from "../../../utils/implementationPartsNormalizer";
import {AuthContext} from "../../../context/auth/authContext";
import {getStyles, MenuProps} from './settlementsData'
import {DatePickerComponent} from "../../shared/ui/DatePickerComponent";

const ImplementationPart = ({procedure, settlementPeriod, form, closeForm, isEditable, patientId}) => {
    const {
        settlementsState,
        editImplementation,
        createImplementation,
        acceptProcedure
    } = useContext(SettlementsContext);
    const {authState: {id: userID}} = useContext(AuthContext);
    const theme = useTheme();
    const [makers, setMakers] = useState([userID]);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [localPartInfo, setLocalPartInfo] = useState(null);
    const [comment, setComment] = useState('');
    const {statusSlug, id: procedureId} = procedure;

    useEffect(() => {
        if (procedureId)
            setMakers(procedure.makers.length ? [...procedure.makers] : [userID])
        setLocalPartInfo(JSON.parse(JSON.stringify(procedure.checkboxes)));
        setComment(procedure.comment);
        setDateStart(new Date(procedure.dateStart));
        setDateEnd(new Date(procedure.dateEnd));
    }, [procedure, procedureId, userID]);

    const changeValueHandler = (procedureIndex, elementIndex, taskIndex, name, value) => {
        const tempArray = JSON.parse(JSON.stringify(localPartInfo));
        tempArray[procedureIndex].values[elementIndex].values[taskIndex][name] = value;
        if (name === "checked" && tempArray[procedureIndex].values[elementIndex].values[taskIndex]["withAmount"] && !value) {
            tempArray[procedureIndex].values[elementIndex].values[taskIndex].amount = null;
        }
        setLocalPartInfo(JSON.parse(JSON.stringify(tempArray)));
    };

    const makersHandler = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "Wszystkie") {
            setMakers(makers.length === settlementsState.makers.length ? [] : [...settlementsState.makers.map((el) => el.id)]);
            return;
        }
        setMakers(value);
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        const type = !form ? "INITIATION_PART_1" : "INITIATION_PART_2"
        !procedureId ? createImplementation(implementationPartsNormalizer(comment, localPartInfo, makers, dateStart, dateEnd, patientId, type), settlementPeriod)
            : isEditable ? editImplementation(implementationPartsNormalizer(comment, localPartInfo, makers, dateStart, dateEnd), procedureId, settlementPeriod)
                : acceptProcedure(procedureId, settlementPeriod)
        closeForm(null)
    };

    return (
        <Grid bgcolor='#f5f5f5' item xs={12} p={1} mt={1} component='form' onSubmit={onSubmitHandler} container>
            <fieldset disabled={!isEditable}>
                <Grid sx={{paddingLeft: "70px"}} container>
                    <Grid item xs={12} lg={6}>
                        <Grid container mb={2} pt={1} sx={{borderBottom: "solid 1px #ccc"}}>
                            <Typography className="settlements__addTitle" sx={{
                                fontWeight: "medium",
                                color: `${!isEditable ? "grey.main" : null}`
                            }}>
                                {!form ? 'Aktywności edukacyjno-szkoleniowe:' : 'Wsparcie merytoryczne dla personelu:'}
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} bgcolor={isEditable ? "magenta.dark" : "grey.dark"} p={1}
                                  sx={{borderRadius: 1}}>
                                <Typography variant='subtitle1' color='white'>
                                    Zakres dat wykonywanych czynności:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent='space-between' pt={3}>
                                {dateStart && <Grid item xs={5}>
                                    <DatePickerComponent
                                        allowSameDateSelection
                                        disabled
                                        disableFuture
                                        label='OD'
                                        value={dateStart}
                                        onChange={() => {
                                        }}
                                    />
                                </Grid>}
                                {dateEnd && <Grid item xs={5}>
                                    <DatePickerComponent
                                        allowSameDateSelection
                                        disabled
                                        disableFuture
                                        label='DO'
                                        value={dateEnd}
                                        onChange={() => {
                                        }}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent='center' pt={3} mb={3} xs={12}>
                            <FormControl fullWidth required>
                                <TextField disabled={!isEditable} fullWidth name='description' label='KOMENTARZ'
                                           multiline
                                           minRows='4' value={comment} onChange={(e) => setComment(e.target.value)}/>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/*DYNAMIC CONTENT*/}
                    {localPartInfo &&
                        localPartInfo.length &&
                        localPartInfo.map((procedure, procedureIndex) => (
                            <Grid container border='1px solid #f5f5f5' key={procedureIndex} mb={2}>
                                <Grid item xs={12} bgcolor={isEditable ? "magenta.dark" : "grey.dark"} p={1}
                                      sx={{borderRadius: 1}}>
                                    <Typography variant='subtitle1' color='white'>
                                        {procedure.group}
                                    </Typography>
                                </Grid>
                                {procedure.values.map((element, elementIndex) => (
                                    <Grid item xs={12} lg={form ? 12 : 6} key={elementIndex} p={1}>
                                        <Grid item xs={8} bgcolor='#f5f5f5' p={1} pl={0}
                                              sx={{borderBottom: "solid 1px #ccc"}}>
                                            <Typography variant='subtitle1'
                                                        sx={{color: !isEditable ? "grey.main" : null}}>{element.group}</Typography>
                                        </Grid>
                                        {element.values.map((task, taskIndex) => (
                                            <Grid item xs={12} container key={taskIndex}>
                                                <Grid item xs={12} pt={2}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={task.name}
                                                                checked={task.checked}
                                                                sx={{p: 0, mr: 2}}
                                                                disabled={!isEditable}
                                                                onChange={(e) => {
                                                                    changeValueHandler(procedureIndex, elementIndex, taskIndex, "checked", e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label={task.label}
                                                    />
                                                </Grid>
                                                {task.withAmount && task.checked && (
                                                    <Grid item xs={3} mt={2}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name={task.amountName}
                                                                label='ILOŚĆ'
                                                                type='number'
                                                                value={task.amount || "0"}
                                                                onChange={(e) => changeValueHandler(procedureIndex, elementIndex, taskIndex, "amount", e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    {/*MAKERS*/}
                    <Grid container item justifyContent='center' mb={2} p={1} xs={5}>
                        <FormControl fullWidth>
                            <InputLabel id='roles-label'>ADS wykonujący czynności</InputLabel>
                            <Select disabled={!isEditable} labelId='roles-label' multiple value={makers}
                                    onChange={makersHandler} input={
                                <OutlinedInput label='ADS wykonujący czynności'/>} MenuProps={MenuProps}>
                                <MenuItem
                                    name='Wszystkie'
                                    value='Wszystkie'
                                    style={{
                                        fontWeight: settlementsState.makers && !!settlementsState.makers.length && !!makers.length && makers.length !== settlementsState.makers.length ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
                                    }}>
                                    Wszystkie
                                </MenuItem>
                                {settlementsState.makers.map((item) => (
                                    <MenuItem key={item.id} value={item.id} style={getStyles(item.name, makers, theme)}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/*BUTTON AREA*/}
                </Grid>
            </fieldset>
            <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                {isEditable &&
                    <Button  type="submit" color="primary" variant="contained" className="buttonCustom"
                            sx={{margin: "auto"}}>{"Zapisz"}</Button>
                }
                {statusSlug === 'waiting' && !procedure.accepted &&
                    <Button type="submit" color="primary" variant="contained" className="buttonCustom"
                            sx={{margin: "auto"}}>{"Akceptuj"}</Button>
                }
            </Stack>
        </Grid>
    );
};

export default ImplementationPart;
