export const headCells = [
    {
        id: 'id',
        label: ' LP',
    },
    {
        id: 'institution',
        label: 'Placówka',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'createdAt',
        label: 'Data zamówienia',
    },
    {
        id: 'notifier',
        label: 'Zamawiający',
    },
    {
        id: 'actions',
        label: 'Działania',
    },
];
