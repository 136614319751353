export const polishMonthName = (month) => {
	let monthName = '';

	switch (month) {
		case '01':
			monthName = 'Styczeń';
			break;
		case '02':
			monthName = 'Luty';
			break;
		case '03':
			monthName = 'Marzec';
			break;
		case '04':
			monthName = 'Kwiecień';
			break;
		case '05':
			monthName = 'Maj';
			break;
		case '06':
			monthName = 'Czerwiec';
			break;
		case '07':
			monthName = 'Lipiec';
			break;
		case '08':
			monthName = 'Sierpień';
			break;
		case '09':
			monthName = 'Wrzesień';
			break;
		case '10':
			monthName = 'Październik';
			break;
		case '11':
			monthName = 'Listopad';
			break;
		case '12':
			monthName = 'Grudzień';
			break;
		default:
			throw new Error('Invalid month');
	}

	return monthName;
}