import * as yup from "yup";
import {isEmail, isPassword, isPhone} from "../../../../utils/helpfunc";

export const headCells = [
	{ id: 'ID', label: 'ID' },
	{ id: 'name', label: 'Imię' },
	{ id: 'surname', label: 'Nazwisko' },
	{ id: 'institutions', label: 'Placówka' },
	{ id: 'phone', label: 'Telefon' },
	{ id: 'roles', label: 'Rola' },
	{ id: 'email', label: 'E-mail' },
	{ id: 'actions', label: 'Działania' }
]

const objectMain = {
	firstName: yup
		.string()
		.required(),
	secondName: yup
		.string()
		.required(),
	institutions: yup
		.array().min(1)
		.required(),
	phoneNumber: yup
		.string()
		.test(isPhone)
		.required(),
	role: yup
		.string()
		.required(),
	email: yup
		.string()
		.test(isEmail)
		.required(),
	type: yup
		.string()
		.required(),
	id: yup
		.string()
		.required(),
	language: yup
		.array().min(1)
		.required(),
	contractNO: yup
		.string()
		.required(),
	dateContract: yup
		.string()
		.required()
}

export const validationSchema = yup.object().shape({...objectMain});

export const validationSchemaAdd = yup.object().shape({...objectMain,
	password: yup
		.string()
		.test(isPassword)
		.required()});

export const userDataInit = {
	firstName: '',
	secondName: '',
	institutions: [],
	phoneNumber: '',
	password: '',
	role: '',
	type: '',
	email: '',
	id: '',
	language: [],
	contractNO: '',
	dateContract: null,
};