import { makeAutoObservable } from 'mobx';

export class WarehouseStore {
	availablePumps = []
	warehouseKeeperOrders = []
	warehouseKeeperPumps = []
	ADSPumps = []

	constructor() {
		makeAutoObservable(this);
	}

	setAvailablePumps(pumps) {
		this.availablePumps = pumps;
	}

	clearAvailablePumps() {
		this.availablePumps = [];
	}

	setWarehouseKeeperOrders(orders) {
		this.warehouseKeeperOrders = orders;
	}

	clearWarehouseKeeperOrders() {
		this.warehouseKeeperOrders = [];
	}

	setWarehouseKeeperPumps(pumps) {
		this.warehouseKeeperPumps = pumps;
	}

	setADSPumps(pumps) {
		this.ADSPumps = pumps;
	}

	clearADSPumps() {
		this.ADSPumps = [];
	}

}