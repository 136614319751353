import React, {useContext} from 'react';
import {
	Box,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import { AuthContext, OrderContext } from '../../../context';
import CheckIcon from '@mui/icons-material/Check';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { userIdentification } from '../../../utils/helpfunc';
import { EnhancedTableHead } from '../../../utils/sortingTable';
import Search from '../../shared/search/Search';
import { EDIT_OPTION, SHOW_OPTION } from '../../../data/consts/modalOptions';
import { ROLE_ADMIN, ROLE_KAM } from '../../../data/consts/users';
import { ACCEPTED, WAITING } from './hardwareOrderStatuses';
import EditIcon from '@mui/icons-material/Edit';
import { useTableController } from '../../../hooks/useTableController';
import { headCells } from './helper';

const HardwareOrdersTable = ({ openModal, currentOrderInfoHandler, potentialMakers }) => {
	const { authState: { role: userRole } } = useContext(AuthContext)
	const { orderState, deleteOrder, acceptOrder, proceedOrder } = useContext(OrderContext);

	const changedOrderList = orderState.ordersList.map(order => {
		return { ...order, notifier: userIdentification(potentialMakers, order.notifier) };
	});

	const {
		drawArray,
		count,
		searchTerm,
		page,
		rowsPerPage,
		order,
		orderBy,
		selected,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange
	} = useTableController(changedOrderList, ['institution.name', 'status', 'notifier'], { orderBy: 'date', rowsPerPage: 50 })

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderState.ordersList.length) : 0;

	return (
		<>
			<Box justifyContent={'flex-end'} paddingBottom={2} sx={{ display: 'flex' }}>
				<Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
			</Box>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size={'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={orderState.ordersList.length}
							headCells={headCells}
						/>
						<TableBody>
							{drawArray.map((row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										role="row"
										tabIndex={-1}
										key={row.id}
									>
										<TableCell
											component="th"
											id={labelId}
											scope="row"
											padding="none"
											align={'center'}
										>
											{index + 1 + '.'}
										</TableCell>
										<TableCell align="center">{row.institution.name}</TableCell>
										<TableCell align={'center'}>
											<Typography component={'div'} variant={'body1'}>
												<Box fontSize={14}
												     sx={{ color: row.status === 'anulowano' ? 'error.main' : row.status === 'zaakceptowano / w realizacji' ? 'success.main' : row.status === 'zrealizowano' ? 'success.main' : 'warning.main' }}>
													{row.status}</Box>
											</Typography>
										</TableCell>
										<TableCell align="center">{row.createdAt}</TableCell>
										<TableCell align={'center'}>
											{row.notifier}
										</TableCell>
										<TableCell align={'center'}>
											<Grid container className="addUserTable" flexDirection="column"
											      alignItems="center">
												{/*SHOW BUTTON*/}
												<Tooltip title={'Podgląd'} placement="right">
													<button
														onClick={() => {
															currentOrderInfoHandler(row)
															openModal(SHOW_OPTION)
														}}
													>
														<RemoveRedEyeIcon/>
													</button>
												</Tooltip>
												{/*EDIT BUTTON*/}
												{
													row.status === WAITING &&
													<Tooltip title={'Edytuj'} placement="right">
														<button
															onClick={() => {
																currentOrderInfoHandler(row)
																openModal(EDIT_OPTION)
															}}>
															<EditIcon/>
														</button>
													</Tooltip>
												}
												{
													row.status === WAITING && (userRole === ROLE_ADMIN || userRole === ROLE_KAM) &&
													<Tooltip title="Akceptuj" placement="right">
														<button
															onClick={() => acceptOrder(row.id)}
														>
															<CloudDoneIcon color={'success'}
															               fontSize={'medium'}
															/>
														</button>
													</Tooltip>
												}
												{
													row.status === WAITING &&
													<Tooltip
														title={userRole === ROLE_ADMIN || userRole === ROLE_KAM ? 'Odrzuć' : 'Usuń'}>
														<button
															onClick={() => deleteOrder(row.id)}
														>
															<DeleteIcon color={'error'} fontSize={'medium'}
															/>
														</button>
													</Tooltip>
												}
												{
													row.status === ACCEPTED && (userRole === ROLE_ADMIN || userRole === ROLE_KAM) &&
													<Tooltip title="Oznacz jako wykonane" placement="right">
														<button
															onClick={() => proceedOrder(row.id)}
														>
															<CheckIcon color={'success'}
															           fontSize={'medium'}
															/>
														</button>
													</Tooltip>
												}
											</Grid>
										</TableCell></TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (53) * emptyRows,
									}}
								>
									<TableCell colSpan={6}/>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[25, 50, 100]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);

}

export default HardwareOrdersTable;