import React, { useCallback, useContext, useEffect, useState } from 'react';
import {useSearchParams} from "react-router-dom";
import { AppContext, AuthContext } from '../../../context';
import {Button, FormControl, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Typography} from '@mui/material';
import PageTitle from '../../shared/ui/PageTitle';
import MainInfo from './MainInfo';
import Line from '../../shared/ui/Line';
import PatientInfo from './PatientInfo';
import DoctorInfo from './DoctorInfo';
import Information from './Information';
import CircumstancesInfo from './CircumstancesInfo';
import PumpSubModule from './PumpSubModule';
import PipeSubModule from './PipeSubModule';
import GelSubModule from './GelSubModule';
import AdverseEventsTable from './AdverseEventsTable';
import DrugTable from './DrugTable';
import MedicalInterviewsTable from './MedicalInterviewsTable';
import { isZipCode } from '../../../utils/helpfunc';
import { dataNormilize } from './dataNormilize';
import {useModalController} from "../../../hooks/useModalController";
import {SHOW_OPTION} from "../../../data/consts/modalOptions";
import {AdverseEventsErrorModal} from './AdverseEventsErrorModal';
import {DuopopaDrugTherapy} from './DuopopaDrugTherapy';
import {countGenderFromInitials} from "./helper";

const AdverseEvents = () => {
	let [searchParams] = useSearchParams();
	const idPatientParam = Number(searchParams.get('idPatient'));
	const serialNumber = Number(searchParams.get('serialNumber'));

	const [patientParam, setPatientParam] = useState(null);
	const { authState } = useContext(AuthContext);
	const { getAdditionalAdverseInfo, sendAdverseEvent, appState } = useContext(AppContext);
	const {
		modalInfo, openModal, closeModal
	} = useModalController();


	const [formErrors, setFormErrors] = useState(null)

	const [pumpsOptions, setPumpsOptions] = useState([])

	const [parts, setParts] =  useState('Reklamacja i zdarzenie');

	const [mainModule, setMainModule] = useState({
		type: 'nowe',
		number: '',
		fromPatient: true,
		fromFamily: false,
		fromHealthCare: false,
		fromAnother: false,
		additionalContact: '',
	})

	const [patientModule, setPatientModule] = useState({
		initials: '',
		gender: '',
		patientId: '',
		age: '',
		dateOfBirth: null,
		dateOfEventConvert: null,
		dateOfEvent: null,
	})


	const [doctorModule, setDoctorModule] = useState({
		contactApprove: '',
		gastrologist: false,
		neurologist: false,
		radiologist: false,
		other: false,
		additionalSpecialization: '',
		name: '',
		address: '',
		voivodeship: '',
		telephone: '',
		city: '',
		zipCode: '',
	})

	const [circumstancesModule, setCircumstancesModule] = useState({
		description: '',
		isFamiliarSeries: '',
		condition: '',
		pump: false,
		pumps: null,
		pumpSNo: null,
		nj: false,
		startNJ: null,
		peg: false,
		lastInstallPeg: null,
		pegSNo: '',
		jejunum: false,
		lastInstallJejunum: null,
		jejunumSNo: '',
		gel: false,
		gelSNo: '',
		anotherProduct: false,
		additionalProduct: '',
		anotherProductReload: 'Tak',
		anotherProductSNo: '',
		anotherProductAccessoriesNo: '',
		complaintSample: 'Tak',
		discarded: false,
		stillInUse: false,
		submitSample: false,
		anotherReason: false,
		additionalReason: '',
		startTreatment: null,
		startPeg: null,
		noPackage: false,
		noAnswer: false,
		noContact: false,
		noContactInfo: false,
		noRegistration: false,
		anotherReasonNS: false,
		additionalReasonNS: '',
		serialNumberDrug: ''
	})

	const [pompSubmodule, setPompSubmodule] = useState({
		isAlarm: 'Tak',
		continuousAlarm: false,
		noConnect: false,
		highPressureAlarm: false,
		fourDigitError: false,
		unspecifiedAlarm: false,
		waterDamage: false,
		physicalDamage: false,
		dropping: false,
		batteryProblem: false,
		batteryCover: false,
		keyboard: false,
		drugDelivery: false,
		cassetteHolder: false,
		display: false,
		onOrOff: false,
		programmingProblem: false,
		feedRate: false,
		usageError: false,
		usageErrorValue: '',
		problemResolved: 'Nie',
		redPlugsRemoved: false,
		batteryReplaced: false,
		reLaunchPomp: false,
		reLaunchCassette: false,
		reLaunchCover: false,
		noDisposable: false,
		replacementPomp: 'Nie',
		replacementPompComment: '',
	});

	const [pipeSubmodule, setPipeSubmodule] = useState({
		isPipeProblem: 'Nie',
		intestinalProbe: false,
		ostomyProbe: false,
		nasoProbe: false,
		extensionPipe: false,
		isAbbVie: false,
		isFreka: false,
		isBostonScientific: false,
		isCook: false,
		isCovidien: false,
		isUnknown: false,
		isAnotherName: false,
		anotherName: '',
		stomaRelated: false,
		blockage: false,
		locationChange: false,
		pegDisconnected: false,
		snapDisconnected: false,
		snapDetachment: false,
		unspecifiedDisconnection: false,
		fold: false,
		entanglement: false,
		isCut: false,
		leakyConnector: false,
		isUsageError: false,
		isUsageErrorValue: '',
		isAnother: false,
		isAnotherValue: '',
		problemResolved: 'Nie',
		rinse: false,
		reconnectConnector: false,
		connectorReplacement: false,
		pegReplaced: false,
		jejunumPipeReplace: false,
		jejunumPipeReplaceDescription: '',
		endoscopicSurgery: false,
		fluoroscopy: false,
		isAnotherEvent: false,
		isAnotherEventValue: false,
	})

	const [gelSubmodule, setGelSubmodule] = useState({
		wasCassetteInUse: 'Tak',
		cassetteCount: 0,
		typeLuer: false,
		innerCase: false,
		outerCase: false,
		caseConnect: false,
		anotherCassetteDamage: false,
		anotherCassetteDamageDescription: '',
		difficultTakeOff: false,
		loose: false,
		broken: false,
		holeInPipe: false,
		brokenLuer: false,
		redLabel: false,
		anotherProblem: false,
		anotherProblemValue: '',
		appearanceDrugProblem: 'Nie',
		isCassetteCoverDamaged: 'Nie',
		cassetteCoverDamageDescription: '',
		problemDescription: '',
		problemTime: '',
		isCassetteChecked: '',
		productStorage: '',
	});

	const [adverseEventsTableValue, setAdverseEventsTableValue] = useState(
		[
			{
				details: '',
				dateStart: null,
				dateEnd: null,
				result: '',
				causation: '',
			},
		],
	)

	const [isDrugTable, setIsDrugTable] = useState('');

	const [drugTableValue, setDrugTableValue] = useState(
		[
			{
				name: '',
				dateStart: null,
				dateEnd: null,
				dosage: '',
				method: '',
				indication: '',
			},
		],
	)

	const [isMedicalInterviewsTable, setIsMedicalInterviewsTable] = useState('');

	const [medicalInterviewsTableValue, setMedicalInterviewsTableValue] = useState(
		[
			{
				period: '',
				dateStart: null,
				dateEnd: null,
				additionalInfo: '',
			},
		],
	)

	const [drugAllergies, setDrugAllergies] = useState('');

	const patientModuleHandler = useCallback((name, value) => {
		setPatientModule(prev => ({ ...prev, [name]: value }))

		if (name === 'initials') {
			const newPatient = appState.adversePatients.find(el => el.patient === value)

			if(newPatient) {
				setPumpsOptions(newPatient.pumps);
				setPatientModule(prev => ({
					...prev,
					gender: countGenderFromInitials(value),
					patientId: newPatient.id
				}))
				setPatientParam(newPatient);
			}

		}

	}, [appState.adversePatients])


	useEffect(() => {

		if(idPatientParam && appState.adversePatients){
			const newPatientParam = appState.adversePatients.find(el => el.id === idPatientParam);
			if(newPatientParam){
				setPatientParam(newPatientParam);
				setCircumstancesModule(prev => ({
					...prev,
					pump: true
				}))
				setPatientModule(prev => ({
					...prev,
					initials: newPatientParam.patient,
					gender: countGenderFromInitials(newPatientParam.patient),
					patientId: idPatientParam
				}))
				setPumpsOptions(newPatientParam.pumps)

				setParts(newPatientParam.isActive ? 'Reklamacja' : 'Reklamacja i zdarzenie')
			}

		}
	}, [appState.adversePatients, idPatientParam, searchParams])

	useEffect(() => {
		const serialNumberParam = searchParams.get('serialNumber');

		if(serialNumberParam && patientParam){

			const findPump = patientParam.pumps.find(pump => pump.serialNumber === serialNumberParam)
			if(findPump){

				setCircumstancesModule(prev => ({
					...prev,
					pumps: [findPump.id],
				}))
			}

		}
	}, [patientParam, searchParams])

	useEffect(() => {
		if(circumstancesModule.isFamiliarSeries === 'Nie'){
			circumstancesModuleHandler('startPeg', '')
		}
	}, [circumstancesModule.isFamiliarSeries])

	useEffect(() => {
		if (patientModule.dateOfBirth && patientModule.dateOfEvent) {
			patientModuleHandler('age', -Math.trunc((patientModule.dateOfBirth.getFullYear() * 12 + patientModule.dateOfBirth.getMonth() - patientModule.dateOfEvent.getFullYear() * 12 - patientModule.dateOfEvent.getMonth()) / 12));
		} else {
			patientModuleHandler('age', 0);
		}

	}, [patientModule.dateOfBirth, patientModule.dateOfEvent, patientModuleHandler])

	useEffect(() => {
		getAdditionalAdverseInfo()
		// eslint-disable-next-line
	}, [])

	const mainModuleHandler = (name, value) => setMainModule(prev => ({ ...prev, [name]: value }))
	const doctorModuleHandler = (name, value) => setDoctorModule(prev => ({ ...prev, [name]: value }))
	const circumstancesModuleHandler = (name, value) => setCircumstancesModule(prev => ({ ...prev, [name]: value }))
	const pompSubmoduleHandler = (name, value) => setPompSubmodule(prev => ({ ...prev, [name]: value }))
	const pipeSubmoduleHandler = (name, value) => setPipeSubmodule(prev => ({ ...prev, [name]: value }))
	const gelSubmoduleHandler = (name, value) => setGelSubmodule(prev => ({ ...prev, [name]: value }))

	const drugTableToggler = (value) => setIsDrugTable(value)
	const medicalInterviewsTableToggler = (value) => setIsMedicalInterviewsTable(value)

	const adverseEventsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		adverseEventsTableValue.forEach(el => tempArray.push({ ...el }))

		if (name === 'dateStart' && adverseEventsTableValue[0].dateEnd !== null && (new Date(adverseEventsTableValue[0].dateEnd) < new Date(value))) {
			tempArray.dateStart = value;
			tempArray.dateEnd = value;
		} else if (name === 'dateEnd' && adverseEventsTableValue[0].dateStart !== null && (new Date(adverseEventsTableValue[0].dateStart) > new Date(value))) {
			tempArray.dateStart = value;
			tempArray.dateEnd = value;
		} else {
			tempArray[index] = { ...el, [name]: value };

		}
		setAdverseEventsTableValue([...tempArray]);
	}

	const drugTableHandler = (name, value, el, index) => {
		const tempArray = [];
		drugTableValue.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setDrugTableValue([...tempArray])
	}

	const medicalInterviewsTableHandler = (name, value, el, index) => {
		const tempArray = [];
		medicalInterviewsTableValue.forEach(el => tempArray.push({ ...el }))
		tempArray[index] = { ...el, [name]: value };
		setMedicalInterviewsTableValue([...tempArray])
	}

	const pumpSelectHandler = (pumps) => {
		setCircumstancesModule(prev => ({
			...prev,
			pumps: pumps.map(pump => pump.id),
		}))
	}

	const delAdverseEventsTableRow = (index) => setAdverseEventsTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const delDrugTableRow = (index) => setDrugTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const delMedicalInterviewsTableRow = (index) => setMedicalInterviewsTableValue(prev => ([...JSON.parse(JSON.stringify(prev.filter((el, i) => i !== index)))]))

	const addAdverseEventsTableRow = () => setAdverseEventsTableValue(prev => ([...prev, {
		details: '',
		dateStart: null,
		dateEnd: null,
		result: '',
		causation: '',
	}]))

	const addDrugTableRow = () => setDrugTableValue(prev => ([...prev, {
		name: '',
		dateStart: null,
		dateEnd: null,
		dosage: '',
		method: '',
		indication: '',
	}]))

	const addMedicalInterviewsTableRow = () => setMedicalInterviewsTableValue(prev => ([...prev, {
		period: '',
		dateStart: null,
		dateEnd: null,
		additionalInfo: '',
	}]))

	const validateHandler = () => {
		let isFormError = false

		if(parts.toLowerCase().includes('reklamacja') && circumstancesModule.isFamiliarSeries === 'Nie' && !(circumstancesModule.noPackage || circumstancesModule.noAnswer || circumstancesModule.noContact || circumstancesModule.noContactInfo|| circumstancesModule.noRegistration || circumstancesModule.anotherReasonNS)){
			setFormErrors('Proszę wybrać powód, gdy numer serii leku jest nieznany.');
			isFormError = true;
		}
		if(parts.toLowerCase().includes('reklamacja') && !(circumstancesModule.pump || circumstancesModule.nj || circumstancesModule.peg || circumstancesModule.jejunum || circumstancesModule.gel ||  circumstancesModule.anotherProduct)){
			setFormErrors('Proszę wybrać conajmniej jeden produkt.');
			isFormError = true;
		}
		if (doctorModule.zipCode && !isZipCode(doctorModule.zipCode)) {
			setFormErrors('Nieprawidłowa wartość kodu pocztowego.');
			isFormError = true;
		}
		if(isFormError){
			openModal(SHOW_OPTION);
		}


		if (!isFormError) {
			setFormErrors(null);
			closeModal();

			return dataNormilize(
				mainModule,
				authState,
				patientModule,
				doctorModule,
				circumstancesModule,
				pompSubmodule,
				pipeSubmodule,
				gelSubmodule,
				adverseEventsTableValue,
				isDrugTable,
				drugTableValue,
				isMedicalInterviewsTable,
				medicalInterviewsTableValue,
				drugAllergies
			);
		}
	}

	const submitHandler = async (e) => {
		e.preventDefault();
		const data = validateHandler();
		if(data){
			await sendAdverseEvent(data);
			await	getAdditionalAdverseInfo();
		}
	}

	return (
		<form onSubmit={(e) => submitHandler(e)} className="adverseEvents">
			<PageTitle title="Formularz zgłaszania zdarzenia niepożądanego:"/>
			<Button style={{position: 'absolute'}} className='adverseEvents__download' color="primary" variant="contained">Pobierz szablon</Button>
			<MainInfo isPatientParam={Boolean(idPatientParam)} mainModuleHandler={mainModuleHandler} mainModule={mainModule}/>
			<Line/>
			<PatientInfo patientModuleHandler={patientModuleHandler} patientModule={patientModule} isPatientParam={Boolean(idPatientParam)} />
			<Line/>
			<DuopopaDrugTherapy
				duopopaDrugTherapyModule={circumstancesModule}
				duopopaDrugTherapyHandler={circumstancesModuleHandler}
				isPatientSelected={patientModule.initials !== ''}
				/>
			<Line/>
			<DoctorInfo doctorModuleHandler={doctorModuleHandler} doctorModule={doctorModule}/>
			<Line/>

			<Grid container border={`1px solid #071d49`} mb={2}>
				<Grid item xs={12} lg={12} p={2}>
					<RadioGroup
						display={'flex'}
						value={parts}
						onChange={(e) => setParts(e.target.value)}
					>
						<FormControlLabel value="Reklamacja" control={<Radio/>} label="Reklamacja"/>
						<FormControlLabel disabled={Boolean(idPatientParam)} value="Zdarzenie" control={<Radio/>} label="Zdarzenie"/>
						<FormControlLabel value="Reklamacja i zdarzenie" control={<Radio/>} label="Reklamacja i zdarzenie"/>
					</RadioGroup>
				</Grid>
			</Grid>

			{parts.toLowerCase().includes('reklamacja') && <><Typography color="#00f">Część 1: Informacje dotyczące reklamacji</Typography>
				<Typography mb={1}>Formularz musi zawierać wszystkie strony. Należy zastosować format daty:
					DD/MM/RRRR</Typography>
				<CircumstancesInfo
					circumstancesModuleHandler={circumstancesModuleHandler}
					circumstancesModule={circumstancesModule}
					isPatientParam={Boolean(idPatientParam)}
				/>
				<Line/>
				<Grid container p={2} border={`1px solid #071d49`} direction="row" wrap="nowrap">
					<Typography variant="h6" color="red" mr={1}>Uwaga:</Typography>
					<Typography variant="h6">Próbki żelu, którego wygląd wzbudził zastrzeżenia, należy przechowywać w
						lodówce do momentu przekazania ich AbbVie.</Typography>
				</Grid>
				<Line/>
				{
					circumstancesModule.pump &&
					<>
						<PumpSubModule serialNumberParam={serialNumber} isPatientParam={Boolean(idPatientParam)} pompSubmodule={pompSubmodule} pompSubmoduleHandler={pompSubmoduleHandler} pumpsOptions={pumpsOptions} pumpSelectHandler={pumpSelectHandler}/>
						<Line/>
					</>
				}
				{
					circumstancesModule.nj || circumstancesModule.peg || circumstancesModule.jejunum || circumstancesModule.anotherProduct ?
						<>
							<PipeSubModule pipeSubmodule={pipeSubmodule} pipeSubmoduleHandler={pipeSubmoduleHandler}/>
							<Line/>
						</>
						: null
				}
				{
					circumstancesModule.gel &&
					<>
						<GelSubModule gelSubmodule={gelSubmodule} gelSubmoduleHandler={gelSubmoduleHandler}/>
						<Line/>
					</>
				}
			</>}
			{parts.toLowerCase().includes('zdarzenie') && <>
				<Typography color="#00f" mb={2}>Część 2: Zdarzenie niepożądane</Typography>
				<AdverseEventsTable
					adverseEventsTableValue={adverseEventsTableValue}
					adverseEventsTableHandler={adverseEventsTableHandler}
					addAdverseEventsTableRow={addAdverseEventsTableRow}
					delAdverseEventsTableRow={delAdverseEventsTableRow}
				/>
				<Line/>
				</>
			}


			<DrugTable
				isDrugTable={isDrugTable}
				addDrugTableRow={addDrugTableRow}
				delDrugTableRow={delDrugTableRow}
				drugTableHandler={drugTableHandler}
				drugTableToggler={drugTableToggler}
				drugTableValue={drugTableValue}
			/>
			<Line/>
			<MedicalInterviewsTable
				isMedicalInterviewsTable={isMedicalInterviewsTable}
				addMedicalInterviewsTableRow={addMedicalInterviewsTableRow}
				delMedicalInterviewsTableRow={delMedicalInterviewsTableRow}
				medicalInterviewsTableHandler={medicalInterviewsTableHandler}
				medicalInterviewsTableToggler={medicalInterviewsTableToggler}
				medicalInterviewsTableValue={medicalInterviewsTableValue}
			/>
			<Line/>
			<Grid p={2} border={`1px solid #071d49`} container justifyContent="center">
				<Grid item xs={12} lg={6}>
					<FormControl fullWidth>
						<Typography variant="subtitle1">Alergie na leki:</Typography>
						<OutlinedInput
							id="allergia"
							name="allergia"
							value={drugAllergies}
							onChange={(e) => setDrugAllergies(e.target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Line/>
			<Information/>
			<Grid container justifyContent="center" direction="column" alignItems="center">
				<Typography variant="h4" mb={2} mt={2}>KONIEC FORMULARZA</Typography>
				<Button color="primary" variant="contained" type="submit">Wyślij</Button>
			</Grid>
			{modalInfo.isOpen && <AdverseEventsErrorModal
				closeModal={closeModal}
				message={formErrors}
			/>}
		</form>
	)
}

export default AdverseEvents;

