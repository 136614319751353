import React, { useContext } from 'react';
import { PatientContext } from '../../../context';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import {EnhancedTableHead} from '../../../utils/sortingTable';
import { Box } from '@mui/material';
import Search from '../../shared/search/Search';
import {DELETE_OPTION, EDIT_OPTION, SHOW_OPTION} from '../../../data/consts/modalOptions';
import { useTableController} from '../../../hooks/useTableController';
import { headCells } from './helper';
import {useSearchParams} from "react-router-dom";
import { getObjectsValues} from "../../../utils/arrayOfObjects";

const keysFilter= ['status', 'institution', 'displayName'];

const PatientsDataTable = ({ openModal, userIDHandler }) => {
	// const { role } = useContext(AuthContext);
	let [searchParams] = useSearchParams();
	const { patientState } = useContext(PatientContext);

	const {
		drawArray,
		count,
		searchTerm,
		filters,
		page,
		rowsPerPage,
		order,
		orderBy,
		handleChangePage,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleSearchChange,
		filterChangeHandler
	} = useTableController(patientState.patients, keysFilter,
		{
			searchTerm : searchParams.get("search"),
			orderBy: 'displayName',
			filters: [
				{
					name: 'institution',
					values: getObjectsValues(patientState.patients, 'institution'),
					selectedValue: '',
				},
				{
					name: 'createdBy',
					values: getObjectsValues(patientState.patients, 'createdBy'),
					selectedValue: '',
				},
				{
					name: 'status',
					values: getObjectsValues(patientState.patients, 'status'),
					selectedValue: !searchParams.get("search") ? 'Aktywny' : '',
				},
			]
		})

	return (
		<>
			<Box justifyContent={'flex-end'} padding={1} sx={{ display: 'flex' }}>
				<Search searchTerm={searchTerm} handleSearchChange={handleSearchChange}/>
			</Box>

			<Paper sx={{ width: '100%', overflow: 'hidden' }}>

				<TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={patientState.patients.length}
							headCells={headCells}
							filters={filters}
							onFilterChange={filterChangeHandler}
						/>
						<TableBody>
							{
								drawArray.length > 0 &&
								drawArray.map(row => {
									return (
										<TableRow
											hover
											role="row"
											tabIndex={-1}
											key={row.id}
										>
											<TableCell
												sx={{ textAlign: 'center' }}
											>
												{row.id}
											</TableCell>
											<TableCell
												sx={{ textAlign: 'center' }}
											>
												{row.displayName}
											</TableCell>
											<TableCell>
												{row.institution}
											</TableCell>
											<TableCell
												sx={{ textAlign: 'center' }}
											>
												{row.createdBy}
											</TableCell>
											<TableCell
												sx={{ textAlign: 'center' }}
											>
												{row.status}
											</TableCell>
											<TableCell
											>
												<div
													className="addUserTable"
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center'
													}}
												>
													{row.status === 'Aktywny' && <button
														onClick={() => {
															userIDHandler(row.id)
															openModal(EDIT_OPTION)
														}}>
														<EditIcon/>
													</button>}
													<button
														onClick={() => {
															userIDHandler(row.id)
															openModal(SHOW_OPTION)
														}}>
														<RemoveRedEyeIcon/>
													</button>
													<button
														onClick={() => {
															userIDHandler(row.id)
															openModal(DELETE_OPTION)
														}}>
														<DeleteIcon/>
													</button>
												</div>
											</TableCell>
										</TableRow>
									)
								})
							}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={count}

					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage={'Elementy na stronie'}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	)
		;
}

export default PatientsDataTable;