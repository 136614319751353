import React, {useContext} from 'react'
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {HardwareContext} from '../../../context/hardware/hardwareContext';
import DeleteIcon from '@mui/icons-material/Delete';

const HardwareOrdersModalTable = ({orderDetails, delOrderDetailsEl, modalOption}) => {

    const {hardwareState: {hardware}} = useContext(HardwareContext)

    const columns = ['Nazwa', 'Kod', 'Rozmiar', 'ilość', 'Działania']
    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>

            {
                orderDetails
                    ?
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column, index) => (
                                            <TableCell align={"center"}
                                                       key={index}
                                            >
                                                {column}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!orderDetails.length &&
                                    orderDetails.map((el, elIndex) => {
                                            const elDraw = hardware.find(elProduct => elProduct.id === el.product)
                                            const detailElDraw = elDraw.details.find(elDetail => elDetail.id === el.detail)

                                            return (
                                                <TableRow key={elIndex}>
                                                    <TableCell align="center">{elDraw.name}</TableCell>
                                                    <TableCell align="center">{detailElDraw.code}</TableCell>
                                                    <TableCell align="center">{detailElDraw.size}</TableCell>
                                                    <TableCell align="center">{el.amount}</TableCell>
                                                    <TableCell align="center">
                                                        <div className="addUserTable">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    delOrderDetailsEl(elIndex)
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        },
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Grid item container flexDirection={'column'} m={2}>
                        <Typography variant={"subtitle1"}>Szczegóły zamówienia:</Typography>
                        <Typography variant={'subtitle2'}>Brak produktów. Dodaj produkty do zamówienia.</Typography>
                    </Grid>

            }

        </Paper>
    )
}

export default HardwareOrdersModalTable;