import React, {useEffect, useState} from 'react';
import {
	Box, Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid, List, ListItem, MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup, Select,
	Typography,
} from '@mui/material';

const PumpSubModule = ({serialNumberParam, isPatientParam, pompSubmodule, pompSubmoduleHandler, pumpsOptions, pumpSelectHandler}) => {
	const [pumpsOptionsState, setPumpsOptionsState] = useState([])
	const [selectedPump, setSelectedPump] = useState('')
	const [addedPumps, setAddedPumps] = useState([]);

	useEffect(() => {
		setPumpsOptionsState(pumpsOptions)
		setAddedPumps([])
		setSelectedPump(serialNumberParam ?? '')
	}, [pumpsOptions, serialNumberParam])

	const addPumpHandler = () => {
		if(selectedPump !== ''){
			setPumpsOptionsState(prevState => prevState.filter(pump => pump.serialNumber !== selectedPump))
			setAddedPumps(prevState => {
				const findPump = pumpsOptions.find(pump => pump.serialNumber === selectedPump);
				const newAddedPumps = [...prevState, findPump];
				pumpSelectHandler(newAddedPumps);
				return newAddedPumps;
			});
			setSelectedPump('');
		}
	}

	const deletePumpHandler = (serialNumber) => {
		setAddedPumps(prevState => {
			const newAddedPumps =  prevState.filter(pump => pump.serialNumber !== serialNumber);
			pumpSelectHandler(newAddedPumps);
			return newAddedPumps;
		});
		setPumpsOptionsState(prevState => {
			const findPump = pumpsOptions.find(pump => pump.serialNumber === serialNumber);
			return [...prevState, findPump];
		})
	}

	return (
		<Grid container border={`1px solid #071d49`} display="flex" direction="row">
			<Grid item className="subMenuSidebar">Pompa</Grid>
			<Grid item flexGrow={1} maxWidth="calc(100% - 100px)" container direction="column">
				<Grid container borderBottom={`1px solid #071d49`}>

					{pumpsOptions.length > 0 && <>
						<Grid item xs={6} p={2}>
							<Typography variant="subtitle1">Numer seryjny pompy: </Typography>

							<Select
								labelId="addUser-label"
								name="pumpId"
							  disabled={serialNumberParam || pumpsOptionsState.length === 0}
								value={selectedPump}
								onChange={(e) => setSelectedPump(e.target.value)}
								sx={{width: 300}}>
								{pumpsOptionsState.map((el => (
									<MenuItem
										key={el}
										value={el.serialNumber}
									>
										{el.serialNumber}
									</MenuItem>
								)))}
							</Select>
							<Button
								disabled={serialNumberParam || pumpsOptionsState.length === 0}
								variant="contained"
								color="success"
								className="formButton"
								onClick={addPumpHandler}>
								dodaj pompe
							</Button>
						</Grid>
						<Grid item xs={6} p={2}>
							{addedPumps.length > 0 && <><Typography variant="subtitle1">Dodane pompy: </Typography>
							<List>
								{addedPumps.map(pump =>
									<ListItem key={pump.id} width={'200px'} sx={{display: 'flex', justifyContent: 'space-between', flexWrap: 'no-wrap', borderBottom: '1px solid #071D49'}}>
										<span>{pump.serialNumber}</span>
										<Button
											variant="contained"
											color="success"
											className="formButton"
											onClick={() => deletePumpHandler(pump.serialNumber)}
										>
											usuń
										</Button>
									</ListItem>
								)}
							</List></>}
						</Grid>
					</>}

					<Grid item xs={12} lg={6} p={2}>
						<Typography variant="subtitle1">Czy pompa wyświetliła alarm lub komunikat o
							błędzie?</Typography>
						<RadioGroup
							name="isAlarm"
							value={pompSubmodule.isAlarm}
							onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
						>
							<FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
							<FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
						</RadioGroup>
					</Grid>
					{
						pompSubmodule.isAlarm === 'Tak' && <Grid item xs={12} lg={6} p={2}>
							<Typography variant="subtitle1">Proszę zaznaczyć odpowiedni rodzaj alarmu i opisać go
								szczegółowo, uwzględniając wszystkie kody błędu i wyświetlone komunikaty:</Typography>
							<FormGroup>
								<FormControlLabel control={
									<Checkbox
										name="continuousAlarm"
										checked={pompSubmodule.continuousAlarm}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="alarm ciągły"/>
								<FormControlLabel control={
									<Checkbox
										name="noConnect"
										checked={pompSubmodule.noConnect}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="brak podłączonego zestawu jednorazowego"/>
								<FormControlLabel control={
									<Checkbox
										name="highPressureAlarm"
										checked={pompSubmodule.highPressureAlarm}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="alarm wysokiego ciśnienia"/>
								<FormControlLabel control={
									<Checkbox
										name="fourDigitError"
										checked={pompSubmodule.fourDigitError}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="4-cyfrowy kod błędu"/>
								<FormControlLabel control={
									<Checkbox
										name="unspecifiedAlarm"
										checked={pompSubmodule.unspecifiedAlarm}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="niesprecyzowany alarm / komunikat"/>
							</FormGroup>
						</Grid>
					}
				</Grid>
				<Grid item container borderBottom={`1px solid #071d49`}>
					<Grid item xs={12} p={2}>
						<Typography variant="subtitle1">Czy problem z pompą ma charakter fizyczny, funkcjonalny,
							związany z oprogramowaniem? Proszę zaznaczyć właściwy problem:</Typography>
					</Grid>
					<Grid item xs={12} lg={6} p={2}>
						<Typography variant="subtitle1">Fizyczny:</Typography>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="waterDamage"
									checked={pompSubmodule.waterDamage}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="uszkodzenie na skutek działania wody / płynu"/>
							<FormControlLabel control={
								<Checkbox
									name="physicalDamage"
									checked={pompSubmodule.physicalDamage}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="uszkodzenie fizyczne"/>
							<FormControlLabel control={
								<Checkbox
									name="dropping"
									checked={pompSubmodule.dropping}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="upuszczenie"/>
						</FormGroup>
					</Grid>
					<Grid item xs={12} lg={6} p={2}>
						<Typography variant="subtitle1">Funkcjonalny:</Typography>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="batteryProblem"
									checked={pompSubmodule.batteryProblem}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="problem z akumulatorem"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="batteryCover"
									checked={pompSubmodule.batteryCover}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="pokrywa akumulatora"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="keyboard"
									checked={pompSubmodule.keyboard}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="klawiatura"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="drugDelivery"
									checked={pompSubmodule.drugDelivery}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="problem z podawaniem leku"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="cassetteHolder"
									checked={pompSubmodule.cassetteHolder}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="mocowanie kasetki"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="display"
									checked={pompSubmodule.display}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="wyświetlacz"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="onOrOff"
									checked={pompSubmodule.onOrOff}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="włączanie lub wyłączanie"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="programmingProblem"
									checked={pompSubmodule.programmingProblem}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="problem z programowaniem"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="feedRate"
									checked={pompSubmodule.feedRate}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="widoczna lub słyszalna zmiana prędkości podawania"/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="usageError"
									checked={pompSubmodule.usageError}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
								/>
							} label="błąd użytkowania / inny (należy podać, jaki):"/>
						</FormGroup>
						<Box minHeight={56}>
							{
								pompSubmodule.usageError && <FormControl fullWidth required>
									<OutlinedInput
										id="usageErrorValue"
										name="usageErrorValue"
										value={pompSubmodule.usageErrorValue}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
									/>
								</FormControl>
							}
						</Box>
					</Grid>
				</Grid>
				<Grid item container borderBottom={`1px solid #071d49`}>
					<Grid item xs={12} lg={6} p={2}>
						<Typography variant="subtitle1">Czy problem udało się rozwiązać?</Typography>
						<RadioGroup

							name="problemResolved"
							value={isPatientParam ? "Nie" : pompSubmodule.problemResolved}
							onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
						>
							<FormControlLabel disabled={isPatientParam} value="Tak" control={<Radio/>} label="Tak"/>
							<FormControlLabel disabled={isPatientParam} value="Nie" control={<Radio/>} label="Nie"/>
						</RadioGroup>
					</Grid>
					{
						pompSubmodule.problemResolved === 'Tak' && <Grid item xs={12} lg={6} p={2}>
							<Typography variant="subtitle1">Jeżeli problem został rozwiązany, to, w jaki
								sposób?</Typography>
							<FormGroup>
								<FormControlLabel control={
									<Checkbox
										name="redPlugsRemoved"
										checked={pompSubmodule.redPlugsRemoved}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="Otwarto zaciski, usunięto czerwone zatyczki."/>
								<FormControlLabel control={
									<Checkbox
										name="batteryReplaced"
										checked={pompSubmodule.batteryReplaced}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="Wymieniono baterie w pompie."/>
								<FormControlLabel control={
									<Checkbox
										name="reLaunchPomp"
										checked={pompSubmodule.reLaunchPomp}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="Wyłączono i ponownie włączono pompę."/>
								<FormControlLabel control={
									<Checkbox
										name="reLaunchCassette"
										checked={pompSubmodule.reLaunchCassette}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								}
								                  label="Wyjęto i włożono ponownie tę samą kasetkę (jeżeli włożono nową kasetkę, należy zaznaczyć to w formularzu)."/>
								<FormControlLabel control={
									<Checkbox
										name="reLaunchCover"
										checked={pompSubmodule.reLaunchCover}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								} label="Pokrywa akumulatora nie została poprawnie zamknięta przez użytkownika."/>
								<FormControlLabel control={
									<Checkbox
										name="noDisposable"
										checked={pompSubmodule.noDisposable}
										onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.checked)}
									/>
								}
								                  label="Pompa wyświetla komunikat „No Disposable” i nie daje się uruchomić: zatrzymano pompę, zamocowano kasetkę i pompa wznowiła pracę bez problemu."/>
							</FormGroup>
						</Grid>
					}
				</Grid>
				<Grid item container>
					<Grid item xs={12} lg={6} p={2}>
						<Typography variant="subtitle1">Czy dostępna była pompa zastępcza?</Typography>
						<RadioGroup
							name="replacementPomp"
							value={pompSubmodule.replacementPomp}
							onChange={(e) => {
								pompSubmoduleHandler(e.target.name, e.target.value)
								pompSubmoduleHandler('replacementPompComment', '')
							}}
						>
							<FormControlLabel value="Tak" control={<Radio/>} label="Tak"/>
							<FormControlLabel value="Nie" control={<Radio/>} label="Nie"/>
						</RadioGroup>
					</Grid>
					{
						pompSubmodule.replacementPomp === 'Tak' && <Grid item xs={12} lg={6} p={2}>
							<Typography variant="subtitle1">Jeśli tak, czy było to pomocne?</Typography>
							<FormControl fullWidth required>
								<OutlinedInput
									id="replacementPompComment"
									name="replacementPompComment"
									value={pompSubmodule.replacementPompComment}
									onChange={(e) => pompSubmoduleHandler(e.target.name, e.target.value)}
								/>
							</FormControl>
						</Grid>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default PumpSubModule;