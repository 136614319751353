import React from 'react'
import { observer } from 'mobx-react-lite';
import { useOrderSelectViewModel } from './useOrderSelect.vm';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';

export const OrderTableSelect = observer(({ orderProductModel, orderProductId }) => {

	const {
		availableCurrentPumps,
		changePumpIdHandler,
		pumpId,
		buttonClickHandler,
	} = useOrderSelectViewModel(orderProductModel, orderProductId)

	return availableCurrentPumps.length > 0
		? (
			<Grid
				container
				alignItems="center"
				justifyContent="flex-end"
				flexWrap='nowrap'
			>
				<Select
					size="small"
					name="pump"
					value={pumpId}
					onChange={changePumpIdHandler}
					sx={{ width: '200px', marginRight: '8px' }}
				>
					{
						availableCurrentPumps.map(pump => (
							<MenuItem key={pump.id} value={pump.id}>
								{pump.serialNumber}
							</MenuItem>
						))
					}
				</Select>
				<Button
					className="formButton"
					variant="contained"
					color="success"
					onClick={buttonClickHandler}
				>
					Dodaj
				</Button>
			</Grid>
		)
		: (
			<Typography textAlign='center'>
				Brak dostepnego produktu
			</Typography>
		)
})