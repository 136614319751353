import { makeAutoObservable } from "mobx";


export class LoadersStore {
	isGlobalLoader = false;
	isLocalLoader = false;
	constructor() {
		makeAutoObservable(this);
	}

	setGlobalLoader = () => (this.isGlobalLoader = true);
	unsetGlobalLoader = () => (this.isGlobalLoader = false);
	setLocalLoader = () => (this.isLocalLoader = true);
	unsetLocalLoader = () => (this.isLocalLoader = false);
}