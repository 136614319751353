import { useCallback } from 'react';
import { useApiCall } from '../apiCall/useApiCall';
import { useNotificationStore, useWarehouseStore } from '../../store/hooks';
import { NOTIFICATION_TYPES } from '../../store/notificationStore/notificationType';
import { statusSetter } from './statusSetter';
import { dateFormat } from '../../utils/helpfunc';
import {
	WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES,
	warehouseWarehouseKeeperItemLabels
} from '../../data/consts/warehouse/warehouseItemStatuses';

export const useWarehouseApi = () => {
	const { api } = useApiCall()
	const notificationStore = useNotificationStore();
	const warehouseStore = useWarehouseStore();

	const getWarehouseKeeperOrders = useCallback(async () => {
		const res = await api.apiInstance.get('warehouse-items/orders', true)

		if (res) {
			const customWarehouseOrders = res.orders.map(order => ({
				...order,
				status: statusSetter(order.products),
				createdAt: dateFormat(new Date(order.createdAt))
			}))
			warehouseStore.setWarehouseKeeperOrders(customWarehouseOrders)
			warehouseStore.setAvailablePumps(res.availablePumps)
		}

	}, [api.apiInstance, warehouseStore])

	const uploadFile = useCallback(async (file) => {
		const formData = new FormData();
		formData.append('file', file);

		const res = await api.apiInstance.post('warehouse-file', formData, true);
		if (res) {
			warehouseStore.setAvailablePumps(res.availablePumps)

			notificationStore.setNotification({
				// message: 'Plik został poprawnie zaimportowany, załaduj kolejny albo zamknij formularz',
				message: res.message,
				type: NOTIFICATION_TYPES.SUCCESS
			})
			void getWarehouseKeeperOrders();
			return true;
		}
	}, [api.apiInstance, notificationStore, warehouseStore, getWarehouseKeeperOrders])

	const getWarehouseKeeperPumps = useCallback(async () => {
		const res = await api.apiInstance.get('warehouse-items/warehouse-keeper', true)

		if (res) {
			warehouseStore.setWarehouseKeeperPumps(res.map(pump => ({
				...pump,
				status: warehouseWarehouseKeeperItemLabels(pump.status)
			})))
		}

	}, [api.apiInstance, warehouseStore])

	const getADSPumps = useCallback(async () => {
		const res = await api.apiInstance.get('warehouse-items/ads', true)

		if (res) {
			warehouseStore.setADSPumps(res.map(pump => ({
				...pump,
				status: warehouseWarehouseKeeperItemLabels(pump.status)
			})))
		}
	}, [api.apiInstance, warehouseStore])

	const addSerialNumberToPump = useCallback(async (orderProductId, pumpSerialNumber) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}`, { orderProductId }, true);
		if (res) {
			await getWarehouseKeeperOrders();
		}
	}, [api.apiInstance, getWarehouseKeeperOrders])

	const addPumpToTrash = useCallback(async (pumpSerialNumber) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}/to-trash`, {}, true);

		if (res) {
			const newWarehouseKeeperPumps = JSON.parse(JSON.stringify(warehouseStore.warehouseKeeperPumps))
				.map(pump => pumpSerialNumber === pump.id
					? {
						...pump,
						status: WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_TRASH
					}
					: pump
				)
			warehouseStore.setWarehouseKeeperPumps(newWarehouseKeeperPumps)
			notificationStore.setNotification({
				message: 'pompa została zutylizowana',
				type: NOTIFICATION_TYPES.SUCCESS
			})
		}
	}, [api.apiInstance, notificationStore, warehouseStore])

	const addPumpToService = useCallback(async (pumpSerialNumber, number, date, comment) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}/to-service`, { number, date, comment }, true);
		if (res) {
			const newWarehouseKeeperPumps = JSON.parse(JSON.stringify(warehouseStore.warehouseKeeperPumps))
				.map(pump => pumpSerialNumber === pump.id
					? {
						...pump,
						status: WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_SERVICE
					}
					: pump
				)
			warehouseStore.setWarehouseKeeperPumps(newWarehouseKeeperPumps)
			notificationStore.setNotification({
				message: 'pompa została wysłana do serwisu',
				type: NOTIFICATION_TYPES.SUCCESS
			})
		}
	}, [api.apiInstance, notificationStore, warehouseStore])

	const addPumpToWarehouse = useCallback(async (pumpSerialNumber) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}/to-warehouse`, {}, true);
		if (res) {
			const newWarehouseKeeperPumps = JSON.parse(JSON.stringify(warehouseStore.warehouseKeeperPumps))
				.map(pump => pumpSerialNumber === pump.id
					? {
						...pump,
						status: WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_WAREHOUSE
					}
					: pump
				)
			warehouseStore.setWarehouseKeeperPumps(newWarehouseKeeperPumps)
			notificationStore.setNotification({
				message: 'pompa została zwrócona do magazynu',
				type: NOTIFICATION_TYPES.SUCCESS
			})
		}
	}, [api.apiInstance, notificationStore, warehouseStore])

	const setPumpToHospital = useCallback(async (pumpSerialNumber) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}/to-institution`, {}, true);

		if (res) {
			const newADSPumps = JSON.parse(JSON.stringify(warehouseStore.ADSPumps))
				.map(pump => pumpSerialNumber === pump.id
					? {
						...pump,
						status: WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_INSTITUTION
					}
					: pump
				)
			warehouseStore.setADSPumps(newADSPumps)
			notificationStore.setNotification({
				message: 'pompa została dodana do magazynu szpitala',
				type: NOTIFICATION_TYPES.SUCCESS
			})
		}

	}, [api.apiInstance, notificationStore, warehouseStore])

	const getPdfLogByWarehouseKeeper = useCallback(async (pumpId) => {
			const res = await api.apiInstance.get(`warehouse-item-log/${pumpId}/pdf`, true, { responseType: 'blob' })
			if (res) {
				const blob = new Blob(
					[res],
					{
						type: 'application/pdf',
					});
				const fileURL = URL.createObjectURL(blob);
				window.open(fileURL);
			}
		}, [api.apiInstance]
	)

	const setPumpToWarehouseByAds = useCallback(async (pumpSerialNumber, reason) => {
		const res = await api.apiInstance.put(`warehouse-item/${pumpSerialNumber}/to-awaiting-return-in-warehouse`, { reason }, true);

		if (res) {
			const newADSPumps = JSON.parse(JSON.stringify(warehouseStore.ADSPumps))
				.map(pump => pumpSerialNumber === pump.id
					? {
						...pump,
						status: WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_WAREHOUSE
					}
					: pump
				)
			warehouseStore.setADSPumps(newADSPumps)
			notificationStore.setNotification({
				message: 'pompa została wysłana do magazyniera',
				type: NOTIFICATION_TYPES.SUCCESS
			})
			return true;
		}
	}, [api.apiInstance, notificationStore, warehouseStore])

	return {
		uploadFile,
		getWarehouseKeeperOrders,
		addSerialNumberToPump,
		getWarehouseKeeperPumps,
		getADSPumps,
		addPumpToTrash,
		addPumpToService,
		addPumpToWarehouse,
		setPumpToHospital,
		setPumpToWarehouseByAds,
		getPdfLogByWarehouseKeeper
	}
}