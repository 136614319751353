import React, {useContext, useEffect, useState} from 'react';
import PageTitle from '../../shared/ui/PageTitle';
import {Button, Grid, Typography} from '@mui/material';
import HardwareOrdersTable from './HardwareOrdersTable';
import HardwareOrdersModal from './HardwareOrdersModal';
import {OrderContext} from '../../../context/orders/orderContext';
import {HardwareContext} from '../../../context/hardware/hardwareContext';
import {uniqUsers} from "../../../utils/helpfunc";
import {useModalController} from "../../../hooks/useModalController";

const HardwareOrders = () => {

    const {getProductsList, hardwareState} = useContext(HardwareContext)
    const {orderState, getOrdersList} = useContext(OrderContext)
    const [currentOrderInfo, setCurrentOrderInfo] = useState(null)
    const [potentialMakers, setPotentialMakers] = useState([]);

    const {
        modalInfo, openModal, closeModal
    } = useModalController();

    useEffect(() => {
        getOrdersList()
        getProductsList()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (hardwareState.formSet) {
            setPotentialMakers([...uniqUsers(hardwareState.formSet)])
        }
    }, [hardwareState.formSet])

    const currentOrderInfoHandler = (order) => setCurrentOrderInfo(order)

    return (
        <>
            <div className="hardwareOrders">
                <PageTitle title="Zamówienia sprzętowe:"/>
                <Grid container justifyContent="flex-end" pb={1}>
                    <Button color="primary" variant="contained" className="formButton" onClick={() => openModal('add')}>dodaj
                        zamówienie</Button>
                </Grid>

                {orderState.ordersList
                    ?
                    <HardwareOrdersTable
                        openModal={openModal}
                        potentialMakers={potentialMakers}
                        currentOrderInfoHandler={currentOrderInfoHandler}
                    />
                    :
                    <Typography>Brak danych</Typography>
                }
            </div>
            {modalInfo.isOpen &&
                <HardwareOrdersModal
                    currentOrderInfo={currentOrderInfo}
                    openModal={openModal}
                    closeModal={closeModal}
                    modalOption={modalInfo.option}
                />}

        </>
    )
}

export default HardwareOrders
