import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { AuthContext } from '../../../../context';
import SideBar from '../../SideBar';
import Header from '../../Header';
import Content from '../../Content';
import Footer from '../../Footer';
import GlobalLoader from '../../ui/GlobalLoader';
import userRoutes from '../../../../data/uiSettings/userRoutes';
import userLinks from '../../../../data/uiSettings/userLinks';

export const ContentLayer = () => {

	const { sendIsAuth, isLoggedIn, authState } = useContext(AuthContext);
	const [routes, setRoutes] = useState(null);
	const [menuLinks, setMenuLinks] = useState(null);

	useEffect(() => {
		if (sendIsAuth) {
			setRoutes(userRoutes(isLoggedIn, authState.role, authState.checkPassDate))
			setMenuLinks(userLinks(isLoggedIn, authState.role, authState.checkPassDate, authState.name, authState.surname))
		}

	}, [isLoggedIn, authState, sendIsAuth])

	return menuLinks !== null && routes !== null ? (
			<Grid
				className="app"
			>
				<Grid className="app__leftSide">
					<SideBar links={menuLinks}/>
				</Grid>
				<Grid
					container
					flexDirection="column"
					className="app__rightSide"
				>
					<Header/>
					<Content routes={routes}/>
					<Footer/>
				</Grid>
			</Grid>
		)
		:
		<GlobalLoader/>

}