import { useWarehouseStore } from '../../../../../../store/hooks';


export const useOrderTablesViewModel = () => {
	const warehouseStore = useWarehouseStore();
	const {warehouseKeeperOrders} = warehouseStore;

	return {
		warehouseKeeperOrders
	}
}