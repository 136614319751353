export const WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES = {
	IN_SERVICE: 'in_service',
	IN_TRASH: 'in_trash',
	IN_INSTITUTION: 'in_institution',
	IN_WAREHOUSE: 'in_warehouse',
	IN_PATIENT: 'in_patient',
	TO_ADVERSE_EVENT: 'to_adverse_event',
	AWAITING_RETURN_IN_INSTITUTION: 'awaiting_return_in_institution',
	AWAITING_RETURN_IN_WAREHOUSE: 'awaiting_return_in_warehouse',
}

export const WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES = {
	IN_SERVICE: 'w serwisie',
	IN_TRASH: 'zutylizowany',
	IN_INSTITUTION: 'w szpitalu',
	IN_WAREHOUSE: 'dostępny w magazynie',
	IN_PATIENT: 'u pacjenta',
	TO_ADVERSE_EVENT: 'do zwrotu',
	AWAITING_RETURN_IN_INSTITUTION: 'oczekuje na dostawę do szpitala',
	AWAITING_RETURN_IN_WAREHOUSE: 'oczekuje na zwrot ze szpitala',
	UNKNOWN: 'nieznany status',
}

export const warehouseWarehouseKeeperItemLabels = (pumpStatus) => {
	switch (pumpStatus) {
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.IN_SERVICE:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_SERVICE;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.IN_TRASH:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_TRASH;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.IN_INSTITUTION:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_INSTITUTION;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.IN_WAREHOUSE:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_WAREHOUSE;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.IN_PATIENT:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.IN_PATIENT;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_INSTITUTION:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_INSTITUTION;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_WAREHOUSE:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.AWAITING_RETURN_IN_WAREHOUSE;
		case WAREHOUSE_ITEM_SERVER_WAREHOUSE_KEEPER_STATUSES.TO_ADVERSE_EVENT:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.TO_ADVERSE_EVENT;
		default:
			return WAREHOUSE_ITEM_LOCAL_WAREHOUSE_KEEPER_STATUSES.UNKNOWN;
	}
}

export const WAREHOUSE_ITEM_SERVER_ADS_STATUSES = {
	IN_INSTITUTION: 'in_institution',
	IN_PATIENT: 'in_patient',
	TO_ADVERSE_EVENT: 'to_adverse_event',
	AWAITING_RETURN_IN_INSTITUTION: 'awaiting_return_in_institution',
	AWAITING_RETURN_IN_WAREHOUSE: 'awaiting_return_in_warehouse',
}

export const WAREHOUSE_ITEM_LOCAL_ADS_STATUSES = {
	IN_INSTITUTION: 'w szpitalu',
	IN_PATIENT: 'u pacjenta',
	TO_ADVERSE_EVENT: 'do zwrotu',
	AWAITING_RETURN_IN_INSTITUTION: 'oczekuje na dostawę do szpitala',
	AWAITING_RETURN_IN_WAREHOUSE: 'oczekuje na zwrot ze szpitala',
	UNKNOWN: 'nieznany status',
}

export const warehouseAdsItemLabels = (pumpStatus) => {
	switch (pumpStatus) {
		case WAREHOUSE_ITEM_SERVER_ADS_STATUSES.IN_INSTITUTION:
			return WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.IN_INSTITUTION;
		case WAREHOUSE_ITEM_SERVER_ADS_STATUSES.IN_PATIENT:
			return WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.IN_PATIENT;
		case WAREHOUSE_ITEM_SERVER_ADS_STATUSES.AWAITING_RETURN_IN_INSTITUTION:
			return WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.AWAITING_RETURN_IN_INSTITUTION;
		default:
			return WAREHOUSE_ITEM_LOCAL_ADS_STATUSES.UNKNOWN;
	}
}