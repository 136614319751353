import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context';
import { useWarehouseApi } from '../../../../apiHooks';
import { ROLE_WAREHOUSE } from '../../../../data/consts/users';
import { useWarehouseStore } from '../../../../store/hooks';
import { isStringEmpty } from './isStringEmpty';

export const useActionCellModalViewModel = (pumpId, closeModalHandler) => {

	const { setPumpToWarehouseByAds, addPumpToService } = useWarehouseApi()
	const { role } = useContext(AuthContext)

	const warehouseStore = useWarehouseStore();

	const [wzNumber, setWzNumber] = useState('');
	const [isWzNumberEmptyError, setIsWzNumberEmptyError] = useState(false);
	const [warehouseKeeperReason, setWarehouseKeeperReason] = useState('');
	const [isWarehouseKeeperReasonEmptyError, setIsWarehouseKeeperReasonEmptyError] = useState(false);
	const [date, setDate] = useState(new Date());
	const [isDateError, setIsDateError] = useState(false);

	const [adsReason, setAdsReason] = useState('');
	const [isAdsReasonEmptyError, setIsAdsReasonEmptyError] = useState(false);

	useEffect(() => {
		if (role === ROLE_WAREHOUSE && warehouseStore) {
			setAdsReason(warehouseStore.warehouseKeeperPumps.find(pump => pump.id === pumpId).toServiceReason || '')
		}
	}, [role, warehouseStore, setAdsReason, pumpId]);

	useEffect(() => {
		wzNumber.trim().length > 0 && setIsWzNumberEmptyError(false)
	}, [wzNumber]);

	useEffect(() => {
		date !== null && setIsDateError(false)
	}, [date]);

	useEffect(() => {
		adsReason.trim().length > 0 && setIsAdsReasonEmptyError(false)
	}, [adsReason]);

	useEffect(() => {
		warehouseKeeperReason.trim().length > 0 && setIsWarehouseKeeperReasonEmptyError(false)
	}, [warehouseKeeperReason]);


	const buttonClickHandler = () => {

		if (role === ROLE_WAREHOUSE) {

			if (isStringEmpty(wzNumber)) {
				setIsWzNumberEmptyError(true)
			} else if (date === null) {
				setIsDateError(true)
			} else if (isStringEmpty(warehouseKeeperReason)) {
				setIsWarehouseKeeperReasonEmptyError(true)
			} else {
				void addPumpToService(pumpId, wzNumber, date, warehouseKeeperReason)
				closeModalHandler()
			}

		} else {

			if (isStringEmpty(adsReason)) {
				setIsAdsReasonEmptyError(true)
			} else {
				void setPumpToWarehouseByAds(pumpId, adsReason)
				closeModalHandler()
			}
			
		}
	}

	return {
		role,
		wzNumber,
		setWzNumber,
		isWzNumberEmptyError,
		date,
		setDate,
		isDateError,
		adsReason,
		setAdsReason,
		isAdsReasonEmptyError,
		warehouseKeeperReason,
		setWarehouseKeeperReason,
		isWarehouseKeeperReasonEmptyError,
		buttonClickHandler
	}
}