import { useEffect, useState } from 'react';
import { useWarehouseStore } from '../../../../../../../../store/hooks';
import { useWarehouseApi } from '../../../../../../../../apiHooks';


export const useOrderSelectViewModel = (orderProductModel, orderProductId) => {

	const warehouseStore = useWarehouseStore();
	const { availablePumps } = warehouseStore;
	
	const { addSerialNumberToPump } = useWarehouseApi();

	const [availableCurrentPumps, setAvailableCurrentPumps] = useState([])

	const [pumpId, setPumpId] = useState('')

	useEffect(() => {
		setAvailableCurrentPumps(availablePumps[orderProductModel] || [])
		setPumpId(availablePumps[orderProductModel]?.[0]?.id || '')
	}, [availablePumps, orderProductModel]);
	const changePumpIdHandler = (e) => setPumpId(e.target.value)

	const buttonClickHandler = () => addSerialNumberToPump(orderProductId, pumpId);

	return {
		availableCurrentPumps,
		pumpId,
		changePumpIdHandler,
		buttonClickHandler
	}
}