import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { PoiContext } from '../../../../context';
import { useLoadersStore } from '../../../../store/hooks';
import GlobalLoader from '../../ui/GlobalLoader';

export const GlobalLoaderRunner = observer(() => {

	//loader from context
	const { isGlobalLoader: isGlobalContextLoader } = useContext(PoiContext);

	//loader from mobx store
	const loadersStore = useLoadersStore();
	const { isGlobalLoader: isGlobalStoreLoader } = loadersStore;


	const isLoaderRunning = isGlobalContextLoader || isGlobalStoreLoader;

	return isLoaderRunning ? <GlobalLoader/> : null
})