import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { OrderTableSelect } from './ordersTableSelect/OrderTableSelect';
import { WAREHOUSE_KEEPER_ORDER_STATUSES } from '../../warehouseKeeperOrderStutuses';
import { dateFormatForm } from '../../../../../../../utils/helpfunc';

export const SubTable = observer(({ order }) => {

	const { products, status } = order


	return (
		<Grid component="ul">
			{
				products.map((product, index) => (
					<Grid component="li" key={`${index}-${product.id}`}>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell>
										{product.name}
									</TableCell>
									<TableCell sx={{ width: '25%', textAlign: 'end' }}>
										{product.amountCompleted}/{product.amount}
									</TableCell>
									<TableCell sx={{ width: '25%' }}>
										{
											status === WAREHOUSE_KEEPER_ORDER_STATUSES.IN_PROGRESS &&
											<OrderTableSelect
												orderProductModel={product.model}
												orderProductId={product.id}
												// orderStatus={status}
											/>
										}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={3}>
										{
											product.completed && product.completed.length > 0
												? (
													<ul>
														{
															product.completed.map((item, index) => (
																<li
																	key={item.serialNumber}>
																	{index + 1}. {item.serialNumber} {dateFormatForm(new Date(item.setAt))}
																</li>
															))
														}
													</ul>
												)
												: <Typography variant="body2" color="text.secondary">Nie dodano żadnej pomp do
													zamówienia</Typography>
										}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
				))
			}
		</Grid>
	);
})