import React from 'react'
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography} from '@mui/material';
import {checkboxesData} from './otherActivitiesData'
import {useOtherActivities} from "./useOtherActivities.vm";
import {DatePickerComponent} from "../../../shared/ui/DatePickerComponent";

const OtherActivityAdd = ({reckoningStatus, activityInfo, setIsAdding}) => {
    const {
        checksHandler,
        submitHandler,
        edition,
        checkboxes,
        date,
        duration,
        setDate,
        setDuration,
        textArea,
        setTextArea,
        yearMonth,
        lastDay, firstDay
    } = useOtherActivities(activityInfo, setIsAdding, reckoningStatus)

    return (
        <Grid item container className="settlements__DatePicker" sx={{bgcolor: '#F5F5F5'}} direction="row"
              alignItems="center"
              mb={1}>
            <Box className="settlements__addBox">
                <Typography className="settlements__addTitle" sx={{
                    fontWeight: "bold",
                    maxWidth: "48rem",
                    borderBottom: "solid 2px #ccc",
                    color: `${!edition ? "grey.main" : null}`
                }}>Inne aktywności</Typography>
                <form onSubmit={submitHandler}>
                    <Grid container pb={4} justifyContent='space-between' sx={{width: '100%'}}>
                        <Grid container item justifyContent='space-between'>
                            <Grid item xs={12} lg={5.8} mt={3}>
                                <DatePickerComponent
                                    required
                                    disabled={!edition}
                                    label='Data aktywności'
                                    openTo="day"
                                    views={['day']}
                                    value={date}
                                    disableFuture
                                    defaultCalendarMonth={new Date(yearMonth)}
                                    minDate={firstDay}
                                    maxDate={lastDay}
                                    onChange={(newValue) => setDate(newValue)}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5.8} mt={3}>
                                <TextField disabled={!edition} label='Czas trwania (minuty)' name="duration"
                                           type="number" value={duration} onChange={(e) => setDuration(e.target.value)}
                                           fullWidth required/>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <TextField
                                    minRows={5}
                                    multiline
                                    placeholder={"Dodaj opis..."}
                                    value={textArea}
                                    style={{width: '100%'}}
                                    onChange={(e) => setTextArea(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Stack direction="row" alignItems='center' py={4} sx={{borderBottom: "solid 1px #ccc"}}>
                            <Typography sx={{color: `${!edition ? "grey.main" : null}`}} mr={2}><b>Rodzaj kontaktu:</b></Typography>
                            <div>
                                {checkboxesData.contact.map(el => (
                                    <FormControlLabel
                                        key={el.name}
                                        control={
                                            <Checkbox
                                                disabled={!edition}
                                                name={el.name}
                                                checked={checkboxes[el.name]}
                                                onChange={(e) => checksHandler(e.target.name, e.target.checked)}
                                            />} label={el.label}/>
                                ))}
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mb={2} border={'1px solid #f5f5f5'} borderRadius={'6px'} bgcolor={'#f5f5f5'}>
                        <FormGroup>
                            <>
                                {checkboxesData.activities.map(el => (
                                    <FormControlLabel
                                        key={el.name}
                                        control={
                                            <Checkbox
                                                disabled={!edition}
                                                name={el.name}
                                                checked={checkboxes[el.name]}
                                                onChange={(e) => checksHandler(e.target.name, e.target.checked)}
                                            />} label={el.label}/>
                                ))}
                            </>
                        </FormGroup>
                    </Grid>
                    <Stack sx={{borderTop: "solid 1px #ccc", width: "42rem", height: "5rem", margin: "auto"}}>
                        {edition &&
                            <Button type="submit" color="primary" variant="contained" className="buttonCustom"
                                    sx={{margin: "auto"}}><Typography>Zapisz</Typography></Button>}
                    </Stack>
                </form>
            </Box>
        </Grid>
    )
};

export default OtherActivityAdd
