import React from 'react';
import { Button, Grid } from '@mui/material';
import PageTitle from '../../../../shared/ui/PageTitle';
import { useWarehouseOrdersViewModel } from './useWarehouseOrders.vm';
import { OrdersModal } from './ordersModal/OrdersModal';
import { OrdersTable } from './ordersTable/OrdersTable';
import fileLInk from '../../../../../assets/file/pompyADSwzor.xlsx';
import {colors} from "../../../../../themeStyle";

export const WarehouseOrders = () => {

	const {
		isModalOpen,
		closeModalHandler,
		openModalHandler,
	} = useWarehouseOrdersViewModel()

	return (
		<Grid container>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
			>
				<PageTitle title="Zamówienia"/>
				<Grid item>
					<a
						href={fileLInk}
						style={styles.linkStyle}
					   target="_blank"
					   rel="noreferrer"
					   download
					>
						pobierz plik wzorcowy
					</a>

					<Button
						className="formButton"
						variant="contained"
						color="primary"
						onClick={openModalHandler}
					>
						dodaj pompy
					</Button>
				</Grid>
			</Grid>
			<OrdersTable/>
			<OrdersModal
				isModalOpen={isModalOpen}
				closeModalHandler={closeModalHandler}
			/>
		</Grid>
	);
}

const styles = {
	linkStyle: {
		cursor: 'pointer',
		color: '#fff',
		fontWeight: 'bold',
		fontSize: '12px',
		marginTop: '5px',
		marginRight: '16px',
		textDecoration: 'none',
		display: 'inline-block',
		backgroundColor: colors.secondary.main,
		letterSpacing: '1px',
		padding: '8px',
		borderRadius: '4px',
		boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
	}
}